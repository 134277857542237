





































import {
    BooleanFieldModel,
    FieldValueCollectionEditViewModel,
    IAddress,
    ListFieldModel,
    TextFieldModel,
    typeDependencies,
} from "chipply-common";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

@Component
@typeDependencies({
    types: {
        BooleanFieldModel,
        TextFieldModel,
        ListFieldModel,
    },
})
export default class FieldValueCollectionEditor extends Vue {
    public BooleanFieldModel = BooleanFieldModel;
    public ListFieldModel = ListFieldModel;
    public TextFieldModel = TextFieldModel;

    @Prop({ type: Object, default: null })
    public viewModel!: FieldValueCollectionEditViewModel;

    // public realViewModel: any | null = this.viewModel;
    //
    // @Watch("viewModel")
    // public viewModelChanged() {
    //   this.realViewModel = this.viewModel;
    // }

    public requireValueBoolean(value: any) {
        if (value !== null) {
            return true;
        }
        return "You must enter a value";
    }

    public requireValue(value: any) {
        const num = parseFloat(value);
        if (!Number.isNaN(num)) {
            return true;
        }

        if ((value || "").length > 0) {
            return true;
        }
        return "You must enter a value";
    }
}
