import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import EcomLayout from "../EcomLayout.vue";
import ProductInfoSelector from "../ProductInfoSelector.vue";
import ProductImageViewer from "../ProductImageViewer.vue";
import { WebHelper } from "chipply-common";

import ProductColorModel from "@/chipply/ProductColorModel";
import ProductColorsModel from "@/chipply/ProductColorsModel";
import ProductDetailArgsModel from "@/chipply/ProductDetailArgsModel";
import ProductColorSizeWrapperModel from "@/chipply/ProductColorSizeWrapperModel";
import BulkInfoSelector from "../BulkInfoSelector.vue";
import IProductDto from "@/chipply/i-product-dto";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import ProductOption from "../ProductOption";
import ProductViewModel from "./ProductViewModel";
import IProductResult from "../IProductResult";
import IParentProductModel from "../IParentProductModel";

export default class ParentProductViewModel {
    public parent: ProductViewModel = new ProductViewModel();
    public children: ProductViewModel[] = [];
    public selectedVm: ProductViewModel = new ProductViewModel();
    public pkgItemFinalized = false;
    public parentInitialized = false;

    public async getParent(productId: number, categoryId: number): Promise<void> {
        await this.parent.get(productId, categoryId);
        this.selectedVm = this.parent;
        this.parentInitialized = true;
    }

    public toModel() {
        const childArray: ProductViewModel[] = [];
        this.children.forEach((child) => {
            childArray.push(child.clone());
        });
        const result: IParentProductModel = {
            parent: this.parent.clone(),
            children: childArray,
            selectedVm: this.selectedVm.clone(),
            pkgItemFinalized: this.pkgItemFinalized,
            parentInitialized: this.parentInitialized,
        };
        return result;
    }

    public fromModel(model: IParentProductModel) {
        this.parent = model.parent;
        this.children = model.children;
        this.selectedVm = model.selectedVm;
        this.pkgItemFinalized = model.pkgItemFinalized;
        this.parentInitialized = model.parentInitialized;
    }

    public clone() {
        const modelCopy = this.toModel();
        const vmCopy = new ParentProductViewModel();
        vmCopy.fromModel(modelCopy);
        return vmCopy;
    }

    public async getChild(productId: number, categoryId: number): Promise<ProductViewModel> {
        let target = new ProductViewModel();

        //make sure we didn't reclick the parent
        if (this.parent.productId == productId) {
            this.selectedVm = this.parent;
            return this.parent;
        }

        //check to see if we already have children data
        this.children.forEach((child) => {
            if (child.productId == productId) {
                target = child;
            }
        });
        if (target.productId) {
            this.selectedVm = target;
            return target;
        }
        //if we don't already have the children data request it
        const childModel = new ProductViewModel();
        await childModel.get(productId, categoryId);

        //put in the parent unique fields into the children
        childModel.colorSize.designsObject = this.parent.colorSize.designsObject;

        if (childModel.product && this.parent.product) {
            childModel.product.designCount = this.parent.product.designCount;
            childModel.product.allPricesSame = this.parent.product.allPricesSame;
            childModel.product.startingPriceDisplay = this.parent.product.startingPriceDisplay;
            childModel.categoryId = this.parent.categoryId;
            childModel.product.maxQuantity = this.parent.product.maxQuantity;
        }

        this.children.push(childModel);
        this.selectedVm = childModel;
        return childModel;
    }
}
