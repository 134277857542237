import ProductOption from "./ProductOption";

export default class AddToCartArgs {
    public productId = 0;
    public sizeId: number | undefined = 0;
    public colorId = 0;
    public quantity = 0;
    public packageId: number | undefined = 0;
    public categoryId = 0;
    public eventId = 0;
    public productOptions: ProductOption[] = [];
    public packageNum = 0;
}
