




import CheckoutReviewRevised from "@/components/CheckoutReviewRevised.vue";
import CheckoutStepper from "@/components/CheckoutStepper.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({
    components: { CheckoutStepper, CheckoutReviewRevised },
})
export default class CheckoutReviewPage extends Vue {
    public name = "CheckoutReviewPage";
    @Prop()
    public eventId: number | null = null;

    @Prop()
    public sessionId: number | null = null;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eventId")) {
            this.eventId = Number(urlParams.get("eventId"));
        }
    }
}
