


























































import CheckoutConfirmationViewModel from "@/chipply/view-model/CheckoutConfirmationViewModel";
import Vue from "vue";
import ItemDisplay from "@/components/ItemDisplay.vue";
import OrderSummary from "@/components/OrderSummary.vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CheckoutReviewViewModel from "../chipply/view-model/CheckoutReviewViewModel";
import CouponCodeApplier from "@/components/CouponCodeApplier.vue";

@Component({
    components: {
        ItemDisplay,
        OrderSummary,
        CouponCodeApplier,
    },
})
export default class CheckoutConfirmation extends Vue {
    @Prop({ default: {}, type: Object })
    public vm!: CheckoutConfirmationViewModel;
}
