














































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

@Component({
    components: {},
})
export default class ProductColor extends Vue {
    name = "ProductColor";

    @Prop()
    public selected!: number;

    @Prop()
    public color!: any;

    @Prop()
    public eventProductColorID!: string;

    @Prop()
    public unavailable!: boolean;

    @Prop()
    public display!: boolean;

    @Prop()
    public requestor!: boolean;

    @Prop()
    public packageView!: boolean;

    public type = "unselected";

    private async created() {
        if (!this.display) {
            this.onSelectedChanged();
        } else {
            this.type = "display";
        }
    }

    @Watch("selected")
    protected onSelectedChanged() {
        if (!this.requestor) {
            if (this.selected == this.color.eventProductColorId) {
                this.type = "selected";
            } else {
                this.type = "unselected";
            }
        } else {
            if (this.selected) {
                this.type = "selected";
            } else {
                this.type = "unselected";
            }
        }
    }
}
