import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { ChipplyIconLibrary } from "@/components/chipply-icons/ChipplyIconLibrary";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: ChipplyIconLibrary,
    },
});
