import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import EcomLayout from "../EcomLayout.vue";
import ProductInfoSelector from "../ProductInfoSelector.vue";
import ProductImageViewer from "../ProductImageViewer.vue";
import { WebHelper } from "chipply-common";

import ProductColorModel from "@/chipply/ProductColorModel";
import ProductColorsModel from "@/chipply/ProductColorsModel";
import ProductDetailArgsModel from "@/chipply/ProductDetailArgsModel";
import ProductColorSizeWrapperModel from "@/chipply/ProductColorSizeWrapperModel";
import BulkInfoSelector from "../BulkInfoSelector.vue";
import IProductDto from "@/chipply/i-product-dto";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import ProductOption from "../ProductOption";
import IProductResult from "../IProductResult";
import { Serializer } from "chipply-common";
import GetProductInfoArgs from "../GetProductInfoArgs";
import RadioButtonModel from "@/chipply/RadioButtonModel";

export default class ProductViewModel {
    public colorSize: ProductColorSizeWrapperModel = new ProductColorSizeWrapperModel();
    public details: ProductColorsModel = new ProductColorsModel();
    public productId = 0;
    public categoryId = 0;
    public options: IProcessOptionModel[] = [];
    public product: IProductDto | null = null;
    public optionArray: ProductOption[] = [];
    public bulkSizes: any[] = [];
    public bulkInitialized = false;
    public imagePreview = "";
    // TODO: Change type to
    //  ({ colorName: string, enabled: boolean, eventProductColorId: number, hexValue: string }
    //  | null)
    //  and test for anything broken
    public selectedColor: any | undefined = "";
    public selectedSize = 0;
    public selectedImage: any | undefined = "";
    public selectedReqColors: any[] = [];
    public selectedLayout = "";
    public colorSizeInitialized = false;
    public productQuantity = 0;
    public pkgItemFinalized = false;
    public productColorCollection: any[] = [];
    public productSizeCollection: any[] = [];
    public loading = false;
    public hideBulkOrdering = false;
    public reqPrevColor = 0;

    public async get(productId: number, categoryId: number): Promise<void> {
        this.productId = productId;
        this.categoryId = categoryId;

        await this.getAllProductInfo(productId, categoryId);
    }

    public async getAllProductInfo(productId: number, categoryId: number) {
        const args = new GetProductInfoArgs();
        args.categoryId = categoryId;
        args.productId = productId;

        const productResult = (await WebHelper.postJson<IProductResult>(
            `/api/EventProductEcom/GetProductResult`,
            args
        )) as IProductResult;
        // Here we want to pass forward the categoryId from the page
        productResult.categoryId = args.categoryId;

        this.fromModel(productResult);
    }

    public multipleImagesPresent() {
        let flag = false;
        if (this.selectedColor) {
            this.details.productColors.forEach((pc) => {
                if (pc.eventProductColorID == this.selectedColor.eventProductColorId) {
                    if (pc.images.length > 1) {
                        flag = true;
                    }
                }
            });
        } else {
            if (this.details.productColors[0].images.length > 1) {
                flag = true;
            }
        }

        return flag;
    }

    public toModel() {
        const result: IProductResult = {
            productId: this.productId,
            categoryId: this.categoryId,
            colorSizes: JSON.parse(JSON.stringify(this.colorSize)),
            productInfo: JSON.parse(JSON.stringify(this.product)),
            options: JSON.parse(JSON.stringify(this.options)),
            image: this.details,
            selectedColor: JSON.parse(JSON.stringify(this.selectedColor)),
            selectedSize: this.selectedSize,
            quantity: this.productQuantity,
            hideBulkOrdering: this.hideBulkOrdering,
        };
        return result;
    }

    public clone() {
        const modelCopy = this.toModel();
        const vmCopy = new ProductViewModel();
        vmCopy.fromModel(modelCopy);
        return vmCopy;
    }

    public fullDescText(breakpoint: boolean) {
        const ele = document.createElement("div");
        if (this.product!.fullDesc) {
            ele.innerHTML = this.product!.fullDesc;
            const target = ele.getElementsByTagName("span")[0];
            if (ele) {
                if (breakpoint) {
                    ele.style.fontSize = "18px !important";
                } else {
                    ele.style.fontSize = "16px !important";
                }
            }
        }

        return ele.outerHTML;
    }

    public toolTipText(store: boolean) {
        const ele = document.createElement("div");
        if (this.product!.shortDesc) {
            ele.innerHTML = this.product!.shortDesc;
            const target = ele.getElementsByTagName("span")[0];
            if (target) {
                if (store) {
                    target.style.fontSize = "14px";
                } else {
                    target.style.fontSize = "18px";
                }
            }
        }

        return ele.innerHTML;
    }

    public fromModel(model: IProductResult) {
        if (this.categoryId == undefined) {
            this.categoryId = 0;
        } else {
            this.categoryId = model.categoryId;
        }

        if (this.productId == undefined) {
            this.productId = 0;
        } else {
            this.productId = model.productId;
        }

        const designViewModels = [];
        for (const design of model.colorSizes.designsObject.designs) {
            designViewModels.push(new RadioButtonModel(design));
        }
        model.colorSizes.designsObject.designs = designViewModels;

        this.colorSize = model.colorSizes;

        this.details = model.image;

        this.selectedImage = this.details.productColors[0].images[0];

        if (model.selectedColor) {
            this.selectedColor = model.selectedColor;
        }

        if (model.selectedSize && model.selectedSize != 0) {
            this.selectedSize = model.selectedSize;
        }
        this.product = model.productInfo;
        if (this.product.sizeChart.length > 0) {
            this.product.sizeChart = this.product.sizeChart.replace("<a ", "<a target='_blank' ");
        }
        this.options = model.options;
        this.productQuantity = model.quantity;
        this.hideBulkOrdering = model.hideBulkOrdering;
    }

    public displayReqColors() {
        let displayString = "";
        this.selectedReqColors.forEach((color) => {
            if (color.selected) {
                displayString += color.colorName + ", ";
            }
        });
        return displayString;
    }

    public colorCount() {
        let count = 0;
        this.selectedReqColors.forEach((color) => {
            if (color.selected) {
                count++;
            }
        });
        return count;
    }

    public getASelectedColor() {
        let target = 0;
        for (const color of this.selectedReqColors) {
            if (color.selected) {
                target = color.eventProductColorId;
                break;
            }
        }

        return target;
    }

    public async sharedSetup() {
        this.productColorCollection = [];
        this.colorSize.colors?.forEach((element) => {
            const targetDictionary = this.colorSize.colorSizeDictionary![element.eventProductColorId];

            const model: { colorName: string; hexValue: string; eventProductColorId: number; enabled: boolean } = {
                colorName: element.colorName,
                hexValue: element.hexCode1,
                eventProductColorId: element.eventProductColorId,
                enabled: Object.keys(targetDictionary).length > 0,
            };

            this.productColorCollection.push(model);
        });

        if (this.selectedColor == "") {
            //presetting the initial color here
            for (const color of this.productColorCollection) {
                if (color.enabled) {
                    this.selectedColor = color;
                    break;
                }
            }
        }

        //need to setup the size array and disable any that aren't available in the initial color
        this.productSizeCollection = [];
        const keys = Object.keys(this.colorSize.colorSizeDictionary![this.selectedColor.eventProductColorId]);
        this.colorSize.sizes?.forEach((element) => {
            const model: { display: string; value: number; selected: boolean; enabled: boolean } = {
                display: element.display,
                value: element.value,
                selected: false,
                enabled: keys.includes("" + element.value),
            };
            this.productSizeCollection.push(new RadioButtonModel(model));
        });
    }

    public async setInitialColorSize() {
        this.sharedSetup();

        // this.productSizeCollection = tempSizeArray;

        if (this.productSizeCollection.length == 1) {
            this.productSizeCollection[0].selected = true;
            this.selectedSize = this.productSizeCollection[0].value;
        }

        if (this.selectedSize != 0) {
            this.productSizeCollection.forEach((size) => {
                if (size.value == this.selectedSize) {
                    size.selected = true;
                }
            });
        }

        if (!this.productQuantity || this.productQuantity == 0) {
            this.productQuantity = this.product!.minQuantity;
        }

        //TODO -- will need to adjust this for requestor packages because they have no quantity
        if (
            this.selectedSize != 0 &&
            this.selectedColor != 0 &&
            this.productQuantity == this.product?.maxQuantity &&
            this.options.length == 0 &&
            this.product.designCount == 1
        ) {
            this.pkgItemFinalized = true;
        }

        this.colorSizeInitialized = true;
    }

    public async getProductColorSize() {
        const args = new ProductDetailArgsModel();
        args.eventProductId = this.productId;
        const deets = await WebHelper.postJsonData("/api/EventProductEcom/ColorSize", args);
        const deetsObject = JSON.parse(deets);
        let blankIndex = 0;
        deetsObject.designsObject.designs.forEach((design: any, index: number) => {
            if (design.display === "Blank Item") {
                blankIndex = index;
            }
            design.enabled = false;
        });
        const blankDesign = deetsObject.designsObject.designs.splice(blankIndex, 1);
        deetsObject.designsObject.designs.splice(0, 0, blankDesign[0]);
        this.colorSize = deetsObject;
    }

    public async rebuildColorsAndSizes(tempId: number) {
        this.productColorCollection = [];
        this.colorSize.colors?.forEach((element) => {
            const targetDictionary = this.colorSize.colorSizeDictionary![element.eventProductColorId];

            const model: { colorName: string; hexValue: string; eventProductColorId: number; enabled: boolean } = {
                colorName: element.colorName,
                hexValue: element.hexCode1,
                eventProductColorId: element.eventProductColorId,
                enabled: Object.keys(targetDictionary).length > 0,
            };

            this.productColorCollection.push(model);
        });

        //need to setup the size array and disable any that aren't available in the initial color
        this.productSizeCollection = [];
        const keys = Object.keys(this.colorSize.colorSizeDictionary![tempId]);
        this.colorSize.sizes?.forEach((element) => {
            const model: { display: string; value: number; selected: boolean; enabled: boolean } = {
                display: element.display,
                value: element.value,
                selected: false,
                enabled: keys.includes("" + element.value),
            };
            this.productSizeCollection.push(model);
        });

        if (this.productSizeCollection.length == 1) {
            this.productSizeCollection[0].selected = true;
            this.selectedSize = this.productSizeCollection[0].value;
        }
    }

    public async getImageInfo() {
        if (this.details.productColors.length == 0) {
            const details = (await WebHelper.getJsonData(
                `/api/EventProductEcom/${this.productId}`
            )) as ProductColorsModel;
            this.details = details;
            this.selectedImage = details.productColors[0].images[0];
        }
    }

    public areItemsPresent() {
        let itemPresent = false;

        this.bulkSizes.forEach((bulkSize) => {
            if (parseInt(bulkSize.quantity) > 0) {
                itemPresent = true;
            }
        });

        return itemPresent;
    }

    public async getProductOptions() {
        const options = (await WebHelper.getJsonData(
            `/api/EventProductEcom/Options/${this.productId}`
        )) as IProcessOptionModel[];
        this.options = options;
    }

    public checkForEnabledDesign() {
        let flag = false;
        if (this.product) {
            if (this.product.designCount > 1) {
                this.colorSize.designsObject.designs.forEach((design: any) => {
                    if (design.selected) {
                        flag = true;
                    }
                });
            }
        }

        return flag;
    }

    public getDisplaySize() {
        let displaySize = "";
        this.colorSize.sizes?.forEach((size) => {
            if (size.value == this.selectedSize) {
                displaySize = size.display;
            }
        });

        return displaySize;
    }

    public async getProductInfo() {
        if (this.categoryId == undefined) {
            this.categoryId = 0;
        }
        this.product = (await WebHelper.getJsonData(
            `/api/EventProductEcom/ProductInfo/${this.productId}/${this.categoryId}`,
            true
        )) as IProductDto;

        if (this.product.sizeChart.length > 0) {
            this.product.sizeChart = this.product.sizeChart.replace("<a ", "<a target='_blank' ");
        }
    }

    public async disableColors(id: number) {
        const colors = Object.keys(this.colorSize.colorSizeDictionary!);
        //this.myColors = [];
        colors.forEach((color) => {
            const sizes = Object.keys(this.colorSize.colorSizeDictionary![color as unknown as number]);
            const combo = this.colorSize.colorSizeDictionary![color as unknown as number][id];

            if (combo == undefined) {
                this.productColorCollection.forEach((myColor) => {
                    if (myColor.eventProductColorId == color) {
                        myColor.enabled = false;
                    }
                });
            } else {
                this.productColorCollection.forEach((myColor) => {
                    if (myColor.eventProductColorId == color) {
                        myColor.enabled = true;
                    }
                });
            }
        });
    }
}
