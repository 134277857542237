import { WebHelper } from "chipply-common";

export default class RecaptchaClient {
    public async getRecaptchaSiteKey(): Promise<string> {
        const results = await WebHelper.getJsonData("/api/ecom/getrecaptchasitekey");
        return results.siteKey;
    }

    public async execute(args: any): Promise<string> {
        const siteKey = await this.getRecaptchaSiteKey();
        await this.recaptchaReady();
        const recaptchaInstance = this.getRecaptchaInstance();
        const token = (await recaptchaInstance.enterprise.execute(siteKey, args)) as string;
        return token;
    }

    public async render(): Promise<void> {
        const siteKey = await this.getRecaptchaSiteKey();
        const recaptchaInstance = this.getRecaptchaInstance();
        if (recaptchaInstance) return;
        const element: HTMLScriptElement = document.createElement("script");
        const scriptUrl = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
        element.src = scriptUrl;
        return new Promise<void>((resolve, reject) => {
            element.addEventListener(
                "load",
                this.waitForScriptToLoad(() => {
                    resolve();
                }),
                false
            );
            element.onerror = (error): void => {
                reject(error);
            };
            document.head.appendChild(element);
        });
    }

    private waitForScriptToLoad(callback: () => void) {
        return (): void => {
            if ((<any>window).grecaptcha === undefined) {
                setTimeout(() => {
                    this.waitForScriptToLoad(callback);
                }, 25);
            } else {
                (<any>window).grecaptcha.enterprise.ready(() => {
                    callback();
                });
            }
        };
    }

    // Some code adapted from https://github.com/abinnovision/recaptcha-v3
    public recaptchaReady(): Promise<void> {
        const promise = new Promise<void>((resolve) => {
            const recaptchaInstance = this.getRecaptchaInstance();
            recaptchaInstance.enterprise.ready(async () => {
                resolve();
            });
        });
        return promise;
    }

    private getRecaptchaInstance(): any {
        return (window as any).grecaptcha;
    }

    private loadScript(src: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.onload = resolve;
            script.onerror = reject;
            script.src = src;
            document.head.append(script);
        });
    }
}

(window as any).RecaptchaClient = RecaptchaClient;
