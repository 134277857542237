









































































































































































































































































































































































import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import Validation from "@/chipply/Validation";
import CartItemViewModel from "@/chipply/view-model/CartItemViewModel";
import CheckoutReviewViewModel from "@/chipply/view-model/CheckoutReviewViewModel";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import StoreLayout from "@/components/StoreLayout.vue";
import ICartItem from "@/chipply/ICartItem";
import ICartItemAttributes from "@/chipply/ICartItemAttributes";
import EcomLayout from "./EcomLayout.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import CartItemAttributeViewModel from "@/chipply/view-model/CartItemAttributeViewModel";
import { mask } from "vue-the-mask";
import QuantityComponent from "@/components/design-elements/QuantityComponent.vue";
@Component({
    components: { QuantityComponent, StoreLayout, EcomLayout },
    directives: {
        mask,
    },
})
export default class Cart extends Vue {
    @Prop()
    public eventId!: number;

    @Prop()
    public isPreview!: boolean;

    public vm: CheckoutReviewViewModel = new CheckoutReviewViewModel();
    public cartVm: CartViewModel = new CartViewModel();
    public alacart: Array<ICartItem> = [];

    public deleteTarget!: ICartItem | any;
    public showDialog = false;
    public cartFormValid = false;
    public packageFormValid = false;
    public qtyFormValid = false;
    public loading = true;
    public chipplyIcons = chipplyIcons;
    public originalValue = "";
    private errorMessage = "";

    // @ts-ignore
    public $refs!: {
        cartForm: any;
        packageForm: any;
        qtyForm: any;
    };

    public async created() {
        await this.cartVm.get(this.eventId);
        await this.cartVm.getCartItems(this.eventId);

        this.cartVm.contents.forEach((item) => {
            if (item.packageId == null) {
                this.alacart.push(item);
            }
        });
        this.loading = false;
    }

    public async quantitySelected(value: string, item: ICartItem) {
        let num = parseInt(value.slice(4));
        await this.cartVm.changeItemQuantity(num, item.cartItemId);
        //get items again to refresh the subtotal
        await this.cartVm.getCartItems(this.eventId);
    }

    protected async changeQty(amount: number, item: ICartItem) {
        item.errorMessage = "";
        const liveMax = this.getCorrectLiveMax(item);
        const adjustedValue = parseInt(item.itemQuantity.toString()) + amount;
        if (amount > 0) {
            if (adjustedValue <= liveMax) {
                item.itemQuantity++;
            } else {
                item.errorMessage = "Max Quantity Reached";
                return;
            }
        } else {
            if (item.itemQuantity != 1) {
                if (adjustedValue >= item.minQuantity) {
                    item.itemQuantity--;
                } else {
                    item.errorMessage = "Min Quantity Reached";
                    return;
                }
            }
        }

        if (item.itemQuantity > liveMax) {
            item.errorMessage = "Product quantity must be between " + item.minQuantity + " and " + liveMax;
        }
        if (this.validateQuantity(item)) {
            this.loading = true;
            await this.cartVm.changeItemQuantity(item.itemQuantity, item.cartItemId);
            await this.cartVm.getCartItems(this.eventId);
            await this.cartVm.clearErrors();
            this.clearAlacartErrors();
            item.displayQuantity = item.itemQuantity;
            this.loading = false;
        }
    }

    private specifiedQuantity(item: ICartItem) {
        if (item.minQuantity == item.maxQuantity) {
            return true;
        } else {
            return false;
        }
    }

    private getCorrectLiveMax(item: ICartItem) {
        var liveMax = 0;
        if (item.colorSizeQuantity > 0) {
            liveMax =
                item.displayQuantity +
                this.cartVm.calculateCurrentMaxMultiDesign(
                    item.designIds,
                    item.maxQuantity,
                    item.colorSizeQuantity,
                    item.itemColors[0].itemColorText,
                    item.itemSize
                );
        } else {
            liveMax =
                item.displayQuantity + this.cartVm.calculateCurrentMaxMultiDesign(item.designIds, item.maxQuantity);
        }
        return liveMax;
    }

    protected validateQuantity(item: ICartItem) {
        if (item) {
            const liveMax = this.getCorrectLiveMax(item);
            if (item.minQuantity <= item.itemQuantity && item.itemQuantity <= liveMax) {
                item.errorMessage = "";
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    protected clearAlacartErrors() {
        this.alacart.forEach((item) => {
            item.errorMessage = "";
        });
    }

    protected validateManualQty(item: ICartItem, event: any) {
        item.itemQuantity = event;
        if (item) {
            var liveMax = this.getCorrectLiveMax(item);
            if (item.minQuantity <= item.itemQuantity && item.itemQuantity <= liveMax) {
                item.errorMessage = "";
                return true;
            } else if (item.itemQuantity > liveMax && item.minQuantity == liveMax) {
                item.errorMessage = "Product quantity must be " + item.minQuantity;
            } else {
                item.errorMessage = "Product quantity must be between " + item.minQuantity + " and " + liveMax;
                return false;
            }
        }
    }

    protected screenCheck() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return true;
        } else {
            return false;
        }
    }

    public async deleteSelected() {
        this.loading = true;
        if (this.deleteTarget[1]) {
            //this is a package
            const split = this.deleteTarget[0].split("_");

            await this.cartVm.deletePackage(split[0], this.deleteTarget[1].packageId, split[1], this.eventId);
            this.deleteTarget = "";
            await this.cartVm.getCartItems(this.eventId);
        } else {
            //this is an individual item
            await this.cartVm.deleteCartItem(this.deleteTarget.cartItemId);
            this.deleteTarget = "";
            await this.cartVm.getCartItems(this.eventId);
            this.alacart = [];
            this.cartVm.contents.forEach((item) => {
                if (item.packageId == null) {
                    this.alacart.push(item);
                }
            });
        }
        this.loading = false;
    }

    private async setOriginalValue(value: string) {
        this.originalValue = value;
    }

    private async markAttrChange(option: CartItemAttributeViewModel) {
        option.valueChanged = true;
    }

    protected validateOptionField(option: CartItemAttributeViewModel) {
        if (!option.isRequired || (option.userValue && option.userValue != "")) {
            return true;
        }
        return "Product Option required";
    }

    protected validateMaxLength(option: CartItemAttributeViewModel) {
        if (option.maxLength === 0) {
            return true;
        } else {
            return Validation.requireMaximumLengthOf(option.maxLength)(option.userValue);
        }
    }

    public async proceedToCheckout() {
        if (this.$refs.cartForm.validate() && this.$refs.packageForm.validate()) {
            if (!this.checkError()) {
                this.loading = true;
                await this.cartVm.proceedToCheckout();
                if (this.cartVm.cartErrorHeader) {
                    this.loading = false;
                }
            }
        }
    }

    public checkError() {
        let errorFlag = false;
        this.cartVm.contents.forEach((item) => {
            if (item.errorMessage != "" && item.errorMessage !== undefined) {
                errorFlag = true;
            }
        });
        this.alacart.forEach((item) => {
            if (item.errorMessage != "" && item.errorMessage !== undefined) {
                errorFlag = true;
            }
        });
        return errorFlag;
    }

    public async manualQtyChange(item: ICartItem) {
        if (this.validateQuantity(item) == true) {
            item.errorMessage = "";
            this.loading = true;
            await this.cartVm.changeItemQuantity(item.itemQuantity, item.cartItemId);
            await this.cartVm.getCartItems(this.eventId);
            item.displayQuantity = item.itemQuantity;
            this.clearAlacartErrors();
            this.loading = false;
        }
    }

    public async optionChanged(cartItemVieModel: CartItemViewModel | ICartItem, option: CartItemAttributeViewModel) {
        if (this.validateOptionField(option) == true) {
            this.loading = true;
            const newPrice = await this.cartVm.updateOption(option.attributeName, option);
            cartItemVieModel.itemPrice = newPrice;
            await this.cartVm.getCartItems(this.eventId);
            this.loading = false;
        }
        option.valueChanged = false;
    }
}
