








































































































































import ProductColor from "../components/ProductColor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import ProductSize from "./ProductSize.vue";
import { Watch } from "vue-property-decorator";
import ProductColorSizeWrapperModel from "@/chipply/ProductColorSizeWrapperModel";
import ProductColorModel from "@/chipply/ProductColorModel";
import ProductColorsModel from "@/chipply/ProductColorsModel";
import IProductDto from "@/chipply/i-product-dto";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import { Serializer } from "chipply-common";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import ParentProductViewModel from "@/chipply/view-model/ParentProductViewModel";
import ChooseYourDesign from "@/components/design-elements/ChooseYourDesign.vue";

@Component({
    components: { ChooseYourDesign, ProductColor, ProductSize },
    props: {
        // productColors: Array,
        productColor: Object,
        value: Object,
        pvm: Object,
        maxReached: Boolean,
        cartVm: Object,
    },
})
export default class BulkInfoSelector extends Vue {
    name = "BulkInfoSelector";
    private myColors: Array<any> = [];
    private mySizes: Array<any> = [];
    private value!: ProductViewModel;
    private pvm!: ParentProductViewModel;
    private maxReached!: boolean;
    private cartVm!: CartViewModel;
    private noSizes = false;
    private noDesignSelected = false;

    public $refs!: {
        productSize: ProductSize;
    };

    public async created() {
        if (!this.value.bulkInitialized) {
            await this.setInitialColor();
        }
    }

    private getCurrentMax() {
        let designIds: any[] = [];
        if (this.value.product!.designCount > 1) {
            this.value.colorSize.designsObject.designs.forEach((design: any) => {
                designIds.push(design.value);
            });
        }
        return this.cartVm.calculateCurrentMaxMultiDesign(designIds, this.value.product!.maxQuantity);
    }

    public async proceedToCustomize() {
        var itemPresent = false;
        this.noSizes = false;
        var exceeding = false;

        this.value.options.forEach((option) => {
            if (option.userValue) {
                option.userValue = "";
            }
        });

        this.value.bulkSizes.forEach((bulkSize) => {
            if (parseInt(bulkSize.quantity) > 0) {
                for (let i = 0; i < bulkSize.quantity; i++) {
                    let copy = Serializer.serialize(this.value.options);
                    bulkSize.options.push(Serializer.deserialize(copy));
                    itemPresent = true;
                }
            }
        });

        if (!this.value.checkForEnabledDesign() && this.value.product!.designCount > 1) {
            this.noDesignSelected = true;
            return;
        }

        if (this.$refs.productSize.exceedingMaxAll()) {
            //don't proceed to customize
            return;
        }

        if (!itemPresent) {
            // no quantities added
            this.noSizes = true;
            return;
        } else {
            this.noSizes = false;
        }

        if (this.value.options.length > 0) {
            this.$emit("customize");
        } else {
            this.$emit("bulkCart");
        }
    }

    private async designSelected(design: any) {
        this.noDesignSelected = false;
        this.$emit("designChanged", design);
    }

    public async setInitialColor() {
        this.value.sharedSetup();
        this.value.bulkInitialized = true;
    }

    @Watch("value.selectedColor")
    protected async onColorChanged() {
        if (this.value.selectedColor != undefined) {
            await this.value.rebuildColorsAndSizes(this.value.selectedColor.eventProductColorId);
            await this.disableSizes(this.value.selectedColor.eventProductColorId);
            this.$emit("color", this.value.selectedColor.eventProductColorId);
        }
    }

    private async disableSizes(id: number) {
        if (id != undefined) {
            var targetDictionary = this.value.colorSize.colorSizeDictionary![id];
            var keys = Object.keys(targetDictionary);
            this.value.productSizeCollection = [];
            this.value.colorSize.sizes?.forEach((element) => {
                var model: { display: string; value: number; selected: boolean; enabled: boolean } = {
                    display: element.display,
                    value: element.value,
                    selected: false,
                    enabled: keys.includes("" + element.value),
                };
                this.value.productSizeCollection.push(model);
            });
        }
    }
}
