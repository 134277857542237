import ICheckoutReviewOrder from "@/chipply/ICheckoutReviewOrder";
import IEventBrandingDto from "@/chipply/IEventBrandingDto";
import IEventBrandingResults from "@/chipply/IEventBrandingResults";
import PaymentInfo from "@/chipply/PaymentInfo";
import CheckoutReviewOrderItemViewModel from "@/chipply/view-model/CheckoutReviewOrderItemViewModel";
import checkoutReview from "@/pages/checkout-review";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import {
    Address,
    BooleanFieldModel,
    FieldValueCollectionEditViewModel,
    FieldValueEditViewModel,
    IAddress,
    IEcertBalanceInfo,
    IOrder,
    IOrderTotals,
    ITextValue,
    ListFieldModel,
    WebHelper,
} from "chipply-common";
import IPickupLocation from "../IPickupLocation";
import Decimal from "decimal.js";
import OrderFeeDetail from "@/chipply/OrderFeeDetail";

export default class CheckoutConfirmationViewModel {
    public get totalDue() {
        if (!this.totals) {
            return 0;
        }
        const result = new Decimal(this.totals!.orderTotal)
            .minus(this.totals!.ecertTotal)
            .minus(this.totals!.couponTotal);
        return result.toNumber();
    }

    public branding: IEventBrandingDto = {} as any;
    public dealerName: string | null = null;
    public orderInstructions: string | null = null;
    public shippingType: string | null = null;
    public ecertCode = "";
    public eventId: number | null = null;
    public ecertError: string | null = null;
    public promoCode: string | null = null;
    public promoCodeError: string | null = null;
    public allowPo = false;
    public allowCoupon = false;
    public allowEcert = false;
    public hideCreditCard = true;
    public hideVendor = false;
    public requireTermsAgreement = false;
    public appliedPromoCode: string | null = null;
    public appliedEcertCodes: IEcertBalanceInfo[] = [];
    public loading = false;
    public orderErrorMessage: string | null = null;
    public orderErrorHeader: string | null = null;
    public showOrderError = false;
    public agreedToTerms = false;
    public creditCardValid = false;
    public isRequestor = false;
    public isGroup = false;
    public storeInstructions = "";
    public billingAddress: IAddress | null = null;
    public shippingAddress: IAddress | null = null;
    public items: CheckoutReviewOrderItemViewModel[] = [];
    public pickupLocation: string | null = null;
    public smsOptIn = false;
    public hasSms = false;
    public order: IOrder | null = null;
    public availablePickups: IPickupLocation[] = [];
    public organizationPickups: ITextValue<number>[] = [];
    public distroGroup = false;
    public distroPickup = false;
    public distroShipping = false;
    public selectedOrganizationPickupId = 0;
    public allowInternationalShipping = false;
    public fieldValueCollection: FieldValueCollectionEditViewModel | null = null;

    public totals: IOrderTotals | null = null;
    public storeUrl = "";

    public payment = new PaymentInfo();
    public initialized = false;

    public showDetailedHandlingFees = false;
    public detailedHandlingFeesList: OrderFeeDetail[] = [];

    public getFieldValue(fieldEditViewModel: FieldValueEditViewModel, value: any) {
        if (!fieldEditViewModel) {
            return "";
        }

        if (value === null) return "";

        if (fieldEditViewModel.field instanceof BooleanFieldModel) {
            return value === true ? "Yes" : "No";
        }

        if (!(fieldEditViewModel.field instanceof ListFieldModel)) {
            return value;
        }

        const listItem = fieldEditViewModel.field.items.find((i) => i.id == value);
        if (!listItem) {
            return "";
        }

        return listItem.name;
    }
    public async get(args: {
        eventId: number;
        orderId: number;
        orderKey: string | null;
        storeUrl: string;
    }): Promise<void> {
        this.eventId = args.eventId;
        this.storeUrl = args.storeUrl;
        let baseUrl = "/api/Event/Branding?eventId=";
        baseUrl += encodeURIComponent(args.eventId);
        const results = (await WebHelper.getJsonData(baseUrl)) as IEventBrandingResults;
        if (results && results.dto) {
            this.branding = results.dto;
        }

        let checkoutReviewOrder: ICheckoutReviewOrder | null = null;
        try {
            const checkoutReviewOrderJson = await WebHelper.postJsonData(`/api/OrderConfirmation`, args);
            checkoutReviewOrder = JSON.parse(checkoutReviewOrderJson) as ICheckoutReviewOrder;
            if (!checkoutReviewOrder) {
                location.assign("./");
                return;
            }
        } catch (e) {
            location.assign("./");
            return;
        }

        if (checkoutReviewOrder.order.billingAddress == null) {
            checkoutReviewOrder.order.billingAddress = new Address();
            checkoutReviewOrder.order.billingAddress.country = "USA";
        }
        if (checkoutReviewOrder.order.shippingAddress == null) {
            checkoutReviewOrder.order.shippingAddress = new Address();
        }

        this.hasSms = checkoutReviewOrder.hasSms;
        this.isRequestor = checkoutReviewOrder.isRequestor;
        this.isGroup = checkoutReviewOrder.isGroup;
        this.allowPo = checkoutReviewOrder.allowPo;
        this.allowCoupon = checkoutReviewOrder.allowCoupon;
        this.allowEcert = checkoutReviewOrder.allowEcert;
        this.hideCreditCard = checkoutReviewOrder.hideCreditCard;
        this.requireTermsAgreement = checkoutReviewOrder.requireTermsAgreement;
        this.order = checkoutReviewOrder.order;
        this.billingAddress = this.order.billingAddress;
        this.shippingAddress = this.order.shippingAddress;
        this.shippingType = this.order.shippingType;
        this.pickupLocation = checkoutReviewOrder.pickupLocation;
        this.organizationPickups = checkoutReviewOrder.organizationPickups;
        this.allowInternationalShipping = checkoutReviewOrder.allowInternationalShipping;
        this.appliedPromoCode = checkoutReviewOrder.order.couponCode;
        this.items = [];
        for (const product of this.order.products) {
            this.items.push(new CheckoutReviewOrderItemViewModel(product));
        }

        this.totals = checkoutReviewOrder.order.totals;
        this.dealerName = checkoutReviewOrder.dealerName;
        this.orderInstructions = checkoutReviewOrder.orderInstructions;
        this.distroGroup = checkoutReviewOrder.distroGroup;
        this.distroPickup = checkoutReviewOrder.distroPickup;
        this.distroShipping = checkoutReviewOrder.distroShipping;
        this.availablePickups = checkoutReviewOrder.availablePickups;

        if (checkoutReviewOrder.fields && checkoutReviewOrder.fields.fields.length > 0) {
            const vm = new FieldValueCollectionEditViewModel();
            vm.fields = checkoutReviewOrder?.fields?.fields ?? [];
            vm.fieldValues = checkoutReviewOrder?.fieldValues?.fieldValues ?? [];
            vm.initialize();
            this.fieldValueCollection = vm;
        }

        this.showDetailedHandlingFees = checkoutReviewOrder.showDetailedHandlingFees;
        this.detailedHandlingFeesList = checkoutReviewOrder.detailedHandlingFeesList;

        this.initialized = true;
    }
}
