
























































































































































































import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import EcomLayout from "../EcomLayout.vue";
import ProductInfoSelector from "../ProductInfoSelector.vue";
import ProductImageViewer from "../ProductImageViewer.vue";
import { WebHelper } from "chipply-common";
import AddToCartBulkArgs from "@/chipply/AddToCartBulkArgs";
import AddToCartArgs from "@/chipply/AddToCartArgs";

import ProductColorModel from "@/chipply/ProductColorModel";
import ProductColorsModel from "@/chipply/ProductColorsModel";
import ProductDetailArgsModel from "@/chipply/ProductDetailArgsModel";
import ProductColorSizeWrapperModel from "@/chipply/ProductColorSizeWrapperModel";
import BulkInfoSelector from "../BulkInfoSelector.vue";
import IProductDto from "@/chipply/i-product-dto";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import ParentProductViewModel from "@/chipply/view-model/ParentProductViewModel";
import BulkProductPage from "./BulkProductPage.vue";
import RequestorInfoSelector from "../RequestorInfoSelector.vue";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import ProductOption from "@/chipply/ProductOption";
import IProcessOptionItem from "@/chipply/IProcessOptionItem";
import ProductDialog from "../ProductDialog.vue";

@Component({
    components: {
        EcomLayout,
        ProductInfoSelector,
        ProductImageViewer,
        BulkInfoSelector,
        BulkProductPage,
        RequestorInfoSelector,
        ProductDialog,
    },
})
export default class ProductDetailPage extends Vue {
    public name = "ProductDetailPage";
    public tab: string | null = null;
    public customize = false;
    public requestor = false;
    private showSnackbar = false;
    private crumbs: Array<any> = [];
    public color = 0;
    public qtyArray: Array<string> = [];
    public options: Array<IProcessOptionModel> = [];
    public vm: ProductViewModel = new ProductViewModel();
    public cartVm: CartViewModel = new CartViewModel();
    public pvm: ParentProductViewModel = new ParentProductViewModel();
    public isPreview = false;
    private disableButton = false;

    @Prop()
    public productId: number | null = null;

    @Prop()
    public categoryId!: number;

    // @ts-ignore
    public $refs!: {
        singleSelector: ProductInfoSelector;
        bulkSelector: BulkInfoSelector;
        reqSelector: RequestorInfoSelector;
        bulkPage: BulkProductPage;
        snack: ProductDialog;
    };

    private async setColor(e: number) {
        if (e != undefined) {
            this.color = e;
        }
    }

    private async addToCart() {
        if (this.disableButton) {
            return;
        }

        this.disableButton = true;
        try {
            if (this.tab == "0") {
                await this.$refs.singleSelector.addToCart();
            } else if (this.cartVm.isRequestor) {
                await this.$refs.reqSelector.addToCartRequestor();
            } else {
                if (this.vm.options.length > 0) {
                    await this.$refs.bulkSelector.proceedToCustomize();
                } else {
                    await this.bulkAddToCart();
                }
            }
        } finally {
            this.disableButton = false;
        }
    }

    private maxQuantityReached() {
        let qtyReached = false;
        let designIds: any[] = [];
        let runningTotal = 0;
        //if there are multiple designs, we have to check the quantity of each design in the cart
        this.vm.colorSize.designsObject.designs.forEach((design: any) => {
            designIds.push(design.value);
        });

        this.cartVm.contents.forEach((item) => {
            if (designIds.includes(item.eventProductId)) {
                if (item.packageId == null) {
                    runningTotal += item.itemQuantity;
                }
            }
        });

        if (runningTotal >= this.vm.product!.maxQuantity) {
            qtyReached = true;
        }

        return qtyReached;
    }

    private async bulkAddToCart() {
        var bulkModel = new AddToCartBulkArgs();

        this.vm.bulkSizes.forEach((size) => {
            if (size.quantity > 0) {
                for (let i = 0; i < size.quantity; i++) {
                    let arg = new AddToCartArgs();
                    arg.productId = this.vm.product!.eventProductID;
                    arg.sizeId = size.value;
                    arg.categoryId = this.categoryId;
                    arg.colorId = this.vm.selectedColor.eventProductColorId;
                    arg.quantity = 1;
                    arg.eventId = this.vm.product!.eventID;

                    let item = size.options[i];
                    if (item) {
                        item.forEach((attribute: any) => {
                            if (attribute.userValue) {
                                const po = new ProductOption();
                                if (attribute.processType == 4 || attribute.processType == 5) {
                                    attribute.options.forEach((choice: IProcessOptionItem) => {
                                        if (choice.display == attribute.userValue) {
                                            po.processOptionId = choice.optionItemId;
                                        }
                                    });
                                }
                                po.text = attribute.userValue;
                                po.processItemId = attribute.optionId;
                                arg.productOptions.push(po);
                            }
                        });
                    }

                    bulkModel.items.push(arg);
                }
            }
        });
        this.vm.loading = true;
        await WebHelper.postJsonData("/api/EventProductEcom/AddToCartBulk", bulkModel);
        this.vm.loading = false;
        await this.$refs.snack.showSnackbar(bulkModel.items.length);
    }

    protected screenCheck() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return true;
        } else {
            return false;
        }
    }

    private isSticky() {
        if (this.tab == "0") {
            if (this.$refs.singleSelector) {
                return this.$refs.singleSelector.isSticky();
            } else {
                return false;
            }
        } else if (this.tab == "1") {
            return this.vm.areItemsPresent();
        }
    }

    private async changeDesign(e: number) {
        var tempColor = this.vm.selectedColor;
        var tempSize = this.vm.selectedSize;
        var tempQuantity = this.vm.productQuantity;
        var tempSizeDisplay = "";
        this.vm.colorSize.sizes?.forEach((size) => {
            if (size.value == tempSize) {
                tempSizeDisplay = size.display;
            }
        });
        this.vm.loading = true;
        var child: ProductViewModel = await this.pvm.getChild(e, this.vm.categoryId);
        this.vm.loading = false;
        child.colorSize.designsObject.designs.forEach((button: any) => {
            if (button.value == e) {
                button.selected = true;
            } else {
                button.selected = false;
            }
        });
        this.vm = child;
        let tempId = 0;
        child.colorSize.colors?.forEach((color) => {
            if (color.colorName === tempColor.colorName) {
                tempId = color.eventProductColorId;
            }
        });

        // In case the child color name has been modified by color assignment/assign by product (e.g. 'Black - PC55'),
        // set tempColor and tempID using first available child color
        if (tempId == 0 && child.colorSize.colors && child.colorSize.colors.length > 0) {
            tempId = child.colorSize.colors[0].eventProductColorId;
            tempColor = child.colorSize.colors[0];
        }

        await this.vm.rebuildColorsAndSizes(tempId);
        this.vm.productColorCollection.forEach((color) => {
            if (color.colorName === tempColor.colorName) {
                child.selectedColor = color;
            }
        });

        child.colorSize.sizes?.forEach((size) => {
            if (size.display === tempSizeDisplay) {
                child.selectedSize = size.value;
            }
        });
        child.productQuantity = tempQuantity;

        this.productId = child.productId;
    }

    public get returnToStoreUrl() {
        let storeId = this.vm!.product!.eventID;
        let baseUrl = `./store.aspx?eid=${storeId}&apid=${this.productId}`;
        if (this.categoryId) {
            baseUrl += `&acid=${this.categoryId}`;
        }
        return baseUrl;
    }

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("pid")) {
            this.productId = Number(urlParams.get("pid"));
        }

        if (urlParams.has("cid")) {
            this.categoryId = Number(urlParams.get("cid"));
        }

        if (this.productId) {
            await this.pvm.getParent(this.productId, this.categoryId);
            // this.pvm.parent.colorSize.designsObject.designs.forEach((button: any) => {
            //     button.enabled = false;
            // });
            this.vm = this.pvm.parent;
            if (this.vm.product != null) {
                await this.cartVm.get(this.vm.product.eventID);
                await this.cartVm.getCartItems(this.vm.product.eventID);
                await this.cartVm.getCategories(this.vm.product.eventID, true);
                this.cartVm.initialized = true;
            }
        }

        this.crumbs = [];
        if (this.vm.product?.parentCategoryName && this.vm.product.parentCategoryName != "") {
            var parentId = 0;
            this.cartVm.categories.containers.forEach((cat) => {
                if (cat.name == this.vm.product?.parentCategoryName) {
                    parentId = cat.eventCategoryID;
                }
            });

            var parentCat: { text: string; disabled: boolean; href: string } = {
                text: this.vm.product.parentCategoryName,
                disabled: true,
                href: `store.aspx?eid=${this.vm.product.eventID}&acid=` + parentId,
            };
            this.crumbs.push(parentCat);
        }

        if (this.vm.product?.categoryName && this.vm.product.categoryName != "") {
            var childCat: { text: string; disabled: boolean; href: string } = {
                text: this.vm.product.categoryName,
                disabled: false,
                href: `store.aspx?eid=${this.vm.product.eventID}&acid=` + this.categoryId,
            };
            this.crumbs.push(childCat);
        }

        this.maxQuantityReached();
    }
}
