import { AddressValidationViewModel } from "@/chipply/address-validation/AddressValidationViewModel";
import { IAddressValidationResults } from "@/chipply/address-validation/IAddressValidationResults";
import ICheckoutReviewOrder from "@/chipply/ICheckoutReviewOrder";
import IEventBrandingDto from "@/chipply/IEventBrandingDto";
import IEventBrandingResults from "@/chipply/IEventBrandingResults";
import PaymentInfo from "@/chipply/PaymentInfo";
import CheckoutReviewOrderItemViewModel from "@/chipply/view-model/CheckoutReviewOrderItemViewModel";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import {
    Address,
    BooleanFieldModel,
    FieldValueCollectionEditViewModel,
    FieldValueEditViewModel,
    IAddress,
    IEcertBalanceInfo,
    IOrder,
    IOrderTotals,
    ITextValue,
    ListFieldModel,
    Utils,
    WebHelper,
} from "chipply-common";
import IPickupLocation from "../IPickupLocation";
import RecaptchaClient from "../verify/RecaptchaClient";
import Decimal from "decimal.js";
import OrderFeeDetail from "@/chipply/OrderFeeDetail";

export default class CheckoutReviewViewModel {
    public get hasAddressValidationViewModel() {
        return this.addressValidationViewModel != null;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public set hasAddressValidationViewModel(value: boolean) {}

    public addressValidationViewModel: AddressValidationViewModel | null = null;
    public orderKey = "";
    public paymentPublicApiKey = "";
    public paymentIntentClientSecret = "";

    public skipPaymentProcessing = false;

    public poNumberChanged = (value: string) => {
        if (this.skipPaymentProcessing && !value) {
            this.skipPaymentProcessing = false;
        }
    };

    public get totalDue() {
        if (!this.totals) {
            return 0;
        }
        const result = new Decimal(this.totals!.orderTotal)
            .minus(this.totals!.ecertTotal)
            .minus(this.totals!.couponTotal);
        return result.toNumber();
    }

    public branding: IEventBrandingDto = {} as any;
    public dealerName: string | null = null;
    public orderInstructions: string | null = null;
    public shippingType: string | null = null;
    public ecertCode = "";
    public eventId: number | null = null;
    public ecertError: string | null = null;
    public promoCode: string | null = null;
    public promoCodeError: string | null = null;
    public allowPo = false;
    public allowCoupon = false;
    public allowEcert = false;
    public hideCreditCard = true;
    public hideBilling = false;
    public hideVendor = false;
    public requireTermsAgreement = false;
    public appliedPromoCode: string | null = null;
    public appliedEcertCodes: IEcertBalanceInfo[] = [];
    public loading = false;
    public orderErrorMessage: string | null = null;
    public orderErrorHeader: string | null = null;
    public showOrderError = false;
    public agreedToTerms = false;
    public creditCardValid = false;
    public isRequestor = false;
    public isGroup = false;
    public storeInstructions = "";
    public billingAddress: IAddress | null = null;
    public shippingAddress: IAddress | null = null;
    public items: CheckoutReviewOrderItemViewModel[] = [];
    public pickupLocation: string | null = null;
    public smsOptIn = false;
    public hasSms = false;
    //TODO remove
    //public myCheckoutReviewOrder: ICheckoutReviewOrder | null = null;
    public order: IOrder | null = null;
    public availablePickups: IPickupLocation[] = [];
    public organizationPickups: ITextValue<number>[] = [];
    public distroGroup = false;
    public distroPickup = false;
    public distroShipping = false;
    public selectedOrganizationPickupId = 0;
    public allowInternationalShipping = false;
    public allowMilitaryShipping = false;
    public fieldValueCollection: FieldValueCollectionEditViewModel | null = null;
    private _selectedLocation: IPickupLocation | null = null;
    public get selectedLocation() {
        return this._selectedLocation;
    }
    public set selectedLocation(v) {
        this._selectedLocation = v;
    }
    public totals: IOrderTotals | null = null;

    public payment = new PaymentInfo();
    public stripe: Stripe | null = null;
    public stripeElementsInstance: StripeElements | null = null;
    public initialized = false;

    public originalOrderTotal = 0;

    public showDetailedHandlingFees = false;
    public detailedHandlingFeesList: OrderFeeDetail[] = [];

    public getFieldValue(fieldEditViewModel: FieldValueEditViewModel, value: any) {
        if (!fieldEditViewModel) {
            return "";
        }

        if (value === null) return "";

        if (fieldEditViewModel.field instanceof BooleanFieldModel) {
            return value === true ? "Yes" : "No";
        }

        if (!(fieldEditViewModel.field instanceof ListFieldModel)) {
            return value;
        }

        const listItem = fieldEditViewModel.field.items.find((i) => i.id == value);
        if (!listItem) {
            return "";
        }

        return listItem.name;
    }
    public toModel(): ICheckoutReviewOrder {
        return {
            allowCoupon: this.allowCoupon,
            allowEcert: this.allowEcert,
            allowPo: this.allowPo,
            availablePickups: this.availablePickups,
            dealerName: this.dealerName!,
            distroGroup: this.distroGroup,
            distroPickup: this.distroPickup,
            distroShipping: this.distroShipping,
            hasSms: this.hasSms,
            hideCreditCard: this.hideCreditCard,
            isRequestor: this.isRequestor,
            isGroup: this.isGroup,
            order: this.order!,
            orderKey: this.orderKey,
            orderInstructions: this.orderInstructions!,
            organizationBranchId: this.selectedOrganizationPickupId,
            organizationPickups: this.organizationPickups,
            pickupLocation: this.pickupLocation,
            requireTermsAgreement: this.requireTermsAgreement,
            allowInternationalShipping: this.allowInternationalShipping,
            allowMilitaryShipping: this.allowMilitaryShipping,
            paymentPublicApiKey: this.paymentPublicApiKey,
            paymentIntentClientSecret: this.paymentIntentClientSecret,
            showDetailedHandlingFees: this.showDetailedHandlingFees,
            detailedHandlingFeesList: this.detailedHandlingFeesList,
            originalOrderTotal: this.originalOrderTotal,
            hideBilling: this.hideBilling,
        };
    }

    public async get(eventId: number): Promise<void> {
        this.eventId = eventId;

        let checkoutReviewOrder: ICheckoutReviewOrder | null = null;
        try {
            checkoutReviewOrder = (await WebHelper.getJsonData(`/api/Event/Order/${eventId}`)) as ICheckoutReviewOrder;
            if (!checkoutReviewOrder) {
                location.assign("./");
                return;
            }
        } catch (e) {
            location.assign("./");
            return;
        }

        if (checkoutReviewOrder.order.billingAddress == null) {
            checkoutReviewOrder.order.billingAddress = new Address();
            checkoutReviewOrder.order.billingAddress.country = "USA";
        }
        if (checkoutReviewOrder.order.shippingAddress == null) {
            checkoutReviewOrder.order.shippingAddress = new Address();
        }
        this.paymentPublicApiKey = checkoutReviewOrder.paymentPublicApiKey;
        this.paymentIntentClientSecret = checkoutReviewOrder.paymentIntentClientSecret;

        this.hasSms = checkoutReviewOrder.hasSms;
        this.isRequestor = checkoutReviewOrder.isRequestor;
        this.isGroup = checkoutReviewOrder.isGroup;
        this.allowPo = checkoutReviewOrder.allowPo;
        this.allowCoupon = checkoutReviewOrder.allowCoupon;
        this.allowEcert = checkoutReviewOrder.allowEcert;
        this.hideCreditCard = checkoutReviewOrder.hideCreditCard;
        this.requireTermsAgreement = checkoutReviewOrder.requireTermsAgreement;
        this.order = checkoutReviewOrder.order;
        this.billingAddress = this.order.billingAddress;
        this.shippingAddress = this.order.shippingAddress;
        this.shippingType = this.order.shippingType;
        this.pickupLocation = checkoutReviewOrder.pickupLocation;
        this.organizationPickups = checkoutReviewOrder.organizationPickups;
        this.allowInternationalShipping = checkoutReviewOrder.allowInternationalShipping;
        this.allowMilitaryShipping = checkoutReviewOrder.allowMilitaryShipping;

        this.items = [];
        for (const product of this.order.products) {
            this.items.push(new CheckoutReviewOrderItemViewModel(product));
        }

        this.orderKey = checkoutReviewOrder.orderKey;
        this.totals = checkoutReviewOrder.order.totals;
        this.dealerName = checkoutReviewOrder.dealerName;
        this.orderInstructions = checkoutReviewOrder.orderInstructions;
        this.distroGroup = checkoutReviewOrder.distroGroup;
        this.distroPickup = checkoutReviewOrder.distroPickup;
        this.distroShipping = checkoutReviewOrder.distroShipping;
        this.availablePickups = checkoutReviewOrder.availablePickups;

        if (checkoutReviewOrder.fields && checkoutReviewOrder.fields.fields.length > 0) {
            const vm = new FieldValueCollectionEditViewModel();
            vm.fields = checkoutReviewOrder?.fields?.fields ?? [];
            vm.fieldValues = checkoutReviewOrder?.fieldValues?.fieldValues ?? [];
            vm.initialize();
            this.fieldValueCollection = vm;
        }

        if (!this.stripe && !this.isRequestor) {
            this.stripe = await loadStripe(this.paymentPublicApiKey);

            this.stripeElementsInstance = this.stripe!.elements({
                mode: "payment",
                amount: this.getStripeAmount(),
                currency: "usd",
                paymentMethodCreation: "manual",
                setupFutureUsage: "on_session",
                paymentMethodTypes: ["card"],
            });
        }

        this.showDetailedHandlingFees = checkoutReviewOrder.showDetailedHandlingFees;
        this.detailedHandlingFeesList = checkoutReviewOrder.detailedHandlingFeesList;

        this.hideBilling = checkoutReviewOrder.hideBilling;

        this.initialized = true;
    }

    protected getStripeAmount() {
        if (this.totalDue <= 0) {
            // Stripe errors out when you give it 0.
            // Let's give it a dollar since if the totalDue
            // ends up being 0 we will skip their processing anyhow.
            return 100;
        }
        const result = new Decimal(this.totalDue).mul(100);
        return result.toNumber();
    }

    public async getBrandingInfo(eventId: number): Promise<void> {
        let baseUrl = "/api/Event/Branding?eventId=";
        baseUrl += encodeURIComponent(eventId);
        const results = (await WebHelper.getJsonData(baseUrl)) as IEventBrandingResults;
        if (results && results.dto) {
            this.branding = results.dto;
        }
    }

    public async saveAdditionalInfo() {
        await WebHelper.postJsonData(`/api/Store/SaveAdditionalInfo/${this.eventId}`, {
            fieldValues: this.fieldValueCollection!.toModel(),
        });
    }

    public get forceShippingAddress() {
        if (this.hideBilling && this.shippingType == "Shipping") {
            return true;
        }
        return !this.allowInternationalShipping && this.billingAddress!.country != "USA";
    }

    public async saveCustomerInfo() {
        const checkoutReviewOrder = await WebHelper.postJsonData(
            `/api/Event/Order/SaveOrder/CustomerInfo/${this.eventId}`,
            this.toModel()
        );
    }

    public async saveOrder(
        sameAddress: boolean,
        validateAddress: boolean
    ): Promise<{ saveSucceeded: boolean; fixAddress: boolean }> {
        if (sameAddress && this.billingAddress) {
            this.shippingAddress = this.billingAddress;
        } else if (this.order) {
            this.shippingAddress = this.order.shippingAddress;
        }
        try {
            const saveOrderResults = await WebHelper.postJsonData(
                `/api/Event/Order/SaveOrder/${this.eventId}/${validateAddress}`,
                this.toModel()
            );

            const addressValidationResults = await this.handleAddressValidation(saveOrderResults, sameAddress);
            return { saveSucceeded: true, fixAddress: addressValidationResults.fixAddress };
        } catch (e) {
            this.orderErrorHeader = "Order Error";
            this.orderErrorMessage = `An unexpected issue occurred when computing Pickup/Delivery Information, please refresh and try again`;
            this.showOrderError = true;
            // tslint:disable-next-line:no-console
            return { saveSucceeded: false, fixAddress: false };
        }
    }

    protected async handleAddressValidation(
        saveOrderResults: string,
        sameAddress: boolean
    ): Promise<{ fixAddress: boolean }> {
        const deserializedResponse = JSON.parse(saveOrderResults) as {
            addressValidationResults: IAddressValidationResults;
        };
        const addressValidationResults = deserializedResponse.addressValidationResults;
        if (!addressValidationResults) return { fixAddress: false };
        if (addressValidationResults.isValid && !addressValidationResults.hasEnhancedMatch)
            return { fixAddress: false };

        this.addressValidationViewModel = new AddressValidationViewModel(
            this.shippingAddress ?? this.billingAddress!,
            addressValidationResults
        );
        const dialogResult = await this.addressValidationViewModel.interact();
        const vm = this.addressValidationViewModel;
        this.addressValidationViewModel = null;

        if (vm.showAddressCorrection) {
            if (vm.selection == "corrected") {
                const correctedAddress = vm.addressResults.correctionResults.address;
                this.assignAddress(correctedAddress, this.shippingAddress);
                if (sameAddress) {
                    this.assignAddress(correctedAddress, this.billingAddress);
                }

                const checkoutReviewOrder = await WebHelper.postJsonData(
                    `/api/Event/Order/SaveOrder/${this.eventId}/false`,
                    this.toModel()
                );
            }
        }

        return { fixAddress: !addressValidationResults.isValid && dialogResult == "accept" };
    }

    protected assignAddress(assignFromAddress: IAddress, assignToAddress: IAddress | null) {
        if (assignToAddress == null) return;
        assignToAddress.addressLine1 = assignFromAddress.addressLine1;
        assignToAddress.addressLine2 = assignFromAddress.addressLine2;
        assignToAddress.city = assignFromAddress.city;
        assignToAddress.state = assignFromAddress.state;
        assignToAddress.zipCode = assignFromAddress.zipCode;
    }

    public async deleteEcertCode(index: number) {
        this.appliedEcertCodes.splice(index, 1);
        await this.refreshOrderTotals();
    }

    public async deletePromoCode() {
        this.appliedPromoCode = null;
        await this.refreshOrderTotals();
    }

    public async applyPromo() {
        if (!this.promoCode || this.promoCode.toUpperCase().trim() === this.appliedPromoCode) {
            return;
        }
        this.promoCodeError = null;
        this.loading = true;
        const validationResultsString = await WebHelper.postJsonData("/api/Event/ValidateCoupon", {
            eventId: this.eventId,
            couponCode: this.promoCode,
            cartTotal: this.totals!.cartTotal,
        });

        const validationResults = JSON.parse(validationResultsString);

        if (!validationResults.valid) {
            this.promoCode = null;
            if (validationResults.minimumCartTotalError) {
                this.promoCodeError = "Minimum cart total for promo not met.";
            }
            if (validationResults.couponUsagesError) {
                this.promoCodeError = "This promo has already been used and cannot be applied.";
            }
            if (validationResults.doesNotExistError) {
                this.promoCodeError = "The specified promo code does not exist.";
            }
            this.loading = false;
            return;
        }

        this.appliedPromoCode = this.promoCode.toUpperCase().trim();

        await this.refreshOrderTotals();

        if (this.totals!.couponTotal <= 0) {
            this.promoCodeError = "The specified promo code is not applicable.";
            this.appliedPromoCode = null;
        }

        this.promoCode = null;
        this.loading = false;
    }

    public async placeOrder(): Promise<{ success: boolean; showConfirmation: boolean }> {
        if (this.checkForOrderError()) {
            return { success: false, showConfirmation: false };
        }
        this.loading = true;
        const token = await this.recaptchaHandler();
        return await this.placeOrderCore(token);
    }

    protected async recaptchaHandler() {
        const recaptchaClient = new RecaptchaClient();
        const token = await recaptchaClient.execute({
            action: "submit",
        });
        return token;
    }

    /**
     * Place the order
     *
     * @param token
     * @protected
     * @returns {Promise<boolean>} true if there was an error
     */
    protected async placeOrderCore(token: string): Promise<{ success: boolean; showConfirmation: boolean }> {
        const ecertCodes = [];
        for (const ecert of this.appliedEcertCodes) {
            ecertCodes.push(ecert.ecertCode);
        }

        const preauthorizeArgs = {
            ecertCodes,
            eventId: this.eventId,
            poNumber: this.payment.poNumber,
            couponCode: this.appliedPromoCode,
            cardInfo: this.payment.card,
            storeInstructions: this.storeInstructions,
            smsOptIn: this.smsOptIn,
            skipPaymentProcessing: this.skipPaymentProcessing || this.hideCreditCard,
            token,
        };

        const preauthorizeResultsString = await WebHelper.postJsonData(
            "/api/Event/PreauthorizeOrder",
            preauthorizeArgs
        );
        const preauthorizeResults = JSON.parse(preauthorizeResultsString);

        if (!preauthorizeResults.success) {
            this.orderErrorHeader = "Order Error";
            this.orderErrorMessage = preauthorizeResults.errorMessage;
            this.showOrderError = true;
            this.loading = false;
            return { success: false, showConfirmation: false };
        }

        if (!preauthorizeResults.needsPayment) {
            return { success: true, showConfirmation: true };
        }

        if (this.hideCreditCard && this.payment.poNumber) {
            return { success: true, showConfirmation: true };
        }

        if (this.isRequestor) {
            return { success: true, showConfirmation: true };
        }

        // Trigger form validation and wallet collection
        // const { error: stripeConfirmationError } = await this.stripe!.confirmPayment({
        //     elements: this.stripeElementsInstance!,
        //     confirmParams: {
        //         return_url: this.getOrderConfirmationUrl(),
        //     },
        // });

        const { error: submitError } = await this.stripeElementsInstance!.submit();
        if (submitError) {
            this.orderErrorHeader = "Order Error";
            this.orderErrorMessage = submitError.message || submitError.type;
            this.showOrderError = true;
            this.loading = false;
            return { success: false, showConfirmation: false };
        }

        const { error, paymentMethod } = await this.stripe!.createPaymentMethod({
            elements: this.stripeElementsInstance!,
        });

        if (error) {
            this.orderErrorHeader = "Order Error";
            this.orderErrorMessage = error.message || error.type;
            this.showOrderError = true;
            this.loading = false;
            return { success: false, showConfirmation: false };
        }

        const confirmArgs = {
            paymentMethodId: paymentMethod!.id,
            returnUrl: this.getOrderConfirmationUrl(),
            eventId: this.eventId,
            token,
        };

        const confirmResultsString = await WebHelper.postJsonData("/api/Event/ConfirmPayment", confirmArgs);
        const confirmResults = JSON.parse(confirmResultsString);

        if (!confirmResults.success) {
            this.orderErrorHeader = "Order Error";
            this.orderErrorMessage = confirmResults.errorMessage;
            this.showOrderError = true;
            this.loading = false;
            return { success: false, showConfirmation: false };
        }

        if (confirmResults.stripeStatus == "requires_action") {
            const { error, paymentIntent } = await this.stripe!.handleNextAction({
                clientSecret: confirmResults.clientSecret,
            });

            if (error) {
                this.orderErrorHeader = "Order Error";
                this.orderErrorMessage = error.message || error.type;
                this.showOrderError = true;
                this.loading = false;
                return { success: false, showConfirmation: false };
            }
        } else {
            return { success: true, showConfirmation: true };
        }

        return { success: true, showConfirmation: true };
    }

    public getOrderConfirmationUrl() {
        const storeUrl = encodeURIComponent(location.href.substr(0, location.href.lastIndexOf("/")));
        return `${location.origin}/ng/order-confirmation.html?eventid=${this.eventId}&orderid=${this.order?.orderNumber}&orderkey=${this.orderKey}&storeurl=${storeUrl}`;
    }
    public async continueShopping() {
        location.assign("./store.aspx");
    }

    public async applyEcert() {
        this.ecertError = null;
        if (!this.ecertCode.trim()) {
            return;
        }
        this.ecertCode = this.ecertCode.toUpperCase();
        const alreadyEnteredEcertCode = this.appliedEcertCodes.find((e) => e.ecertCode === this.ecertCode);
        if (alreadyEnteredEcertCode) {
            this.ecertError = "This E-Certificate is already applied";
            return;
        }
        this.loading = true;
        const ecertBalanceInfo = (await WebHelper.getJsonData(
            `/api/Event/Ecert/${this.eventId}/${this.ecertCode}`
        )) as IEcertBalanceInfo;
        if (ecertBalanceInfo === null) {
            this.ecertError = "The supplied E-Certificate is invalid";
            this.loading = false;
            return;
        }

        this.appliedEcertCodes.push(ecertBalanceInfo);
        await this.refreshOrderTotals();
        this.ecertCode = "";
        this.loading = false;
    }

    protected checkForOrderError() {
        if (this.isRequestor) {
            return false;
        }

        if (Utils.hasValue(this.orderInstructions!) && this.requireTermsAgreement && !this.agreedToTerms) {
            this.orderErrorHeader = "Order Error";
            this.orderErrorMessage = "You must agree to the note to continue.";
            this.showOrderError = true;
            return true;
        }

        if (this.hideCreditCard && this.totalDue > 0 && this.allowPo && !this.payment.poNumber) {
            this.orderErrorHeader = "Order Error";
            this.orderErrorMessage = "You must enter a PO Number";
            this.showOrderError = true;
            return true;
        }

        return false;
    }

    public async refreshOrderTotals() {
        this.loading = true;
        let baseUrl = `/api/Event/OrderTotals/${this.eventId}`;

        let firstParameter = true;
        for (const ecertCode of this.appliedEcertCodes) {
            baseUrl += firstParameter ? "?" : "&";
            firstParameter = false;
            baseUrl += "ecertCodes=" + ecertCode.ecertCode;
        }

        if (this.appliedPromoCode) {
            baseUrl += firstParameter ? "?" : "&";
            firstParameter = false;
            baseUrl += "promoCode=" + this.appliedPromoCode;
        }

        const orderTotals = (await WebHelper.getJsonData(baseUrl)) as IOrderTotals;
        this.totals = orderTotals;

        if (this.stripeElementsInstance && this.totalDue > 0) {
            this.stripeElementsInstance.update({ amount: this.getStripeAmount() });
        }

        this.loading = false;
    }
}
