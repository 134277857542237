




















export default {
    name: "CIconApplyChange",
};
