



import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import DealerLanding from "@/components/DealerLanding.vue";
import Cart from "@/components/Cart.vue";
@Component({
    components: { DealerLanding, Cart },
})
export default class CartPage extends Vue {
    public name = "CartPage";
    public dealerId: number | null = null;
    public eventId: number | null = null;
    public $refs!: {
        landing: DealerLanding;
    };

    @Prop({ default: false })
    public isPreview!: boolean;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eventId")) {
            if (!this.eventId) {
                this.eventId = Number(urlParams.get("eventId"));
            }
        }
        if (urlParams.has("dealerId")) {
            this.dealerId = Number(urlParams.get("dealerId"));
        }
    }

    @Watch("dealerId")
    public async onDealerChange() {
        if (this.dealerId) {
            this.$refs.landing.dealerId = this.dealerId;
        }
    }
}
