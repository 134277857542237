import DetailColor from "./DetailColor";
import DetailSize from "./DetailSize";
import ProductColorSizeInfoModel from "./ProductColorSizeInfoModel";
import DesignWrapperModel from "@/chipply/DesignWrapperModel";

export default class ProductColorSizeWrapperModel {
    // colorSizeDictionary: Map<number, Map<number, ProductColorSizeInfoModel>> | null = null;
    colorSizeDictionary: { [key: number]: { [key: number]: ProductColorSizeInfoModel } } | null = null;
    colors: Array<DetailColor> | null = null;
    maxQuantity: number | null = null;
    minQuantity: number | null = null;
    sizes: Array<DetailSize> | null = null;
    designsObject: DesignWrapperModel = new DesignWrapperModel();
    //designsObject: { designs: any[]; hasChildren: boolean } = { designs: [], hasChildren: false };
}
