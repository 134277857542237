
























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

@Component({
    props: {
        slidePresent: Boolean,
        url: String,
        storeOpen: Boolean,
    },
    components: {},
})
export default class PreviewFlag extends Vue {
    public slidePresent = false;
    public storeOpen!: boolean;
    public url!: string;
}
