



























































































































































































































































































































































































































import IPickupLocation from "@/chipply/IPickupLocation";
import CheckoutReviewViewModel from "@/chipply/view-model/CheckoutReviewViewModel";
import AddressDisplay from "@/components/AddressDisplay.vue";
import AddressValidationCard from "@/components/AddressValidationCard.vue";
import CAddressEditor from "@/components/CAddressEditor.vue";
import CheckoutConfirmation from "@/components/CheckoutConfirmation.vue";
import CheckoutReviewRevised from "@/components/CheckoutReviewRevised.vue";
import FieldValueCollectionEditor from "@/components/common-vue/FieldValueCollectionEditor.vue";
import CouponCodeApplier from "@/components/CouponCodeApplier.vue";
import ItemDisplay from "@/components/ItemDisplay.vue";
import OrderSummary from "@/components/OrderSummary.vue";
import StoreLayout from "@/components/StoreLayout.vue";
import { Address, ShippingType, ShippingTypeHelper, Utils, WebHelper } from "chipply-common";
import CCreditCard from "@/components/CCreditCard.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import ICheckoutReviewOrder from "@/chipply/ICheckoutReviewOrder";

@Component({
    components: {
        AddressValidationCard,
        FieldValueCollectionEditor,
        CouponCodeApplier,
        OrderSummary,
        ItemDisplay,
        AddressDisplay,
        StoreLayout,
        CCreditCard,
        CAddressEditor,
        CheckoutReviewRevised,
        CheckoutConfirmation,
    },
    computed: {
        mobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    directives: {
        mask,
    },
})
export default class CheckoutStepper extends Vue {
    public Utils = Utils;

    public name = "CheckoutReview";
    @Prop({ type: Number })
    public eventId!: number | null;

    public stepName!: string;

    // @ts-ignore
    public $refs!: {
        billingForm: any;
        shippingForm: any;
        additionalInfoForm: any;
        storeLayout: StoreLayout;
    };

    public vm: CheckoutReviewViewModel = new CheckoutReviewViewModel();
    protected readonly eventIdParamName = "eventid";
    public address: Address = new Address();
    public shippingAddress: Address = new Address();
    public preventRepeat = 0;
    public createdRepeat = 0;
    public sameAddress = false;
    public shipDirect = false;
    public billFormValid = false;
    public shipFormValid = false;
    public additionalInfoValid = false;
    public drawer = false;
    public advancing = false;
    public isLoading = false;
    public locationPicked = false;

    //Warning booleans for the stepper
    public noteWarning = false;
    public additionalInfoWarning = false;
    public pickupDeliveryWarning = false;
    public billingInfoWarning = false;
    public reviewWarning = false;

    public testValue = "";
    public testLabel = "testPhone";
    public focus = false;
    public reviewComplete = false;

    //array of stepNames
    public stepNames: string[] = ["billingInfo", "additionalInfo", "pickupDelivery", "dealerNotes", "checkoutReview"];

    public stepMap: Map<string, boolean> = new Map<string, boolean>([
        ["billingInfo", false],
        ["additionalInfo", false],
        ["pickupDelivery", false],
        ["dealerNotes", false],
        ["checkoutReview", false],
    ]);

    public stepCompleted: boolean[] = [];

    // TODO: Refactor validation into common and then remove this code
    public requireValidEmail(email: string) {
        if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
            )
        ) {
            return true;
        } else {
            return "Please enter a valid email address";
        }
    }

    public emailKeydown(event: KeyboardEvent) {
        if (/\s/.test(event.key)) {
            event.preventDefault();
        }
    }

    public data() {
        return {
            e6: 1,
        };
    }

    public showDealerNotes() {
        if (this.vm.orderInstructions == "" && this.vm.requireTermsAgreement == false) {
            return false;
        } else {
            return true;
        }
    }

    public getStepNumber(stepName: string) {
        if (this.stepNames.indexOf(stepName) < 0) {
            return -1;
        }
        return this.stepNames.indexOf(stepName) + 1;
    }

    public orderPlaced(results: { success: boolean; showConfirmation: boolean }) {
        if (results.success) {
            this.reviewComplete = true;
            this.reviewWarning = false;

            if (results.showConfirmation) {
                location.assign(this.vm.getOrderConfirmationUrl());
            }
        } else {
            this.reviewWarning = true;
        }
    }

    public getTop() {
        let compHeight = "";
        if (this.$refs) {
            if (this.$refs.storeLayout) {
                if (this.$refs.storeLayout.$refs) {
                    compHeight = (this.$refs.storeLayout.$refs.appbar as any).computedHeight + 100 + "px";
                }
            }
        }

        return compHeight;
    }

    public getCartWidth() {
        if (this.$vuetify.breakpoint.smAndDown) {
            return "90%";
        } else {
            return "50%";
        }
    }

    private formatPhone(phoneNumberString: string) {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return null;
    }

    public getCartPadding() {
        if (this.$vuetify.breakpoint.lgAndUp) {
            return "200px";
        }
    }

    public getCartHeight() {
        if (this.$vuetify.breakpoint.smAndDown) {
            return "90%";
        } else if (this.$vuetify.breakpoint.md) {
            return "50%";
        } else {
            return "66%";
        }
    }

    public getInfoWidth() {
        if (this.$vuetify.breakpoint.smAndDown) {
            return "100%";
        } else {
            return "50%";
        }
    }

    public get showAdditionalInfo() {
        return this.vm.fieldValueCollection != null;
    }

    private async getNextStep(currentStep: string) {
        var index = this.stepNames.indexOf(currentStep);
        if (index < this.stepNames.length - 1) {
            return this.stepNames[index++];
        } else {
            return currentStep;
        }
    }

    public get showCheckoutReview() {
        if (!this.vm) {
            return false;
        }
        if (Utils.hasValue(this.vm.orderInstructions!) && this.vm.requireTermsAgreement && !this.vm.agreedToTerms) {
            return false;
        }

        if (this.$data.e6 == this.getStepNumber("checkoutReview")) {
            return true;
        } else {
            return false;
        }
    }

    public async created() {
        var translatedStep = this.stepNames.indexOf(this.stepName) + 1;

        this.$data.e6 = translatedStep;
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("eventId")) {
            this.eventId = Number(urlParams.get("eventId"));
        }
        if (this.eventId) {
            await this.onEventIdChanged();
        }
        if (urlParams.has("stepName")) {
            this.stepName = String(urlParams.get("stepName"));
        }
        if (this.stepName) {
            await this.onStepNameChanged();
        }

        //Saving order to update totals with preset values
        if (this.getStepNumber(this.stepName) < this.getStepNumber("pickupDelivery")) {
            if (this.vm.availablePickups) {
                if (this.vm.availablePickups.length === 1) {
                    this.vm.selectedLocation = this.vm.availablePickups[0];
                }
            }
            await this.updatePickupLocation();
            //await this.saveOrder();
            try {
                this.isLoading = true;
                await this.vm.getBrandingInfo(this.eventId!);
                await this.vm.get(this.eventId!);
            } finally {
                this.isLoading = false;
            }
            await this.clearPickupLocation();
        } else if (this.getStepNumber(this.stepName) === this.getStepNumber("pickupDelivery")) {
            await this.clearPickupLocation();
        }

        //see if billing is the same as shipping
        if (this.vm.order) {
            this.sameAddress = this.vm.order.useBillingAddress;
        }

        //Validate to make sure they're not skipping steps
        await this.validateSteps(this.stepName);
        this.completePrevious();

        this.createdRepeat++;

        //Catch the browser back button and change the current stepper step
        window.onpopstate = (event: Event) => {
            const backUrl = new URLSearchParams(window.location.search);
            this.stepName = String(backUrl.get("stepName"));
            if (this.stepName == "checkoutReview") {
                this.vm.continueShopping();
            } else {
                var index = this.stepNames.indexOf(this.stepName);
                this.$data.e6 = index + 1;
            }
        };
    }

    public async validateSteps(currentStep: string) {
        //If the user tries to navigate to a step that doesn't exist, send them to the beginning
        if (this.getStepNumber(currentStep) < 0) {
            this.stepName = "billingInfo";
            this.onStepNameChanged();
            this.$refs.billingForm.resetValidation();
            return;
        }
        if (!this.$refs.billingForm.validate()) {
            this.stepName = "billingInfo";
            this.onStepNameChanged();
            this.$refs.billingForm.resetValidation();
            return;
        } else {
            if (this.stepNames.includes("additionalInfo")) {
                if (!this.$refs.additionalInfoForm.validate()) {
                    this.stepName = "additionalInfo";
                    this.completePrevious();
                    this.onStepNameChanged();
                    return;
                } else {
                    this.stepName = await this.getNextStep("additionalInfo");
                    this.completePrevious();
                }
            }
            if (this.stepNames.includes("pickupDelivery")) {
                if (
                    this.vm.order?.pickupBranchId == null &&
                    this.vm.order?.organizationBranchId == null &&
                    !this.$refs.shippingForm?.validate()
                ) {
                    this.stepName = "pickupDelivery";
                    this.completePrevious();
                    this.onStepNameChanged();
                    return;
                } else if (this.vm.order?.shippingType == null) {
                    this.stepName = "pickupDelivery";
                    this.completePrevious();
                    this.onStepNameChanged();
                    return;
                } else if (this.vm.order?.shippingType == "Shipping" && !this.vm.order?.shippingAddress?.zipCode) {
                    // This happens when the user goes to step 3 and then pastes the link into another tab
                    // This should force them to make a selection and continue past step 3
                    // Previously this code had taken them to step 4 directly which caused the user not to be charged shipping
                    this.completePrevious();
                    this.pickupDeliveryWarning = false;
                    if (this.vm.availablePickups && this.vm.availablePickups.length > 0) {
                        this.vm.selectedLocation = this.vm.availablePickups[0];
                    }
                    return;
                } else {
                    this.stepName = await this.getNextStep("pickupDelivery");
                    this.completePrevious();
                }
            }
            if (this.stepNames.includes("dealerNotes")) {
                if (this.getStepNumber(currentStep) > this.getStepNumber("dealerNotes")) {
                    if (this.vm.requireTermsAgreement && !this.vm.agreedToTerms) {
                        this.stepName = "dealerNotes";
                        this.completePrevious();
                        this.onStepNameChanged();
                        return;
                    } else {
                        this.stepName = await this.getNextStep("dealerNotes");
                        this.completePrevious();
                    }
                } else {
                    this.stepName = await this.getNextStep("dealerNotes");
                    this.completePrevious();
                }
            }
            if (!this.reviewComplete && this.getStepNumber(currentStep) > this.getStepNumber("checkoutReview")) {
                this.stepName = "checkoutReview";
                this.completePrevious();
                this.onStepNameChanged();
                return;
            }
        }
        this.onStepNameChanged();
    }

    public completePrevious() {
        let limit = this.stepNames.indexOf(this.stepName);
        for (let i = 0; i < limit; i++) {
            this.stepCompleted[i] = true;
        }
    }

    public skipCompletedSteps(nextStep: string) {
        try {
            this.stepCompleted.forEach((completed, index) => {
                if (!completed && index != 0) {
                    throw this.stepNames[index];
                }
            });
        } catch (err) {
            return err as string;
        }

        return nextStep;
    }

    public async advanceStepper(selectedStep?: string) {
        this.advancing = true;
        var currentStep = this.stepNames[this.$data.e6 - 1];
        var nextStep = this.stepNames[this.$data.e6];

        if (selectedStep) {
            if (this.getStepNumber(selectedStep) < this.getStepNumber(currentStep)) {
                this.stepName = selectedStep;
                this.onStepNameChanged();
                this.advancing = false;
                return;
            }
        }

        //Clean up steps
        switch (currentStep) {
            case "billingInfo":
                if (!this.$refs.billingForm.validate()) {
                    this.billingInfoWarning = true;
                    this.advancing = false;
                    return;
                } else {
                    await this.savebillingInfo();
                    this.billingInfoWarning = false;
                    this.vm.forceShippingAddress;
                }
                break;
            case "additionalInfo":
                if (!this.$refs.additionalInfoForm.validate()) {
                    this.additionalInfoWarning = true;
                    this.advancing = false;
                    return;
                } else {
                    await this.vm.saveAdditionalInfo();
                    this.additionalInfoWarning = false;
                }
                break;
            case "pickupDelivery":
                var saveSucceeded = false;
                if (this.vm.selectedLocation == null) {
                    this.pickupDeliveryWarning = true;
                    this.advancing = false;
                    return;
                } else if (this.$refs.shippingForm) {
                    if (this.sameAddress && this.vm.order && !this.vm.forceShippingAddress) {
                        this.vm.order.shippingAddress = this.vm.order.billingAddress;
                    }
                    if (!this.$refs.shippingForm.validate()) {
                        this.pickupDeliveryWarning = true;
                        this.advancing = false;
                        return;
                    } else {
                        const saveOrderResults = await this.saveOrder(true);
                        this.pickupDeliveryWarning = false;
                        this.locationPicked = true;
                        if (!saveOrderResults.saveSucceeded) {
                            this.pickupDeliveryWarning = true;
                            this.advancing = false;
                            return;
                        }

                        if (saveOrderResults.fixAddress) {
                            if (this.sameAddress) {
                                this.stepName = "billingInfo";
                                this.onStepNameChanged();
                            }

                            this.advancing = false;
                            return;
                        }
                    }
                } else {
                    this.pickupDeliveryWarning = false;
                    const saveOrderResults = await this.saveOrder(false);
                    if (!saveOrderResults.saveSucceeded) {
                        this.pickupDeliveryWarning = true;
                        this.advancing = false;
                        return;
                    }
                }

                try {
                    this.isLoading = true;
                    await this.vm.get(this.eventId!);
                } finally {
                    this.isLoading = false;
                }

                if (
                    (this.vm.billingAddress?.addressLine1 === "" || this.vm.billingAddress?.addressLine1 === null) &&
                    this.vm.totals!.orderTotal > 0
                ) {
                    this.stepName = "billingInfo";
                    await this.onStepNameChanged();
                    this.$refs.billingForm.resetValidation();
                    this.vm.hideBilling = false;
                    this.advancing = false;
                    return;
                }

                break;
            case "dealerNotes":
                if (this.vm.requireTermsAgreement && !this.vm.agreedToTerms) {
                    this.noteWarning = true;
                    this.advancing = false;
                    return;
                } else {
                    this.noteWarning = false;
                }
                break;
            default:
                break;
        }

        if (nextStep == "pickupDelivery") {
            if (this.vm.forceShippingAddress) {
                this.sameAddress = false;
            }
        }

        this.$data.e6++;

        let currentUrl = window.location.href;
        let historyUrl = new URL(currentUrl);
        let replaceUrl = new URL(currentUrl);
        replaceUrl.searchParams.set("stepName", nextStep);

        historyUrl.searchParams.set("stepName", this.stepNames[this.$data.e6 - 1]);
        try {
            history.pushState(null, "", historyUrl.href);
        } catch (e) {
            console.log("error: ", e);
        }

        history.replaceState(null, "", replaceUrl.toString());

        this.stepCompleted[this.stepNames.indexOf(currentStep)] = true;

        nextStep = this.skipCompletedSteps(nextStep);

        this.stepName = nextStep;
        this.advancing = false;
    }

    public async savebillingInfo() {
        if (this.vm.order) {
            try {
                this.isLoading = true;
                await this.vm.saveCustomerInfo();
            } finally {
                this.isLoading = false;
            }
        }
    }

    private async clearPickupLocation() {
        if (this.vm.order) {
            if (this.vm.availablePickups.length != 1) {
                this.vm.selectedLocation = null;
                this.vm.order.pickupBranchId = null;
                //this.shipDirect = false;
                this.vm.order.shippingType = null;
                this.vm.shippingType = null;
                this.vm.order.organizationBranchId = 0;
                this.vm.pickupLocation = null;
            }
        }
    }

    public async updatePickupLocation(flag?: boolean) {
        if (!this.vm.order) {
            return;
        }

        this.vm.order.shippingType = ShippingTypeHelper.toString(this.vm.selectedLocation!.shippingType);
        if (this.vm.selectedLocation!.shippingType === ShippingType.Shipping) {
            this.shipDirect = true;
            this.vm.pickupLocation = null;
            this.vm.order.pickupBranchId = null;
            this.vm.order.organizationBranchId = 0;
            if (this.sameAddress) {
                this.vm.order.shippingAddress = this.vm.order.billingAddress;
            }

            //unselect any branch related stuff to prevent confusion
            this.vm.order.pickupBranchId = null;
        } else if (this.vm.selectedLocation!.shippingType === ShippingType.InStorePickup) {
            this.shipDirect = false;
            this.vm.order.pickupBranchId = this.vm.selectedLocation!.id;
            this.vm.order.organizationBranchId = 0;
            this.vm.pickupLocation = this.vm.selectedLocation!.description;
        } else if (this.vm.selectedLocation!.shippingType === ShippingType.GroupPickup) {
            this.shipDirect = false;
            this.vm.order.pickupBranchId = null;
            this.vm.pickupLocation = this.vm.selectedLocation!.description;
            this.vm.order.organizationBranchId = this.vm.selectedLocation!.id;
        }

        await this.saveOrder(false);
        try {
            this.isLoading = true;
            await this.vm.get(this.eventId!);
        } finally {
            this.isLoading = false;
            if (this.vm.forceShippingAddress) {
                this.vm.order.shippingAddress = new Address();
                this.vm.order.shippingAddress.country = "US";
                if (this.$refs.shippingForm) {
                    this.$refs.shippingForm.resetValidation();
                }
            }
        }
    }

    public async saveOrder(validateAddress: boolean): Promise<{ saveSucceeded: boolean; fixAddress: boolean }> {
        if (this.vm.order) {
            try {
                this.isLoading = true;
                const result = await this.vm.saveOrder(this.sameAddress, validateAddress);
                return result;
            } finally {
                this.isLoading = false;
            }
        }
        return { saveSucceeded: false, fixAddress: false };
    }

    protected validateAddressField(value: string) {
        if (value) {
            return true;
        }
        return "You must enter a value";
    }

    @Watch("stepName")
    public onStepNameChanged() {
        this.$data.e6 = this.stepNames.indexOf(this.stepName) + 1;

        let currentUrl = window.location.href;
        let replaceUrl = new URL(currentUrl);
        replaceUrl.searchParams.set("stepName", this.stepName);
        history.replaceState(null, "", replaceUrl.toString());
    }

    @Watch("sameAddress")
    public async sameAddressChanged(value: boolean) {
        if (!value && !this.vm.allowInternationalShipping && this.vm.billingAddress?.country != "US") {
            if (this.vm.order) {
                this.vm.order.shippingAddress = new Address();
            }
        }
    }

    @Watch("eventId")
    public async onEventIdChanged() {
        if (this.eventId) {
            if (this.preventRepeat == 0) {
                this.preventRepeat = 1;
                try {
                    this.isLoading = true;
                    await this.vm.getBrandingInfo(this.eventId);
                    await this.vm.get(this.eventId);
                } finally {
                    this.isLoading = false;
                }
                //await this.vm.loadOrder(this.eventId);
                if (this.vm.orderInstructions == "" && this.vm.requireTermsAgreement === false) {
                    this.stepNames.splice(this.stepNames.indexOf("dealerNotes"), 1);
                }

                if (this.vm.isRequestor) {
                    this.stepNames.splice(this.stepNames.indexOf("pickupDelivery"), 1);
                }

                // if (this.vm.availablePickups) {
                //   if (this.vm.availablePickups.length > 0) {
                //     this.vm.selectedLocation = this.vm.availablePickups[0];
                //   }
                // }
                //Remove the additional info step if there is no roster or require recipient
                if (!this.showAdditionalInfo) {
                    this.stepNames.splice(this.stepNames.indexOf("additionalInfo"), 1);
                }

                //Build the step-completed array
                this.stepNames.forEach((step) => {
                    this.stepCompleted.push(false);
                });
            }
        }
    }
}
