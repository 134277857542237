import { WebHelper } from "chipply-common";
import IStoresDto from "../IStoresDto";
import { StoresDto } from "../StoresDto";
import IDealerBrandingDto from "../IDealerBranding";
import { DealerBrandingDto } from "../DealerBrandingDto";
import IDealerCategoriesModel from "@/chipply/IDealerCategoriesModel";
import ListDealerCategoriesResults from "@/chipply/ListDealerCategoriesResults";
import IStoreDto from "@/chipply/IStoreDto";
import IGetDealerCategoryResults from "@/chipply/IGetDealerCategoryResults";
import { forEach } from "lodash";

export default class DealerViewModel {
    public stores: IStoresDto = new StoresDto();
    public branding: IDealerBrandingDto = new DealerBrandingDto();
    public catObject: ListDealerCategoriesResults = new ListDealerCategoriesResults();
    public homeCategory!: IDealerCategoriesModel;
    public dealerId!: number;
    public historyArray: IDealerCategoriesModel[] = [];
    public youtubeEmbed = "";

    public async get(dealerId: number): Promise<void> {
        let baseUrl = "/api/Dealer/Stores?&dealerId=";
        baseUrl += encodeURIComponent(dealerId);
        const results = (await WebHelper.getJsonData(baseUrl)) as IStoresDto;
        if (results && results.dealerStores) {
            this.stores = results;
            this.dealerId = dealerId;
        }
    }

    public setHomeCategory(cat: IDealerCategoriesModel) {
        this.homeCategory = cat;
    }

    public async getDealerInfo(dealerId: number): Promise<void> {
        let baseUrl = "/api/Dealer/Branding?&dealerId=";
        baseUrl += encodeURIComponent(dealerId);
        const results = (await WebHelper.getJsonData(baseUrl)) as IDealerBrandingDto;
        if (results && results.dealerBranding) {
            this.branding = results;
        }
    }

    public async getCategoryAndSubs(dealerId: number, categoryId: number | null) {
        const args = { dealerId: dealerId, categoryId: categoryId };
        if (categoryId == null) {
            categoryId = 0;
        }

        const results: ListDealerCategoriesResults = (await WebHelper.getJsonData(
            `/api/Dealer/Categories/GetCategory/${dealerId}/${categoryId}`
        )) as ListDealerCategoriesResults;
        this.catObject.showDealerCategories = results.showDealerCategories;

        return results;
    }

    public sortedCategories(selectedCat: IDealerCategoriesModel) {
        const sortedArray: IDealerCategoriesModel[] = [];
        if (selectedCat == null) {
            return this.catObject.categories;
        } else {
            const parentId = selectedCat.categoryId;
            selectedCat.subCategories.forEach((sub) => {
                if (sub.parentCategoryId == parentId) {
                    sortedArray.push(sub);
                }
            });
            return sortedArray;
        }
    }

    public sortedStores(selectedCatId: number) {
        const sortedArray: IStoreDto[] = [];

        if (selectedCatId == 0 || selectedCatId == null) {
            this.stores.dealerStores.forEach((store: IStoreDto) => {
                if (store.dealerCategoryIds.length == 0) {
                    sortedArray.push(store);
                }
            });
        } else {
            this.stores.dealerStores.forEach((store: IStoreDto) => {
                if (selectedCatId != null && store.dealerCategoryIds.length > 0) {
                    if (store.dealerCategoryIds.includes(selectedCatId)) {
                        sortedArray.push(store);
                    }
                }
            });
        }
        return sortedArray;
    }

    public initializeYouTubeEmbed(url: string) {
        if (url) {
            const videoId = this.getYouTubeId(url);
            return `https://www.youtube-nocookie.com/embed/${videoId}?&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1&mute=1&loop=1&playlist=${videoId}`;
        }
        return "";
    }

    protected getYouTubeId(url: string): string {
        let videoId = "";
        if (url.indexOf("v=") > 0) {
            // Handle urls in format https://www.youtube.com/watch?v=6qg8yxL0ASs
            videoId = url.split("v=")[1];
            const ampersandPosition = videoId.indexOf("&");
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
        } else if (url.indexOf("/") > 0) {
            // Handle urls in format https://youtu.be/6qg8yxL0ASs
            const splitUrl = url.split("/");
            if (splitUrl.length > 0) {
                return splitUrl[splitUrl.length - 1];
            }
        }
        return videoId;
    }
}
