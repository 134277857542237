export class UrlUtils {
    public static getVersionedLink(link: string) {
        let versionString = null;
        if (link.indexOf("?") === -1) {
            versionString = `?v=${Math.floor(Math.random() * 100000)}`;
        } else {
            versionString = `&v=${Math.floor(Math.random() * 100000)}`;
        }

        return link + versionString;
    }
}
