export default class RadioButtonModel {
    public constructor(button: any) {
        this.display = button.display;
        this.selected = button.selected;
        this.enabled = button.enabled;
        this.value = button.value;
    }
    display = "";
    selected = false;
    value = 0;
    enabled = false;
}
