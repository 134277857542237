import { ViewModel } from "../ViewModel";
import { FieldModel } from "./FieldModel";

export abstract class FieldViewModel extends ViewModel {
    public abstract modelTypeHint: string;
    public name = "";
    public required = false;
    public enabled = true;
    public sortOrder = 0;
    public inUse = false;
    public id = 0;
    public abstract typeName: string;

    protected constructor(model?: FieldModel) {
        super();
        if (model) {
            this.name = model.name;
            this.required = model.required;
            this.sortOrder = model.sortOrder;
            this.enabled = model.enabled;
            this.inUse = model.inUse;
            this.id = model.id;
        }
    }

    public toModel(): FieldModel {
        return {
            $typeHint: this.modelTypeHint,
            id: this.id,
            name: this.name,
            required: this.required,
            sortOrder: this.sortOrder,
            enabled: this.enabled,
            inUse: this.inUse,
        };
    }
}
