











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

@Component({
    props: {
        quantity: Number,
    },
    components: {},
})
export default class DesignFlag extends Vue {}
