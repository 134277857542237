






































































































































































































































































































































import Vue from "vue";
import EcomLayout from "../EcomLayout.vue";
import Component from "vue-class-component";
import ProductColorModel from "@/chipply/ProductColorModel";
import { Serializer, WebHelper } from "chipply-common";
import IProductDto from "@/chipply/i-product-dto";
import ProductColor from "../ProductColor.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import AddToCartBulkArgs from "@/chipply/AddToCartBulkArgs";
import AddToCartArgs from "@/chipply/AddToCartArgs";
import ProductOption from "@/chipply/ProductOption";
import IProcessOptionItem from "@/chipply/IProcessOptionItem";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import ProductDialog from "../ProductDialog.vue";
import Validation from "@/chipply/Validation";
import ParentProductViewModel from "@/chipply/view-model/ParentProductViewModel";

@Component({
    components: {
        EcomLayout,
        ProductColor,
        ProductDialog,
    },
    props: {
        productColors: Array,
        value: Object,
        cartVm: CartViewModel,
        pvm: ParentProductViewModel,
        crumbs: Array,
        isPreview: Boolean,
    },
})
export default class BulkProductPage extends Vue {
    public name = "BulkProductPage";
    private productColors!: Array<ProductColorModel>;
    public chipplyIcons = chipplyIcons;
    private showAdd = true;
    private value!: ProductViewModel;
    private cartVm!: CartViewModel;
    private pvm!: ParentProductViewModel;

    private crumbs!: Array<any>;
    private opened = [0];
    private showSnackbar = false;
    public prunedBulkSizes: any[] = [];
    private optionFormValid = false;
    private isPreview!: boolean;
    public Validation = Validation;

    // @ts-ignore
    public $refs!: {
        snack: ProductDialog;
        optionForm: any;
    };

    private async panelOpened() {
        if (this.showAdd) {
            this.showAdd = false;
        } else {
            this.showAdd = true;
        }
    }

    private created() {
        this.value.bulkSizes.forEach((size) => {
            if (size.quantity != 0) {
                size.full = false;
                this.prunedBulkSizes.push(size);
            }
        });
    }

    private async addItem(size: any) {
        var currentCount: number = size.quantity;
        var currentMax = this.calculateCurrentMax(size.value);
        if (++currentCount <= currentMax) {
            let copy = Serializer.serialize(this.value.options);
            size.options.unshift(Serializer.deserialize(copy));
            size.quantity = parseInt(size.quantity) + 1;
            if (currentCount == currentMax) {
                size.full = true;
            }
        } else {
            size.full = true;
        }

        //these three lines are to trick its reactivity
        var temp = this.prunedBulkSizes;
        this.prunedBulkSizes = [];
        this.prunedBulkSizes = temp;
    }

    private calculateCurrentMax(size: number) {
        let designIds: any[] = [];
        let limit = this.value.product?.maxQuantity;

        if (this.value.selectedColor && size) {
            if (this.value.selectedColor.eventProductColorID != "" && size != 0) {
                var colorId = this.value.selectedColor.eventProductColorId;
                var sizeId = size;
                var targetEntry = this.value.colorSize.colorSizeDictionary![colorId][sizeId];
                if (targetEntry.parentProductColorId != 0 || targetEntry.parentProductSizeId != 0) {
                    var parentSize =
                        this.pvm.parent.colorSize.colorSizeDictionary![targetEntry.parentProductColorId][
                            targetEntry.parentProductSizeId
                        ];
                    if (parentSize != null) {
                        if (parentSize.availableQuantity != null) {
                            return parentSize.availableQuantity;
                        }
                    }
                }

                if (this.value.colorSize.colorSizeDictionary![colorId][sizeId].availableQuantity != null) {
                    return this.value.colorSize.colorSizeDictionary![colorId][sizeId].availableQuantity;
                }
            }
        }

        if (this.value.product!.designCount > 1) {
            this.value.colorSize.designsObject.designs.forEach((design: any) => {
                designIds.push(design.value);
            });
        }
        return this.cartVm.calculateCurrentMaxMultiDesign(designIds, this.value.product!.maxQuantity);
    }

    private async deleteItem(size: any, index: number) {
        if (parseInt(size.quantity) != 1) {
            size.quantity = parseInt(size.quantity) - 1;
            size.options.splice(index - 1, 1);
            size.full = false;
        }
    }

    protected dropdownChange(option: IProcessOptionModel) {
        if (option.processType == 5 && option.userValue) {
            let occurance = 0;
            this.value.bulkSizes.forEach((bulkSize) => {
                if (bulkSize.options.length > 0) {
                    bulkSize.options.forEach((item: any) => {
                        item.forEach((attribute: IProcessOptionModel) => {
                            if (attribute.processType == 5) {
                                if (
                                    attribute.userValue &&
                                    attribute.userValue == option.userValue &&
                                    attribute.optionId == option.optionId
                                ) {
                                    occurance++;
                                }
                            }
                        });
                    });
                }
            });

            if (occurance > 1) {
                return "Duplicate exclusive option detected";
            } else {
                return true;
            }
        }

        return true;
    }

    protected validateItem(option: IProcessOptionModel) {
        if (option.userValue && option.isRequired) {
            if (option.userValue != "") {
                return true;
            } else {
                return "Required option not filled";
            }
        } else if (option.isRequired && option.userValue == undefined) {
            return "Required option not filled";
        } else {
            return true;
        }
    }

    protected validateMaxLength(option: IProcessOptionModel) {
        if (option.maxCharacters === 0) {
            return true;
        } else {
            return Validation.requireMaximumLengthOf(option.maxCharacters)(option.userValue);
        }
    }

    private async dropdownChange_bak(option: IProcessOptionModel, size: any) {
        if (option.processType == 5) {
            option.options.forEach((choice) => {
                if (choice.display == option.userValue) {
                    this.value.bulkSizes.forEach((bulkSize) => {
                        if (bulkSize.options.length > 0) {
                            bulkSize.options.forEach((attribute: IProcessOptionModel[]) => {
                                attribute.forEach((field) => {
                                    if (option.optionId == field.optionId) {
                                        field.options.forEach((selection) => {
                                            if (selection.optionItemId == choice.optionItemId) {
                                                let index = field.options.indexOf(selection);
                                            }
                                        });
                                    }
                                });
                            });
                        }
                    });
                }
            });
        }
    }

    public async addToCart() {
        var bulkModel = new AddToCartBulkArgs();

        this.value.bulkSizes.forEach((size) => {
            if (size.quantity > 0) {
                for (let i = 0; i < size.quantity; i++) {
                    let arg = new AddToCartArgs();
                    arg.productId = this.value.product!.eventProductID;
                    arg.sizeId = size.value;
                    arg.colorId = this.value.selectedColor.eventProductColorId;
                    arg.categoryId = this.value.categoryId;
                    arg.quantity = 1;
                    arg.eventId = this.value.product!.eventID;

                    let item = size.options[i];
                    item.forEach((attribute: any) => {
                        if (attribute.userValue) {
                            const po = new ProductOption();
                            if (attribute.processType == 4 || attribute.processType == 5) {
                                attribute.options.forEach((choice: IProcessOptionItem) => {
                                    if (choice.display == attribute.userValue) {
                                        po.processOptionId = choice.optionItemId;
                                    }
                                });
                            }
                            po.text = attribute.userValue;
                            po.processItemId = attribute.optionId;
                            arg.productOptions.push(po);
                        }
                    });

                    bulkModel.items.push(arg);
                }
            }
        });

        if (!this.$refs.optionForm.validate()) {
        } else {
            await WebHelper.postJsonData("/api/EventProductEcom/AddToCartBulk", bulkModel);
            await this.$refs.snack.showSnackbar(bulkModel.items.length);
        }
    }
}
