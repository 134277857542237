
















































































































































































































































































































































































































import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import "@mdi/font/css/materialdesignicons.css";
import Component from "vue-class-component";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import { DealerLogoDisplay, WebHelper } from "chipply-common";
import IEventCategoryDto from "@/chipply/i-event-category-dto";
import IPackageDto from "@/chipply/i-package-dto";
import PreviewFlag from "./PreviewFlag.vue";

@Component({
    components: { PreviewFlag },
    props: {
        eventId: Number,
        checkout: Boolean,
        value: Object,
        isCartPage: Boolean,
        cartVm: Object,
        landing: Boolean,
        isPreview: Boolean,
    },
})
export default class EcomLayout extends Vue {
    @Prop({
        type: Boolean,
    })
    public skipLoad!: boolean;

    private eventId!: number | null;
    private checkout!: boolean | null;

    private cartVm!: CartViewModel;
    private value!: CartViewModel;
    private cartQuantity = 0;
    private isCartPage = false;
    private showCatNav = false;
    public DealerLogoDisplay = DealerLogoDisplay;
    private selectedCategory: IEventCategoryDto | null = null;
    public drawer = false;
    public isNavigationButtonVisible = false;
    public isPreview = false;
    @Prop({
        default: false,
        type: Boolean,
    })
    private landing!: boolean;

    // @ts-ignore
    public $refs!: {
        navRef: HTMLElement;
    };

    private async created() {
        this.viewModelInitialized();

        if (this.eventId != null && !this.skipLoad) {
            await this.value.get(this.eventId);
            await this.value.getCategories(this.eventId, true);
        }
    }

    @Watch("value.initialized")
    public viewModelInitialized() {
        if (this.value.itemCount != "0") {
            this.cartQuantity = parseInt(this.value.itemCount);
        }
    }

    private toggleCatNav() {
        if (this.showCatNav == true) {
            this.showCatNav = false;
        } else {
            this.showCatNav = true;
        }
    }

    protected breakCatName(name: string) {
        var copy = name;
        var indices = [0];
        copy = copy.replace(/\s+/g, "i");
        while (/\W/.test(copy)) {
            indices.push(copy.search(/\W/));
            copy = copy.replace(/\W/, "i");
        }
        var modifier = 0;
        indices.forEach((character, index) => {
            if (index > 1) {
                modifier += 5;
                character = character + modifier;
            }
            var startingIndex = indices[index - 1] + modifier;
            var sub = name.substring(startingIndex, character + 1);
            if (index != 0) {
                name = name.replace(sub, sub + "<wbr>");
            }
        });

        return name;
    }

    private checkPasswords(selected: IEventCategoryDto | null) {
        var unlockedContainers: any[] = [];
        if (selected && selected.containers) {
            selected.containers.forEach((subCat) => {
                if (!selected.hasPassword || selected.isAuthorized) {
                    unlockedContainers.push(subCat);
                }
            });
        }

        return unlockedContainers;
    }

    private get subCategories() {
        var unlocked = this.checkPasswords(this.selectedCategory);
        return unlocked;
    }

    private get logoLessNav() {
        if (this.value.branding.dealerLogoDisplay === DealerLogoDisplay.UseLogo) {
            return "185px";
        } else if (this.value.branding.dealerLogoDisplay === DealerLogoDisplay.NoLogoUseText) {
            return "175px";
        } else {
            return "100px";
        }
    }

    private blurNav() {
        this.toggleCatNav();
    }

    public viewAll() {
        location.assign("./store.aspx?eid=" + this.eventId + "&action=viewall");
    }

    private async selectCat(category: IEventCategoryDto) {
        this.selectedCategory = category;
        if (
            (category.containers && category.containers.length == 0) ||
            category.isPackage ||
            this.checkPasswords(category).length == 0 ||
            (category.hasPassword && !category.isAuthorized)
        ) {
            location.assign("./store.aspx?eid=" + this.eventId + "&acid=" + category.eventCategoryID);
        }
    }

    private get cartTarget() {
        if (this.$vuetify.breakpoint.smAndDown && this.checkout) {
            return "";
        } else {
            return "./cart.aspx?eventId=" + this.eventId;
        }
    }
}
