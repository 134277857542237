























































































































































































































































































































































































































































































import EventCategoriesDto from "@/chipply/event-categories-dto";
import Category from "@/chipply/event-category-dto";
import IEventCategoriesDto from "@/chipply/i-event-categories-dto";
import IEventCategoryDto from "@/chipply/i-event-category-dto";
import IEventProperties from "@/chipply/i-event-properties";
import IPackageDto from "@/chipply/i-package-dto";
import CategoryPasswordAuthenticator from "@/components/CategoryPasswordAuthenticator.vue";
import ProductLoader from "@/components/ProductLoader.vue";
import StorePackage from "@/components/StorePackage.vue";
import { WebHelper, Utils } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import NewStore from "./NewStore.vue";

@Component({
    components: {
        ProductLoader,
        CategoryPasswordAuthenticator,
        StorePackage,
    },
})
export default class ProductShell extends Vue {
    public Utils = Utils;
    public name = "ProductShell";
    public loaded!: boolean;
    public categoryBackgroundColor: string | undefined;
    public categoryForegroundColor: string | undefined;
    public eventProperties!: IEventProperties;
    public eventCategories!: IEventCategoriesDto;
    public subcategoryToggles!: Array<{ toggle: number | null }>;
    public panelTogglesArray!: number[];
    public panelToggles!: number | null;
    public subcategoryTogglesArray!: Array<number[]>;
    public currentCategoryFocusId: number | null = null;
    @Prop({
        default: "white",
        type: String,
    })
    public backgroundColor!: string;

    @Prop({
        default: "",
        type: String,
    })
    public eventID!: string;

    public newStore: NewStore | undefined;

    // @ts-ignore
    public $refs: {
        zoomImg: HTMLImageElement;
        paneContainer: HTMLElement;
        boundingBoxContainer: HTMLElement;
        passcodeInput: HTMLElement;
    };

    public data() {
        return {
            categoryBackgroundColor: undefined,
            categoryForegroundColor: undefined,
            eventProperties: null,
            eventCategories: {
                containers: [],
            },
            subcategoryToggles: null,
            panelToggles: null,
            panelTogglesArray: null,
            subcategoryTogglesArray: [],
            loaded: false,
            productShell: this,
        };
    }

    @Watch("eventProperties")
    public async onEventPropertiesChanged() {
        if (!this.eventProperties) {
            return;
        }

        await this.loadProductCategories(this.eventProperties);
    }

    public async loadProductCategories(eventProperties: IEventProperties) {
        const eventCategories = (await WebHelper.getJsonData(
            `/api/EventProductEcom/GetEventCategories/` +
                `${eventProperties.eventID}/${eventProperties.dealerID}/${eventProperties.selectedCategoryID}`,
            true
        )) as IEventCategoriesDto;

        if (!eventCategories) {
            return;
        }

        this.categoryBackgroundColor = eventCategories.hexCode1;
        this.categoryForegroundColor = eventCategories.foregroundColor ? eventCategories.foregroundColor : "white";
        for (const category of eventCategories.containers) {
            category.id = category.eventCategoryID;
            if (!category.isPackage) {
                const subcatContainer = category as IEventCategoryDto;
                if (subcatContainer.containers) {
                    for (const subcategory of subcatContainer.containers) {
                        subcategory.id = subcategory.eventCategoryID;
                    }
                }
            }
        }

        if (eventCategories.isSingleCategoryExpansion) {
            const subcategoryToggles = [];
            for (const containers of eventCategories.containers) {
                const category = containers as IEventCategoryDto;
                // if (category.defaultAsOpen) this.panelToggles = i;
                let openSubcategoryIndex: number | null = (category.containers || []).findIndex(
                    (item) => (item as IEventCategoryDto).defaultAsOpen
                );
                if (openSubcategoryIndex === -1) {
                    openSubcategoryIndex = null;
                }

                subcategoryToggles.push({ toggle: openSubcategoryIndex });
            }
            this.subcategoryToggles = subcategoryToggles;
        } else {
            const subcategoryTogglesArray = [];
            const panelTogglesArray = [];

            for (let ci = 0; ci < eventCategories.containers.length; ci++) {
                const container = eventCategories.containers[ci];
                const category = container as IEventCategoryDto;
                if (category.defaultAsOpen) {
                    panelTogglesArray.push(ci);
                }
                if (!category.containers || category.containers.length === 0) {
                    subcategoryTogglesArray.push([]);
                } else {
                    if (category.containers) {
                        const subcategoryToggles = [];
                        for (let sci = 0; sci < category.containers.length; sci++) {
                            const subcontainer = category.containers[sci];
                            const subcategory = subcontainer as IEventCategoryDto;
                            if (subcategory.defaultAsOpen) {
                                subcategoryToggles.push(sci);
                            }
                        }
                        subcategoryTogglesArray.push(subcategoryToggles);
                    }
                }
            }
            this.subcategoryTogglesArray = subcategoryTogglesArray;
            this.panelTogglesArray = panelTogglesArray;
        }

        this.eventCategories = eventCategories;
        this.loaded = true;
        this.updateStoreCategories(eventCategories);
    }

    public async validated(validated: boolean, categoryId: number) {
        if (validated) {
            for (const con of this.eventCategories.containers) {
                const cat = con as IEventCategoryDto;
                if (cat.eventCategoryID === categoryId) {
                    cat.isAuthorized = true;
                    this.updateStoreCategories(this.eventCategories);
                    return;
                } else {
                    if (cat.containers) {
                        for (const subCon of cat.containers) {
                            const subCat = subCon as IEventCategoryDto;
                            if (subCat.eventCategoryID === categoryId) {
                                subCat.isAuthorized = true;
                                this.updateStoreCategories(this.eventCategories);
                                return;
                            }
                        }
                    }
                }
            }
        }
    }

    public async eventValidated(validated: boolean) {
        if (!validated) {
            return;
        }

        await this.loadProductCategories(this.eventProperties);
    }

    public selectCategory(categoryId: number) {
        this.eventProperties.selectedCategoryID = categoryId;
        const elements = document.getElementsByClassName("header");
        this.currentCategoryFocusId = categoryId;
        Vue.nextTick(() => {
            try {
                for (const item of elements) {
                    if (categoryId.toString() === item.getAttribute("data-id")) {
                        const htmlElement = item as HTMLElement;
                        item.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest",
                        });
                        return;
                    }
                }
            } finally {
                setTimeout(() => {
                    this.currentCategoryFocusId = null;
                }, 1000); // The 1000 is a hack to allow the animation to finish.
            }
        });
    }

    public async scrollEvent(id: number) {
        //console.log("scroll event firing for: ", id);
    }

    protected updateStoreCategories(eventCategories: IEventCategoriesDto) {
        if (!this.newStore) {
            return;
        }
        this.newStore.vm.categories = new EventCategoriesDto(eventCategories);
        const categories: IEventCategoryDto[] = [];
        for (const category of eventCategories.containers) {
            const categoryToAdd = new Category(category as IEventCategoryDto);
            const subCons: Array<IPackageDto | IEventCategoryDto> = [];
            if (categoryToAdd.containers) {
                if (!categoryToAdd.hasPassword || categoryToAdd.isAuthorized) {
                    for (const subcategory of categoryToAdd.containers) {
                        subCons.push(subcategory);
                    }
                }
            }
            categoryToAdd.containers = subCons;
            categories.push(categoryToAdd);

            if (categoryToAdd.defaultAsOpen && categoryToAdd.containers.length > 0) {
                if (this.newStore.vm.openCategories) {
                    // Keep an eye out for a future bug here
                    // The vuetify docs show that we should put the itemKey (id in our case) into the array
                    // that backs :open for the VTreeView, but it did not work for me without pushing
                    // the whole object.
                    this.newStore.vm.openCategories.push(categoryToAdd);
                }
            }
        }
        this.newStore.vm.categories.containers = categories;
        if (
            this.newStore.vm.categories.containers.length > 0 &&
            !this.newStore.vm.branding.isCategoriesSelectorHidden
        ) {
            this.newStore.$refs.ecomLayout.isNavigationButtonVisible = true;
        }
    }
}
