

















import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import IDealerCategoriesModel from "@/chipply/IDealerCategoriesModel";

@Component({})
export default class CategorySelect extends Vue {
    @Prop({
        type: Object,
    })
    category!: IDealerCategoriesModel;
}
