


















































































































































































import { WebHelper } from "chipply-common";
import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import IProductsDto from "@/chipply/i-products-dto";
import IProductDto from "@/chipply/i-product-dto";
import IEventProperties from "@/chipply/i-event-properties";
import IEventCategoryDto from "@/chipply/i-event-category-dto";
import CategoryPasswordAuthenticator from "@/components/CategoryPasswordAuthenticator.vue";
import ProductShell from "./ProductShell.vue";
@Component({
    components: { CategoryPasswordAuthenticator },
    props: {
        initialProducts: Object,
        load: Boolean,
        eventProperties: Object,
        categoryDto: Object,
        isSubcategory: Boolean,
    },
})
export default class ProductLoader extends Vue {
    public name = "ProductLoader";
    public load!: boolean;
    public eventProperties!: IEventProperties;
    public categoryDto!: IEventCategoryDto;
    public initialProducts!: IProductsDto;
    public hasLoaded = false;
    public products!: IProductDto[];
    public hasPassword!: boolean;
    public isValidated!: boolean;
    public allLoaded!: boolean;
    public categoryID!: number;
    public currentPage!: number;
    public productShell!: ProductShell;

    // @ts-ignore
    public $refs: {
        products: HTMLElement[];
        outerMost: HTMLElement;
    };

    public data() {
        return {
            allLoaded: false,
            currentPage: 0,
            products: this.initialProducts ? this.initialProducts.products : null,
            hasPassword: false,
            isValidated: false,
            categoryID: null,
        };
    }

    public async validated(ev: boolean) {
        this.isValidated = ev;
        if (ev) {
            this.load = true;
            await this.tryLoadingProducts();
        }
    }

    @Watch("load")
    public async onLoadChanged() {
        await this.tryLoadingProducts();
    }

    public navigateToProduct(product: IProductDto, categoryDto: IEventCategoryDto) {
        // window.location.href =
        //     "product-detail.aspx?pid=" +
        //     product.eventProductID +
        //     (categoryDto ? "&cid=" + categoryDto.eventCategoryID : "");
        window.location.href =
            "product-detail-shell.aspx?pid=" +
            product.eventProductID +
            (categoryDto ? "&cid=" + categoryDto.eventCategoryID : "");
        let currentUrl = window.location.href;
        let historyUrl = new URL(currentUrl);

        if (!historyUrl.searchParams.has("apid")) {
            historyUrl.searchParams.set("apid", product.eventProductID.toString());
        } else {
            historyUrl.searchParams.delete("apid");
            historyUrl.searchParams.set("apid", product.eventProductID.toString());
        }

        if (!historyUrl.searchParams.has("acid") && categoryDto) {
            historyUrl.searchParams.set("acid", categoryDto.eventCategoryID.toString());
        } else {
            historyUrl.searchParams.delete("acid");
            if (categoryDto) {
                historyUrl.searchParams.set("acid", categoryDto.eventCategoryID.toString());
            }
        }

        history.replaceState(null, "", historyUrl.href);
    }

    public getProductVendorSku(product: IProductDto) {
        if (product.hideVendor && product.hideStyle) {
            return "";
        }
        if (product.hideVendor) {
            return product.sku;
        }
        if (product.hideStyle) {
            return product.vendorName;
        }

        return product.vendorName + " - " + product.sku;
    }

    public getProductImageAltText(product: IProductDto) {
        const id = product.eventProductID;

        return `Product Image: ${id}`;
    }

    public getProductName(product: IProductDto) {
        return product.hideProductName ? "" : product.productName;
    }

    public async loadProducts(eventID: number, dealerID: number, categoryID?: number) {
        let focusId = null;
        if (this.productShell && this.productShell.currentCategoryFocusId) {
            focusId = this.productShell.currentCategoryFocusId;
        }
        const products = (await WebHelper.getJsonData(
            `/api/EventProductEcom/${eventID}/${dealerID}` + `/${categoryID}/${this.currentPage}`,
            true
        )) as IProductsDto;

        if (!this.products) {
            this.products = products.products;
        } else {
            this.products.push(...products.products);
        }

        this.allLoaded = products.lastPage;
        if (focusId && this.productShell) {
            this.productShell.selectCategory(focusId);
        } else {
            this.tryFocus(categoryID);
        }
    }

    public async created() {
        if (this.categoryDto) {
            this.hasPassword = this.categoryDto.hasPassword;
            this.isValidated = this.categoryDto.isAuthorized;
            this.categoryID = this.categoryDto.eventCategoryID;
        }

        if (this.load) {
            await this.tryLoadingProducts();
        }

        if (this.initialProducts) {
            this.tryFocus();
            this.allLoaded = this.initialProducts.lastPage;
        }
    }

    private async tryLoadingProducts() {
        if (!this.load) {
            return;
        }
        if (this.hasPassword && !this.isValidated) {
            return;
        }
        if (!this.eventProperties) {
            return;
        }
        if (this.initialProducts) {
            return;
        }
        if (this.hasLoaded) {
            return;
        }

        this.hasLoaded = true;
        await this.loadProducts(
            this.eventProperties.eventID,
            this.eventProperties.dealerID,
            this.categoryDto == null ? 0 : this.categoryDto.eventCategoryID
        );
    }

    private async loadNextPage() {
        this.currentPage += 1;
        await this.loadProducts(
            this.eventProperties.eventID,
            this.eventProperties.dealerID,
            this.categoryDto == null ? 0 : this.categoryDto.eventCategoryID
        );
    }

    private tryFocus(categoryID?: number) {
        const shouldFocus = this.eventProperties.selectedCategoryID === categoryID || !categoryID;
        if (!shouldFocus) {
            return;
        }

        Vue.nextTick(() => {
            if (this.eventProperties.selectedProductID) {
                for (let pid = 0; pid < this.products.length; pid++) {
                    const product = this.products[pid];
                    if (product.eventProductID !== this.eventProperties.selectedProductID) {
                        continue;
                    }
                    this.$refs.products[pid].scrollIntoView();
                    this.$emit("scroll");
                    return;
                }
            } else if (categoryID) {
                this.$refs.outerMost.scrollIntoView(false);
                this.$emit("scroll");
            }
        });
    }
}
