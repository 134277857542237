var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"chipplyFont",staticStyle:{"min-height":"300px","padding":"0px"},attrs:{"fluid":""}},[(!_vm.loaded && !_vm.eventCategories.storePasswordNeedsValidation)?_c('v-row',{staticStyle:{"height":"300px"},attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":200,"width":15,"indeterminate":""}})],1):_vm._e(),(_vm.loaded && _vm.eventCategories.storePasswordNeedsValidation)?[_c('category-password-authenticator',{attrs:{"eventID":_vm.eventProperties.eventID},on:{"validated":_vm.eventValidated}})]:[(_vm.loaded && _vm.eventCategories.containers && _vm.eventCategories.containers.length === 0)?_c('product-loader',{attrs:{"paging":"","event-properties":_vm.eventProperties,"initial-products":_vm.eventCategories.products,"productShell":_vm.productShell},on:{"scroll":function($event){return _vm.scrollEvent(1)}}}):_vm._e(),(_vm.loaded && _vm.eventCategories.isSingleCategoryExpansion && _vm.eventCategories.containers)?_c('v-expansion-panels',{model:{value:(_vm.panelToggles),callback:function ($$v) {_vm.panelToggles=$$v},expression:"panelToggles"}},[_vm._l((_vm.eventCategories.containers),function(container,i){return [(container.$typeHint === 'Package')?[_c('v-expansion-panel',{key:i,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",attrs:{"color":_vm.categoryBackgroundColor,"data-id":container.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{style:({
                                        color: _vm.categoryForegroundColor,
                                        textAlign: 'center',
                                        width: '100%',
                                        height: '100%',
                                    })},[_vm._v(" "+_vm._s(container.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({ backgroundColor: _vm.backgroundColor })},[(container.hasPassword && !container.isAuthorized)?[_c('category-password-authenticator',{attrs:{"categoryID":container.eventCategoryID},on:{"validated":function($event){return _vm.validated($event, container.eventCategoryID)}}})]:[_c('store-package',{attrs:{"package":container,"eventID":_vm.eventID,"foregroundColor":_vm.categoryForegroundColor,"backgroundColor":_vm.categoryBackgroundColor}})]],2)],1)]:[_c('v-expansion-panel',{key:i,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",attrs:{"color":_vm.categoryBackgroundColor,"data-id":container.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var open = ref.open;
return [_c('div',{style:({
                                        color: _vm.categoryForegroundColor,
                                        textAlign: 'center',
                                        width: '100%',
                                        height: '100%',
                                    })},[_vm._v(" "+_vm._s(container.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({ backgroundColor: _vm.backgroundColor })},[_c('div',{staticClass:"subheading",staticStyle:{"font-style":"italic"}},[_vm._v(" "+_vm._s(container.subheader)+" ")]),_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(container.description)+" ")]),(container.hasPassword && !container.isAuthorized)?[_c('category-password-authenticator',{attrs:{"categoryID":container.eventCategoryID},on:{"validated":function($event){return _vm.validated($event, container.eventCategoryID)}}})]:[(!container.containers || container.containers.length === 0)?_c('product-loader',{attrs:{"load":_vm.panelToggles === i,"event-properties":_vm.eventProperties,"category-dto":container,"initial-products":container.products,"productShell":_vm.productShell},on:{"scroll":function($event){return _vm.scrollEvent(2)}}}):_vm._e(),(container.containers && container.containers.length > 0)?_c('div',{staticClass:"subcategory"},[_c('v-expansion-panels',{model:{value:(_vm.subcategoryToggles[i].toggle),callback:function ($$v) {_vm.$set(_vm.subcategoryToggles[i], "toggle", $$v)},expression:"subcategoryToggles[i].toggle"}},[_vm._l((container.containers),function(subContainer,m){return [(subContainer.$typeHint === 'Package')?[_c('v-expansion-panel',{key:m,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",attrs:{"color":_vm.categoryBackgroundColor,"data-id":container.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var open = ref.open;
return [_c('div',{style:({
                                                                    color: _vm.categoryForegroundColor,
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                })},[_vm._v(" "+_vm._s(subContainer.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({
                                                            backgroundColor: _vm.backgroundColor,
                                                        })},[(
                                                                subContainer.hasPassword &&
                                                                !subContainer.isAuthorized
                                                            )?[_c('category-password-authenticator',{attrs:{"categoryID":subContainer.eventCategoryID},on:{"validated":function($event){return _vm.validated($event, subContainer.eventCategoryID)}}})]:[_c('store-package',{attrs:{"eventID":_vm.eventID,"package":subContainer,"foregroundColor":_vm.categoryForegroundColor,"backgroundColor":_vm.categoryBackgroundColor}})]],2)],1)]:[_c('v-expansion-panel',{key:m,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",staticStyle:{"padding-left":"20px"},attrs:{"dense":"","color":_vm.categoryBackgroundColor,"data-id":subContainer.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                            var open = ref.open;
return [_c('div',{style:({
                                                                    color: _vm.categoryForegroundColor,
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                })},[_vm._v(" "+_vm._s(subContainer.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({
                                                            backgroundColor: _vm.backgroundColor,
                                                        })},[_c('div',{staticClass:"subheading",staticStyle:{"font-style":"italic"}},[_vm._v(" "+_vm._s(subContainer.subheader)+" ")]),_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(subContainer.description)+" ")]),_c('product-loader',{attrs:{"load":_vm.subcategoryToggles[i].toggle === m,"event-properties":_vm.eventProperties,"initial-products":subContainer.products,"is-subcategory":true,"category-dto":subContainer,"productShell":_vm.productShell},on:{"scroll":function($event){return _vm.scrollEvent(3)}}})],1)],1)]]})],2)],1):_vm._e()]],2)],1)]]})],2):_vm._e(),(_vm.loaded && !_vm.eventCategories.isSingleCategoryExpansion && _vm.eventCategories.containers)?_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panelTogglesArray),callback:function ($$v) {_vm.panelTogglesArray=$$v},expression:"panelTogglesArray"}},[_vm._l((_vm.eventCategories.containers),function(category,i){return [(category.$typeHint === 'Package')?[_c('v-expansion-panel',{key:i,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",attrs:{"color":_vm.categoryBackgroundColor,"data-id":category.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                        var open = ref.open;
return [_c('div',{style:({
                                        color: _vm.categoryForegroundColor,
                                        textAlign: 'center',
                                        width: '100%',
                                        height: '100%',
                                    })},[_vm._v(" "+_vm._s(category.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({ backgroundColor: _vm.backgroundColor })},[(category.hasPassword && !category.isAuthorized)?[_c('category-password-authenticator',{attrs:{"categoryID":category.eventCategoryID},on:{"validated":function($event){return _vm.validated($event, category.eventCategoryID)}}})]:[_c('store-package',{attrs:{"package":category,"eventID":_vm.eventID,"foregroundColor":_vm.categoryForegroundColor,"backgroundColor":_vm.categoryBackgroundColor}})]],2)],1)]:[_c('v-expansion-panel',{key:i,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",attrs:{"color":_vm.categoryBackgroundColor,"data-id":category.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var open = ref.open;
return [_c('div',{style:({
                                        color: _vm.categoryForegroundColor,
                                        textAlign: 'center',
                                        width: '100%',
                                        height: '100%',
                                    })},[_vm._v(" "+_vm._s(category.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({ backgroundColor: _vm.backgroundColor })},[_c('div',{staticClass:"subheading",staticStyle:{"font-style":"italic"}},[_vm._v(" "+_vm._s(category.subheader)+" ")]),_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(category.description)+" ")]),(category.hasPassword && !category.isAuthorized)?[_c('category-password-authenticator',{attrs:{"categoryID":category.eventCategoryID},on:{"validated":function($event){return _vm.validated($event, category.eventCategoryID)}}})]:[(!category.containers || category.containers.length === 0)?_c('product-loader',{attrs:{"load":_vm.panelTogglesArray.indexOf(i) > -1,"event-properties":_vm.eventProperties,"category-dto":category,"initial-products":category.products,"productShell":_vm.productShell},on:{"scroll":function($event){return _vm.scrollEvent(4)}}}):_vm._e(),(category.containers && category.containers.length > 0)?_c('div',{staticClass:"subcategory"},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.subcategoryTogglesArray[i]),callback:function ($$v) {_vm.$set(_vm.subcategoryTogglesArray, i, $$v)},expression:"subcategoryTogglesArray[i]"}},[_vm._l((category.containers),function(subContainer,m){return [(subContainer.$typeHint === 'Package')?[_c('v-expansion-panel',{key:m,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",attrs:{"color":_vm.categoryBackgroundColor,"data-id":subContainer.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var open = ref.open;
return [_c('div',{style:({
                                                                    color: _vm.categoryForegroundColor,
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                })},[_vm._v(" "+_vm._s(subContainer.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({
                                                            backgroundColor: _vm.backgroundColor,
                                                        })},[(
                                                                subContainer.hasPassword &&
                                                                !subContainer.isAuthorized
                                                            )?[_c('category-password-authenticator',{attrs:{"categoryID":subContainer.eventCategoryID},on:{"validated":function($event){return _vm.validated($event, subContainer.eventCategoryID)}}})]:[_c('store-package',{attrs:{"package":subContainer,"eventID":_vm.eventID,"foregroundColor":_vm.categoryForegroundColor,"backgroundColor":_vm.categoryBackgroundColor}})]],2)],1)]:[_c('v-expansion-panel',{key:m,staticClass:"expansionPanel"},[_c('v-expansion-panel-header',{staticClass:"header",staticStyle:{"padding-left":"20px"},attrs:{"color":_vm.categoryBackgroundColor,"data-id":subContainer.eventCategoryID},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                            var open = ref.open;
return [_c('div',{style:({
                                                                    color: _vm.categoryForegroundColor,
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                })},[_vm._v(" "+_vm._s(subContainer.name)+" ")])]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.categoryForegroundColor}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{style:({
                                                            backgroundColor: _vm.backgroundColor,
                                                        })},[_c('div',{staticClass:"subheading",staticStyle:{"font-style":"italic"}},[_vm._v(" "+_vm._s(subContainer.subheader)+" ")]),_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(subContainer.description)+" ")]),_c('product-loader',{attrs:{"load":_vm.subcategoryTogglesArray.length > i &&
                                                                _vm.subcategoryTogglesArray[i].indexOf(m) > -1,"event-properties":_vm.eventProperties,"initial-products":subContainer.products,"is-subcategory":true,"category-dto":subContainer,"productShell":_vm.productShell},on:{"scroll":function($event){return _vm.scrollEvent(5)}}})],1)],1)]]})],2)],1):_vm._e(),_c('div',{staticClass:"subcategory"})]],2)],1)]]})],2):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }