




































import CheckoutReviewOrderItemViewModel from "@/chipply/view-model/CheckoutReviewOrderItemViewModel";
import CheckoutReviewViewModel from "@/chipply/view-model/CheckoutReviewViewModel";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { Utils } from "chipply-common";
@Component({
    directives: { mask },
})
export default class CouponCodeApplier extends Vue {
    public Utils = Utils;

    @Prop({ default: {} })
    public vm!: CheckoutReviewViewModel;
}
