


















import { IAddress } from "chipply-common";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class AddressDisplay extends Vue {
    @Prop({ default: {} })
    public address!: IAddress;
}
