import { FieldValueEditViewModel } from "./FieldValueEditViewModel";
import { FieldModel } from "./FieldModel";
import { FieldValueModel } from "./FieldValueModel";

export class FieldValueCollectionEditViewModel {
    public fields: FieldModel[] = [];
    public fieldValues: FieldValueModel[] = [];

    public fieldEditViewModels: FieldValueEditViewModel[] = [];

    public initialize() {
        for (const field of this.fields) {
            const editVm = new FieldValueEditViewModel();
            editVm.field = field;

            const existingFieldValue = this.fieldValues.find((f) => f.fieldId === field.id);
            if (existingFieldValue) {
                editVm.fieldValueId = existingFieldValue.fieldValueId;
                editVm.value = existingFieldValue.value;
            }

            this.fieldEditViewModels.push(editVm);
        }
    }

    public toModel() {
        const models: FieldValueModel[] = [];
        for (const vm of this.fieldEditViewModels) {
            const fvm = new FieldValueModel();
            fvm.value = vm.value;
            fvm.fieldValueId = vm.fieldValueId;
            fvm.fieldId = vm.field!.id;
            models.push(fvm);
        }

        return models;
    }
}
