import Package from "./Package";
import IEventCategoryDto from "./i-event-category-dto";
import IPackageDto from "./i-package-dto";
import IProductsDto from "./i-products-dto";
import { ProductsDto } from "./products-dto";
import { typeDependencies, typeHint } from "chipply-common";

@typeHint({ typeHint: "Category" })
export default class Category implements IEventCategoryDto {
    public id = 0;
    public eventCategoryID = 0;
    public parentCategoryID = 0;
    public name = "";
    public description = "";
    public isPackage = false;
    public hasPassword = false;
    public isAuthorized = false;
    public allowMultiple = false;
    public defaultAsOpen = false;
    public containers: Array<IEventCategoryDto | IPackageDto> = [];
    public products: IProductsDto = new ProductsDto();
    public storePasswordNeedsValidation = false;
    public activeSubCategory: IEventCategoryDto | IPackageDto | null = null;

    public constructor(category?: IEventCategoryDto) {
        if (category) {
            this.id = category.id;
            this.eventCategoryID = category.eventCategoryID;
            this.parentCategoryID = category.parentCategoryID;
            this.name = category.name;
            this.description = category.description;
            this.isPackage = category.isPackage;
            this.hasPassword = category.hasPassword;
            this.isAuthorized = category.isAuthorized;
            this.defaultAsOpen = category.defaultAsOpen;
            this.containers = category.containers;
            this.products = category.products;
            this.storePasswordNeedsValidation = category.storePasswordNeedsValidation;
            this.activeSubCategory = category.activeSubCategory;
            this.allowMultiple = category.allowMultiple;
        }
    }
}
