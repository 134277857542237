















































































































































import Component from "vue-class-component";
import Vue from "vue";
import Drift from "drift-zoom";
import { Watch, Prop } from "vue-property-decorator";
import ProductColorModel from "@/chipply/ProductColorModel";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import DesignFlag from "./DesignFlag.vue";

@Component({
    props: {
        paneContainer: HTMLElement,
        productColors: Array,
        colorSelected: Number,
        watchThis: Number,
        value: Object,
        carousel: Boolean,
        packageImage: Boolean,
    },
    components: { DesignFlag },
})
export default class ProductImageViewer extends Vue {
    public get isMobile() {
        return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm";
    }
    public name = "ProductImageViewer";
    public watchThis!: number | null;
    private value!: ProductViewModel;

    // @ts-ignore
    public $refs: {
        zoomImg: HTMLImageElement;
        paneContainer: HTMLElement;
        boundingBoxContainer: HTMLElement;
        zoomPane: HTMLElement;
    };
    private paneContainer!: HTMLElement;
    private productColors!: Array<ProductColorModel>;
    private selectedEventProductColorID!: number;
    public images!: Array<any>;
    public selectedHexValue!: string;
    //private selectedImage!: object;
    private showZoom = "";

    @Prop({
        default: false,
        type: Boolean,
    })
    private carousel!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    private packageImage!: boolean;

    private drift: any;

    public data() {
        return {
            images: [],
            selectedHexValue: "",
            //selectedImage: {},
            selectedEventProductColorID: 0,
        };
    }

    private toggleOverlay(toggleValue: boolean) {
        if (toggleValue) {
            this.showZoom = "";
        } else {
            this.showZoom = "none";
        }
    }

    public async created() {
        this.initialize();
    }

    public async mounted() {
        this.toggleOverlay(false);
        if (!this.packageImage && !this.carousel) {
            this.drift = new Drift(this.$refs.zoomImg, {
                hoverBoundingBox: false,
                paneContainer: this.$refs.zoomPane,
                handleTouch: false,
                inlinePane: 1,
            });
        }
    }

    private additionalImages() {
        return this.images.length > 1;
    }

    private initialize() {
        if ((this.productColors || []).length === 0) {
            return;
        }

        const selectedID = this.selectedEventProductColorID
            ? this.selectedEventProductColorID
            : this.value.selectedColor.eventProductColorId;

        this.setEventProductColorID(selectedID);
    }

    @Watch("selectedEventProductColorID")
    private onSelectedEventProductColorIDChanged(oldValue: any, newValue: any) {
        if (oldValue === newValue) {
            return;
        }
        this.setEventProductColorID(this.selectedEventProductColorID);
    }

    @Watch("watchThis")
    private onWatchChanged(e: number) {
        this.setEventProductColorID(e);
    }

    private setEventProductColorID(id: number) {
        let selectedColor: null | any;
        for (const color of this.productColors) {
            if (color.eventProductColorID !== id) {
                continue;
            }
            selectedColor = color;
            break;
        }

        if (!selectedColor) {
            this.images = this.productColors[0].images;
            this.value.selectedImage = this.images[0];
            this.images[0].enabled = true;
            this.selectedHexValue = this.productColors[0].hexValue;
            return;
        }

        this.images = selectedColor.images;
        this.images.forEach((img) => {
            img.enabled = false;
        });
        this.images[0].enabled = true;
        this.selectedHexValue = selectedColor.hexValue;
        this.value.selectedImage = this.images[0];
    }

    @Watch("productColors")
    private onProductColorsChanged(oldValue: any, newValue: any) {
        this.initialize();
    }

    @Watch("value.details")
    private onVmChanged() {
        this.initialize();
    }

    @Watch("paneContainer")
    private onPaneContainerChanged(oldValue: any, newValue: any) {
        this.initialize();
    }

    private selectImage(img: any) {
        this.value.selectedImage.enabled = false;
        img.enabled = true;
        this.value.selectedImage = img;
    }

    private selectProductColor(color: any) {
        this.images = color.images;
        this.value.selectedImage = color.images[0];

        this.$emit("clicked", color);
    }
}
