import { FieldItemModel } from "./FieldItemModel";
import { FieldViewModel } from "./FieldViewModel";
import { ListFieldModel } from "./ListFieldModel";

export class ListFieldViewModel extends FieldViewModel {
    public modelTypeHint = "ListFieldModel";
    public items: FieldItemModel[] = [];
    public typeName = "List";

    public constructor(model?: ListFieldModel) {
        super(model);
        if (model) {
            this.items = model.items;
        }
    }

    public toModel() {
        return { ...super.toModel(), items: this.items };
    }
}
