
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

@Component({
    props: {
        quantity: Number,
        mobile: Boolean,
    },
    components: {},
})
export default class PackageDesignFlag extends Vue {
    public mobile!: boolean;
}
