import { ITextValue } from "chipply-common";
import ICartItemAttributes from "../ICartItemAttributes";

export default class CartItemAttributeViewModel {
    attributeName = "";
    userValue = "";
    eventProcessOptionID = 0;
    cartItemAttributeID = 0;
    cartID = 0;
    cartItemID = 0;
    eventProductID = 0;
    eventProcessItemID = 0;
    sessionID = "";
    isRequired = false;
    eventProcessTypeID = 0;
    attributeOptions: Array<ITextValue<number>> = [];
    valueChanged = false;
    maxLength = 0;

    public constructor(model: ICartItemAttributes) {
        this.attributeName = model.attributeName;
        this.userValue = model.userValue;
        this.eventProcessOptionID = model.eventProcessOptionID;
        this.cartItemAttributeID = model.cartItemAttributeID;
        this.cartID = model.cartID;
        this.cartItemID = model.cartItemID;
        this.eventProductID = model.eventProductID;
        this.eventProcessItemID = model.eventProcessItemID;
        this.sessionID = model.sessionID;
        this.isRequired = model.isRequired;
        this.eventProcessTypeID = model.eventProcessTypeID;
        this.attributeOptions = model.attributeOptions;
        this.maxLength = model.maxLength;
        this.valueChanged = false;
    }
}
