import { viewModelKey } from "../Serializer";

export class ViewModelFactory {
    public static getViewModel(
        args: {
            model: any;
        },
        ...additionalConstructorArgs: any[]
    ): any {
        const type = Reflect.getMetadata(viewModelKey, args.model.constructor);
        const viewModel = new type(args.model, ...additionalConstructorArgs);
        if (viewModel.created) {
            viewModel.created(args.model);
        }
        return viewModel;
    }
}
