import { BooleanFieldModel } from "./BooleanFieldModel";
import { FieldType } from "./FieldType";
import { FieldViewModel } from "./FieldViewModel";

export class BooleanFieldViewModel extends FieldViewModel {
    public modelTypeHint = "BooleanFieldModel";
    public type = FieldType.Boolean;
    public typeName = "Yes/No";

    public constructor(model?: BooleanFieldModel) {
        super(model);
    }
}
