import { AsyncInteractionResult } from "./AsyncInteractionViewModel";

export class AsyncInteractionWithDataResult {
    public data: any | null = null;
    public result: AsyncInteractionResult;

    constructor(result: AsyncInteractionResult, data?: any) {
        this.result = result;
        this.data = data;
    }
}

export class AsyncInteractionWithDataViewModel<T> {
    public data: T | null = null;
    protected _interactionResolve: null | ((result: AsyncInteractionWithDataResult) => void) = null;

    public interact() {
        return new Promise<AsyncInteractionWithDataResult>((resolve) => {
            this._interactionResolve = resolve;
        });
    }

    public done(accepted: AsyncInteractionResult, data?: any) {
        this._interactionResolve!(new AsyncInteractionWithDataResult(accepted, data));
    }
}
