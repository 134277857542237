






































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import { mask } from "vue-the-mask";
import ParentProductViewModel from "@/chipply/view-model/ParentProductViewModel";
import ChipplyChip from "@/components/design-elements/ChipplyChip.vue";
import RadioButtonModel from "@/chipply/RadioButtonModel";

@Component({
    components: { ChipplyChip },
    directives: {
        mask,
    },
})
export default class ProductSize extends Vue {
    name = "ProductSize";

    @Prop()
    public oldSizes: string[] | undefined;

    @Prop()
    public sizes!: RadioButtonModel[];

    @Prop()
    public bulk!: boolean;

    @Prop()
    public value!: ProductViewModel;

    @Prop()
    public pvm!: ParentProductViewModel;

    @Prop({
        default: false,
        type: Boolean,
    })
    public error!: boolean;

    @Prop()
    public currentMax!: number;

    @Prop()
    public currentMin!: number;

    public bulkSizes: any[] = [];

    public selected: { size: string; active: boolean }[] = [];

    public labelSizes = 16;

    private errorMessage = "";

    public async created() {
        if (this.sizes) {
            this.selected = [];
            if (this.sizes.length == 1) {
                this.selected?.push({ size: this.sizes[0].display, active: true });
            }
        }
    }

    private errorQueue() {
        let topError = "";
        this.value.bulkSizes.forEach((bulkSize) => {
            if (bulkSize.errorMessage) {
                topError = bulkSize.errorMessage;
            }
        });
        return topError;
    }

    private totalItems(specificSize?: number) {
        let count = 0;

        if (specificSize) {
            this.value.bulkSizes.forEach((bulkSize) => {
                if (bulkSize.value == specificSize) {
                    count = count + parseInt(bulkSize.quantity);
                }
            });
        } else {
            this.value.bulkSizes.forEach((bulkSize) => {
                count = count + parseInt(bulkSize.quantity);
            });
        }
        this.$emit("clearError");
        return count;
    }

    public exceedingMaxAll() {
        var totalMax = this.currentMax;
        var errorProc = false;

        this.value.bulkSizes.forEach((bulkSize) => {
            var tempMax = this.currentMax;
            var usingAvailable = false;

            if (this.value.selectedColor) {
                var colorId = this.value.selectedColor.eventProductColorId;
                var targetEntry = this.value.colorSize.colorSizeDictionary![colorId][bulkSize.value];

                if (targetEntry && (targetEntry.parentProductColorId != 0 || targetEntry.parentProductSizeId != 0)) {
                    var parentSize =
                        this.pvm.parent.colorSize.colorSizeDictionary![targetEntry.parentProductColorId][
                            targetEntry.parentProductSizeId
                        ];
                    if (parentSize != null) {
                        if (parentSize.availableQuantity != null) {
                            tempMax = parentSize.availableQuantity;
                        }
                    }
                } else if (targetEntry && targetEntry.availableQuantity != null) {
                    tempMax = targetEntry.availableQuantity;
                    usingAvailable = true;
                }
            }

            if (tempMax != null && this.totalItems(bulkSize.value) > tempMax) {
                this.errorMessage =
                    "Exceeding fixed quantity for " +
                    bulkSize.display +
                    ", please select a total of " +
                    tempMax +
                    " items or less";
                bulkSize.errorMessage =
                    "Exceeding fixed quantity for " +
                    bulkSize.display +
                    ", please select a total of " +
                    tempMax +
                    " items or less";
                errorProc = true;
            } else if (this.totalItems() > totalMax && !usingAvailable) {
                this.errorMessage =
                    "Exceeding maximum cart quantity, please select a total of " + tempMax + " items or less";
                bulkSize.errorMessage =
                    "Exceeding maximum cart quantity, please select a total of " + tempMax + " items or less";
                errorProc = true;
            } else if (this.totalItems() < this.currentMin) {
                this.errorMessage = "Item total must be greater than the set minimum of " + this.currentMin;
                bulkSize.errorMessage = "Item total must be greater than the set minimum of " + this.currentMin;
                errorProc = true;
            } else {
                this.errorMessage = "";
                bulkSize.errorMessage = "";
            }
        });

        return errorProc;
    }

    public exceedingMin() {
        if (this.totalItems() < this.currentMin) {
            return true;
        } else {
            return false;
        }
    }

    @Watch("sizes")
    private async sizeChanged() {
        this.value.bulkSizes = [];
        this.sizes!.forEach((size) => {
            var model: { display: string; value: number; quantity: number; enabled: boolean; options: any[] } = {
                display: size.display,
                value: size.value,
                quantity: 0,
                enabled: size.enabled,
                options: [],
            };

            if (size.value == this.value.selectedSize) {
                size.selected = true;
            }

            this.value.bulkSizes.push(model);
        });
    }

    public async clicked(size: any) {
        if (size.selected == true) {
            size.selected = false;
            this.$emit("size", 0);
            return;
        }

        this.sizes!.forEach((option) => {
            option.selected = false;
        });
        size.selected = true;
        this.$emit("size", size.value);
    }
}
