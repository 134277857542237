import { IOrderItem, OrderItem } from "chipply-common";

export default class CheckoutReviewOrderItemViewModel extends OrderItem {
    constructor(orderItem: IOrderItem) {
        super(orderItem);

        if (!this.imageUrl && this.colors.length > 0) {
            this.imageUrl = this.colors[0].image1;
        }
    }
}
