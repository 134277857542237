


























import Component from "vue-class-component";
import Vue from "vue";
import RadioButtonModel from "@/chipply/RadioButtonModel";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ChipplyChip extends Vue {
    @Prop({
        type: Object,
    })
    button!: RadioButtonModel;

    @Prop({
        type: Boolean,
    })
    sizeVersion!: boolean;

    private styleObject = {
        height: "",
        width: "",
    };

    private created() {
        this.screenSize();
    }

    protected async screenSize() {
        if (this.sizeVersion) {
            this.styleObject.height = "32px";
            this.styleObject.width = "";
        } else {
            this.styleObject.height = "48px";
            if (this.$vuetify.breakpoint.smAndUp) {
                this.styleObject.width = "49%";
            } else {
                this.styleObject.width = "100%";
            }
        }
    }

    private chipClicked() {
        this.$emit("chipClicked");
    }
}
