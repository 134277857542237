export type AsyncInteractionResult = "continue" | "cancel" | "accept";

export class AsyncInteractionViewModel {
    protected _interactionResolve: null | ((result: AsyncInteractionResult) => void) = null;

    public interact() {
        return new Promise<AsyncInteractionResult>((resolve) => {
            this._interactionResolve = resolve;
        });
    }

    public done(accepted: AsyncInteractionResult) {
        this._interactionResolve!(accepted);
    }
}
