import "reflect-metadata";

export class Serializer {
    protected static typeDictionary: { [typeHint: string]: { type: { new (...args: any[]): object } } } = {};
    public static register<T extends { new (...args: any[]): object }>(constructor: T, args?: { typeHint?: string }) {
        this.typeDictionary[constructor.name] = { type: constructor };
        if (args && args.typeHint) {
            this.typeDictionary[args.typeHint] = { type: constructor };
        }
    }

    public static copy<I>(data: I) {
        const serializedData = this.serialize(data);
        return this.deserialize(serializedData) as I;
    }

    public static serialize(data: any) {
        return JSON.stringify(data);
    }

    public static deserialize<TResult>(data: string) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const serializerThis = this;
        return JSON.parse(data, function (key: string, value: any) {
            return <TResult>serializerThis.deserializeInternal(this, key, value);
        });
    }

    protected static deserializeInternal(object: any, key: string, value: any) {
        if (!value || !value.$typeHint) {
            return value;
        }
        const typeEntry = this.typeDictionary[value.$typeHint];

        if (!typeEntry) {
            if (console && console.info) {
                console.info(
                    `${value.$typeHint} type is not registered with the Serializer, cannot deserialize into type`
                );
                return value;
            }
        }

        const newObject = new typeEntry.type();
        Object.assign(newObject, value);
        return newObject;
    }
}

export function typeHint(args: { typeHint: string }) {
    return function initTypeHint<T extends { new (...args: any[]): object }>(constructor: T) {
        Serializer.register(constructor, args);
        return constructor;
    };
}

export function typeDependencies(args: { types: { [key: string]: { new (): any } } }) {
    return function registerTypeDependency<T extends { new (...args: any[]): object }>(constructor: T) {
        return constructor;
    };
}

export const viewModelKey = "viewmodel";

export function viewModel<T extends { new (...args: any[]): object }>(viewModelType: T) {
    return function initTypeHint<T extends { new (...args: any[]): object }>(targetType: T) {
        Reflect.defineMetadata(viewModelKey, viewModelType, targetType);
        return targetType;
    };
}

export const defaultViewKey = "defaultView";

export function defaultView<T extends { new (...args: any[]): object }>(viewType: T) {
    return function initTypeHint<T extends { new (...args: any[]): object }>(targetType: T) {
        Reflect.defineMetadata(defaultViewKey, viewType, targetType);
        return targetType;
    };
}
