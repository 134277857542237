import {
    mdiAlert,
    mdiAlertCircle,
    mdiAlertOutline,
    mdiArrowLeft,
    mdiArrowRight,
    mdiBriefcase,
    mdiCalendar,
    mdiCamera,
    mdiCancel,
    mdiCardsSpade,
    mdiCart,
    mdiCheck,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiChevronDown,
    mdiChevronRight,
    mdiChevronUp,
    mdiClockOutline,
    mdiClose,
    mdiCloudUpload,
    mdiCog,
    mdiCogs,
    mdiContentCopy,
    mdiContentPaste,
    mdiContentSave,
    mdiCurrencyUsd,
    mdiDelete,
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiDownload,
    mdiEmailSend,
    mdiEye,
    mdiEyeOff,
    mdiFileMultiple,
    mdiFormatListBulleted,
    mdiFormatListChecks,
    mdiHelpCircle,
    mdiImage,
    mdiKey,
    mdiLogout,
    mdiMagnify,
    mdiMenuRight,
    mdiMinus,
    mdiPencil,
    mdiPlus,
    mdiPlusCircleOutline,
    mdiPrinter,
    mdiRefresh,
    mdiRss,
    mdiRun,
    mdiShape,
    mdiStar,
    mdiStore,
    mdiStorefrontOutline,
    mdiSync,
    mdiTruckDelivery,
    mdiViewDashboard,
    mdiWeatherLightningRainy,
} from "@mdi/js";
import { mdiDomain } from "@mdi/js";

// These can be found at https://materialdesignicons.com/

const chipplyIcons = {
    mdiAlert,
    mdiAlertCircle,
    mdiAlertOutline,
    mdiArrowRight,
    mdiArrowLeft,
    mdiBriefcase,
    mdiCalendar,
    mdiCamera,
    mdiCancel,
    mdiCardsSpade,
    mdiCart,
    mdiCheck,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiChevronRight,
    mdiChevronDown,
    mdiChevronUp,
    mdiClockOutline,
    mdiClose,
    mdiCloudUpload,
    mdiCog,
    mdiCogs,
    mdiContentCopy,
    mdiContentPaste,
    mdiContentSave,
    mdiCurrencyUsd,
    mdiDelete,
    mdiDomain,
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiDownload,
    mdiEmailSend,
    mdiEye,
    mdiEyeOff,
    mdiFileMultiple,
    mdiFormatListBulleted,
    mdiFormatListChecks,
    mdiHelpCircle,
    mdiImage,
    mdiKey,
    mdiLogout,
    mdiMagnify,
    mdiMenuRight,
    mdiMinus,
    mdiPencil,
    mdiPlus,
    mdiPlusCircleOutline,
    mdiPrinter,
    mdiRefresh,
    mdiRss,
    mdiRun,
    mdiShape,
    mdiStar,
    mdiStore,
    mdiStorefrontOutline,
    mdiSync,
    mdiTruckDelivery,
    mdiViewDashboard,
};

export default chipplyIcons;
