import { typeHint } from "chipply-common";
import IPackageDto from "./i-package-dto";
import IProductsDto from "./i-products-dto";
import { ProductsDto } from "./products-dto";

@typeHint({ typeHint: "Package" })
export default class Package implements IPackageDto {
    id = 0;
    name = "";
    eventCategoryID = 0;
    parentCategoryID = 0;
    price = 0;
    products: IProductsDto = new ProductsDto();
    isPackage = false;
    hasPassword = false;
    isAuthorized = false;
    allowMultiple = false;
    count = 0;
    defaultAsOpen = false;
}
