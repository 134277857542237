




























































































































































































import CheckoutReviewViewModel from "@/chipply/view-model/CheckoutReviewViewModel";
import AddressDisplay from "@/components/AddressDisplay.vue";
import CouponCodeApplier from "@/components/CouponCodeApplier.vue";
import ItemDisplay from "@/components/ItemDisplay.vue";
import OrderSummary from "@/components/OrderSummary.vue";
import StoreLayout from "@/components/StoreLayout.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CCreditCard from "@/components/CCreditCard.vue";
import { Utils } from "chipply-common";
import { loadStripe, Stripe, StripeElements, StripePaymentElement } from "@stripe/stripe-js";

@Component({
    components: {
        CouponCodeApplier,
        OrderSummary,
        ItemDisplay,
        AddressDisplay,
        StoreLayout,
        CCreditCard,
    },
})
export default class CheckoutReviewRevised extends Vue {
    public Utils = Utils;

    public $refs!: {
        creditCardContainer: HTMLDivElement;
    };

    public name = "CheckoutReview";
    @Prop({ default: {} })
    public vm!: CheckoutReviewViewModel;
    protected readonly eventIdParamName = "eventid";
    public loading = false;
    public paymentElement: StripePaymentElement | null | undefined = null;
    public previouslyMounted = false;

    public async mounted() {
        if (this.previouslyMounted) {
            return;
        }

        this.$nextTick(() => {
            if (!this.$refs.creditCardContainer) {
                return;
            }

            this.paymentElement = this.vm.stripeElementsInstance!.create("payment");
            this.paymentElement!.mount(this.$refs.creditCardContainer);
        });
        this.previouslyMounted = true;
    }

    //write code to load stripe

    public async emitRequest() {
        var results = await this.vm.placeOrder();
        this.$emit("orderPlaced", results);
    }
}
