import { DateTime } from "luxon";

export class DateUtils {
    public static parseDateString(datetime: string | null, useTwelveHourTime?: boolean) {
        if (!datetime || datetime.startsWith("0001-01-01")) {
            return { dateString: "", timeString: "" };
        }

        return {
            dateString: DateTime.fromISO(datetime).toFormat("yyyy-MM-dd"),
            timeString: useTwelveHourTime
                ? DateTime.fromISO(datetime).toLocaleString(DateTime.TIME_SIMPLE)
                : DateTime.fromISO(datetime).toLocaleString(DateTime.TIME_24_SIMPLE),
        };
    }

    public static getDstDateTime(date: string, time: string) {
        let dateDateTime = DateTime.fromISO(date);
        const offset = dateDateTime.offset;
        const millis = dateDateTime.toMillis();
        const hour = parseInt(time ? time.split(":")[0] : "0", 10);
        const minute = parseInt(time ? time.split(":")[1] : "0", 10);
        dateDateTime = dateDateTime.plus({ hours: hour, minutes: minute });
        if (dateDateTime.offset !== offset) {
            // Needed to handle when the addition crosses a DST change, e.g. 3/14/2021, 11/7/2021
            const offsetAdjustment = offset - dateDateTime.offset;
            dateDateTime = dateDateTime.plus({ minutes: offsetAdjustment });
        }
        return dateDateTime;
    }

    public static getDstDate(date: string, time: string) {
        const dateDateTime = this.getDstDateTime(date, time);
        return dateDateTime.toISO();
    }

    public static getTodayDateString() {
        return this.getDateString(DateTime.now());
    }

    public static getDateString(dateTime: DateTime, useLocalizedNumericDate = false) {
        if (useLocalizedNumericDate) {
            // example: 5/10/2024
            return dateTime.toFormat("D");
        }
        return dateTime.toFormat("yyyy-MM-dd");
    }
}
