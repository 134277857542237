import { typeHint, viewModel } from "../Serializer";
import { FieldItemModel } from "./FieldItemModel";
import { FieldModel } from "./FieldModel";
import { ListFieldViewModel } from "./ListFieldViewModel";

@typeHint({ typeHint: "ListFieldModel" })
@viewModel(ListFieldViewModel)
export class ListFieldModel extends FieldModel {
    public items: FieldItemModel[] = [];
    public $typeHint = "ListFieldModel";
}
