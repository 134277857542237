









































import { Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";
import ProductImageViewer from "@/components/ProductImageViewer.vue";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import PackageViewModel from "@/chipply/view-model/PackageViewModel";

@Component({
    components: { ProductImageViewer },
    props: {
        selectedProduct: ProductViewModel,
        packageVm: PackageViewModel,
        color: Number,
    },
})
export default class PackageDrawerProductInfo extends Vue {
    public selectedProduct!: ProductViewModel;
    public color!: number;
    public packageVm!: PackageViewModel;
}
