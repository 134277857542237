


























































import { ICreditCardInfo } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import valid from "card-validator";
import { mask } from "vue-the-mask";

@Component({
    directives: { mask },
    props: {
        card: Object,
        enableAutocomplete: Boolean,
        disabled: Boolean,
    },
})
export default class CCreditCard extends Vue {
    public card!: ICreditCardInfo | null;

    protected validateCardNumber(val: string) {
        const validationResults = valid.number(val);
        if (validationResults.isPotentiallyValid) {
            return true;
        }
        return "You must enter a valid card number";
    }

    protected validateCardName(val: string) {
        const validationResult = valid.cardholderName(val);
        if (validationResult.isValid) {
            return true;
        }
        return "You must enter a valid cardholder name";
    }

    protected validateExpiration(val: string) {
        const validationResult = valid.expirationDate(val);
        if (validationResult.isValid) {
            return true;
        }
        return "You must enter a valid expiration";
    }

    protected expirationInput(evt: KeyboardEvent) {
        const number = Number(evt.key);
        if (Number.isNaN(number)) {
            return;
        }

        const input = evt.currentTarget as HTMLInputElement;
        if (input.value.length > 0) {
            return;
        }

        if (number > 1 && number < 10) {
            input.value = "0" + number.toString() + "/";
            evt.preventDefault();
        }
    }
}
