

































































import Vue from "vue";
import Component from "vue-class-component";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import { colors } from "vuetify/lib";

@Component({
    components: {},
    props: {
        vm: Object,
        value: Boolean,
        cartVm: Object,
        bulk: Boolean,
        mobile: Boolean,
    },
})
export default class ProductDialog extends Vue {
    private value = false;
    private vm!: ProductViewModel;
    private snackbar = false;
    private cartVm!: CartViewModel;
    private bulkItems = 0;

    public dialogClosed() {
        this.snackbar = false;
        this.vm.loading = true;
        location.reload();
    }

    private get multiColors() {
        var count = 0;
        var cutoff = 3;
        var colorString = "";

        if (!this.$vuetify.breakpoint.smAndUp) {
            cutoff = 1;
        }

        this.vm.selectedReqColors.forEach((color) => {
            if (color.selected) {
                if (count < cutoff) {
                    colorString += color.colorName + ", ";
                }
                count++;
            }
        });

        if (count > cutoff) {
            var additional = count - cutoff;
            colorString += "and " + additional + " more";
        } else {
            colorString = colorString.slice(0, -2);
        }

        return colorString;
    }

    private get cartTarget() {
        return "./cart.aspx?eventId=" + this.vm.product?.eventID;
    }

    private checkWidth() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return "850px";
        } else {
            return "250px";
        }
    }

    private checkStyle() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return "";
        } else {
            return "position: absolute; bottom: 0px;";
        }
    }

    public async showSnackbar(itemNum = 0) {
        if (this.vm.product) {
            await this.cartVm.getCartItems(this.vm.product.eventID);
            this.bulkItems = itemNum;
            this.snackbar = true;
        }
    }
}
