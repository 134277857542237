
import { AddressValidationViewModel } from "@/chipply/address-validation/AddressValidationViewModel";
import AddressDisplay from "@/components/AddressDisplay.vue";
import { AsyncInteractionViewModel } from "chipply-common";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    components: { AddressDisplay },
})
export default class AddressValidationCard extends Vue {
    @Prop()
    public viewModel!: AddressValidationViewModel | null;
}
