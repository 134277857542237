












































import Vue from "vue";
import Component from "vue-class-component";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import { mask } from "vue-the-mask";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    props: {
        packageView: Boolean,
        cartVm: Object,
        minQty: Number,
        maxQty: Number,
        value: Number,
    },
    directives: {
        mask,
    },
})
export default class QuantityComponent extends Vue {
    public packageView!: boolean;
    //public value!: ProductViewModel;
    public value!: number;
    public cartVm!: CartViewModel;
    private qtyFormValid = false;

    private minQty!: number;
    private maxQty!: number;
    private currentQty = 0;

    // @ts-ignore
    public $refs!: {
        qtyForm: any;
    };

    private created() {
        this.currentQty = this.value;
    }

    @Watch("value")
    private updateCurrentValue() {
        this.currentQty = this.value;
    }

    private calculateCols(where: string) {
        switch (where) {
            case "options":
                if (this.packageView || !this.$vuetify.breakpoint.smAndUp) {
                    return "12";
                } else {
                    return "6";
                }
            case "sizing":
                return "12";

            case "quantity":
                if (this.packageView || !this.$vuetify.breakpoint.smAndUp) {
                    return "12";
                } else {
                    return "4";
                }
            case "button":
                if (this.packageView) {
                    return "12";
                } else {
                    return "8";
                }
            default:
                break;
        }
    }

    protected screenCheck() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return true;
        } else {
            return false;
        }
    }

    // This might work better as a computed property
    protected validateQuantity(enteredNum: number) {
        this.$emit("validateQuantity", enteredNum);
    }

    public validateForm() {
        return this.$refs.qtyForm.validate();
    }

    public resetValidation() {
        this.$refs.qtyForm.resetValidation();
    }

    public cartChangeEvent(enteredNum: number) {
        this.$emit("manualChange", enteredNum);
    }

    private specifiedQuantity() {
        if (this.minQty == this.maxQty) {
            return true;
        } else {
            return false;
        }
    }
}
