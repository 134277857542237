























import Vue from "vue";
import Component from "vue-class-component";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import ProductColorSizeWrapperModel from "@/chipply/ProductColorSizeWrapperModel";
import DesignWrapperModel from "@/chipply/DesignWrapperModel";
import RadioButtonModel from "@/chipply/RadioButtonModel";
import { Prop } from "vue-property-decorator";
import ChipplyChip from "@/components/design-elements/ChipplyChip.vue";

@Component({
    components: { ChipplyChip },
    props: {
        noDesignSelected: Boolean,
    },
})
export default class ChooseYourDesign extends Vue {
    //private value!: ProductViewModel;
    //public wrapper : DesignWrapperModel = this.value.colorSize.designsObject;
    private buttonPushed = false;

    private testDummies: any[] = [
        { display: "test", enabled: false },
        { display: "Second", enabled: false },
    ];

    @Prop({
        type: Array,
    })
    public designs!: RadioButtonModel[];

    public getClassObject(enabled: boolean) {
        if (!enabled) return {};

        return {
            selected: true,
        };
    }
    public tryMethod(enabled: boolean) {
        return enabled;
    }

    private async designSelected(design: any) {
        this.designs.forEach((button) => {
            if (button.value == design.value) {
                button.selected = true;
                //button.displayClass = { selected: true }
                this.$emit("designChanged", design.value);
            } else {
                button.selected = false;
                //button.displayClass = { selected: false }
            }
        });
    }
}
