















































































































































import { IAddress, WebHelper, IStateDto, ICountryDto } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    props: {
        address: Object,
        disabled: Boolean,
        enableAutocomplete: Boolean,
        isRequired: Boolean,
        warning: String,
        checkout: Boolean,
    },
})
export default class CAddressEditor extends Vue {
    @Prop({
        default: false,
        type: Boolean,
    })
    public requireZip!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public hideName!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public hideCompanyName!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public checkout!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public validationStatus!: boolean;

    @Prop()
    public disableCountriesForShipping!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public disableMilitaryStatesForShipping!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public hideBilling!: boolean;

    public address!: IAddress | null;
    public states: IStateDto[] = [];
    public countries: ICountryDto[] = [];
    public isRequired!: boolean;
    //public chipplyIcons = chipplyIcons;

    public async created() {
        this.countries = await WebHelper.getJsonData(`/api/List/Countries`, false);
        this.states = await WebHelper.getJsonData("/api/List/States", false);
        if (this.address) {
            this.address.country = this.countries[0].alpha3;
        }
    }

    public get computedCountries() {
        if (this.disableCountriesForShipping) {
            return this.countries.filter((x) => x.alpha2 === "US");
        } else {
            return this.countries;
        }
    }

    public militaryStatesSet = new Set(["AA", "AE", "AP"]);

    public get computedStates() {
        var filteredStates = this.states;
        if (this.disableMilitaryStatesForShipping) {
            filteredStates = filteredStates.filter((x) => !this.militaryStatesSet.has(x.name));
        }
        if (this.address && this.address!.country) {
            filteredStates = filteredStates.filter((x) => x.country === this.address!.country);
        }
        return filteredStates;
    }

    protected validateAddressField(value: string) {
        if (/[/"]/.test(value)) {
            return "Value cannot contain quotation marks";
        }
        if (!this.isRequired) {
            return true;
        }
        if (value) {
            return true;
        }
        return "You must enter a value";
    }

    protected countryAutocompleteChanged(value: string): void {
        if (!this.address) {
            return;
        }
        for (const item of this.countries) {
            if (item.alpha2 === value) {
                this.address.country = item.alpha3;
                return;
            }
        }
    }

    protected stateAutocompleteChanged(value: string): void {
        if (!this.address) {
            return;
        }
        for (const item of this.computedStates) {
            if (item.name === value) {
                this.address.state = item.abbreviation;
                return;
            }
            if (item.abbreviation === value) {
                this.address.state = value;
                return;
            }
        }
    }

    protected hasNoStateOrZip() {
        if (this.address?.country != "USA" && this.address?.country != "CAN") {
            return true;
        }
        return false;
    }

    protected validateStateField(value: string) {
        if (this.hasNoStateOrZip()) {
            return true;
        }

        return this.validateAddressField(value);
    }

    protected validateZipField(value: string) {
        if (/[/"]/.test(value)) {
            return "Value cannot contain quotation marks";
        }

        if (!this.isRequired && !this.requireZip) {
            return true;
        }

        if (this.hasNoStateOrZip()) {
            return true;
        }

        if (value) {
            return true;
        }

        return "You must enter a value";
    }
}
