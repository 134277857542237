






























































import { WebHelper } from "chipply-common";
import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import IProductsDto from "@/chipply/i-products-dto";
import IProductDto from "@/chipply/i-product-dto";
import IEventProperties from "@/chipply/i-event-properties";

@Component({
    props: {
        categoryID: Number,
        eventID: Number,
    },
})
export default class CategoryPasswordAuthenticator extends Vue {
    public name = "CategoryPasswordAuthenticator";
    public categoryID!: number;
    public eventID!: number;
    public loading!: boolean;
    public passcode!: string;
    public customError = "";

    // @ts-ignore
    public $refs: {
        passcodeInput: HTMLInputElement;
    };

    public data() {
        return {
            loading: false,
            passcode: "",
        };
    }

    public async validate() {
        (this.$refs.passcodeInput as any).validate(true);

        if (!(this.$refs.passcodeInput as any).valid) {
            return;
        }

        this.loading = true;
        let result = "";

        if (this.eventID) {
            result = await WebHelper.postJsonData(`/api/EventCategoryEcom/AuthenticateEvent`, {
                eventID: this.eventID,
                passcode: this.passcode,
            });
        } else {
            result = await WebHelper.postJsonData(`/api/EventCategoryEcom/AuthenticateCategory`, {
                categoryID: this.categoryID,
                passcode: this.passcode,
            });
        }

        if (!JSON.parse(result)) {
            this.customError = "The entered passcode was invalid";
        } else {
            this.customError = "";
        }
        this.$emit("validated", JSON.parse(result));
        this.passcode = "";
        this.loading = false;
    }

    public validatePassword() {
        if (this.passcode.length > 0) {
            return true;
        }
        return "You must enter a passcode.";
    }
}
