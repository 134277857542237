






















































































































































import IProductDto from "@/chipply/i-product-dto";
import IProductsDto from "@/chipply/i-products-dto";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import IPackageDto from "@/chipply/i-package-dto";
import { Utils } from "chipply-common";
import PackageDesignFlag from "./PackageDesignFlag.vue";

@Component({
    components: { PackageDesignFlag },
})
export default class StorePackage extends Vue {
    public Utils = Utils;

    @Prop({
        default: "",
        type: String,
    })
    public foregroundColor!: string;

    @Prop({
        default: "",
        type: String,
    })
    public backgroundColor!: string;

    @Prop({
        default: "",
        type: String,
    })
    public eventID!: string;

    @Prop()
    public packageDto!: IPackageDto;

    public imagesShown() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return 5;
        } else {
            return 3;
        }
    }

    public buttonWidth() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return 190;
        } else {
            return 100;
        }
    }

    private toolTipText(product: IProductDto) {
        let ele = document.createElement("div");
        if (product.shortDesc) {
            ele.innerHTML = product.shortDesc;
        }

        return ele.innerText;
    }

    private priceDisplay() {
        let displayLabel = Utils.toCurrency(this.packageDto.price);
        let allCustom = true;

        for (let product of this.packageDto.products.products) {
            if (product.zeroSellPriceLabel == null) {
                allCustom = false;
            }
        }

        if (allCustom && this.packageDto.products.products.length > 0) {
            displayLabel = this.packageDto.products.products[0].zeroSellPriceLabel;
        }

        return displayLabel;
    }

    private descText(product: IProductDto) {
        const ele = document.createElement("div");
        if (product.shortDesc) {
            ele.innerHTML = product.shortDesc;
            const target = ele.getElementsByTagName("span")[0];
            if (target) {
                target.style.fontSize = "14px";
            }
        }

        return ele.innerHTML;
    }

    public getProductName(product: IProductDto) {
        let productDisplay = "";
        if (!product.hideVendor) {
            productDisplay += product.vendorName;
        }
        if (!product.hideStyle) {
            if (productDisplay.length > 0) {
                productDisplay += " - ";
            }
            productDisplay += product.sku;
        }

        if (!product.hideProductName) {
            if (productDisplay.length > 0) {
                productDisplay += " - ";
            }

            productDisplay += product.productName;
        }

        return productDisplay;
    }
}
