























































import Vue from "vue";
import Component from "vue-class-component";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import Validation from "@/chipply/Validation";
import { Utils } from "chipply-common";
@Component({
    props: {
        option: Object,
    },
})
export default class ProductOptionDisplay extends Vue {
    private option!: IProcessOptionModel;
    public Utils = Utils;

    protected validateItem(option: IProcessOptionModel) {
        if (option.userValue && option.isRequired) {
            if (option.userValue != "") {
                return true;
            } else {
                return "Required option not filled";
            }
        } else if (option.isRequired && option.userValue == undefined) {
            return "Required option not filled";
        } else {
            return true;
        }
    }

    private mobileColLengths(priceLabel: boolean) {
        if (priceLabel) {
            if (!this.$vuetify.breakpoint.smAndUp) {
                return "4";
            } else {
                return "2";
            }
        } else {
            if (!this.$vuetify.breakpoint.smAndUp) {
                return "8";
            } else {
                return "10";
            }
        }
    }

    protected validateMaxLength(option: IProcessOptionModel) {
        if (option.maxCharacters === 0) {
            return true;
        } else {
            return Validation.requireMaximumLengthOf(option.maxCharacters)(option.userValue);
        }
    }
}
