








































import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import IDealerCategoriesModel from "@/chipply/IDealerCategoriesModel";
import IStoresDto from "@/chipply/IStoresDto";
import IStoreDto from "@/chipply/IStoreDto";
import DealerViewModel from "@/chipply/view-model/DealerViewModel";
import CategorySelect from "@/components/design-elements/CategorySelect.vue";
import CategoryDrawerDivider from "@/components/design-elements/CategoryDrawerDivider.vue";
import CategoryDrawerHeader from "@/components/design-elements/CategoryDrawerHeader.vue";

@Component({
    components: { CategoryDrawerHeader, CategoryDrawerDivider, CategorySelect },
})
export default class CategoryDrawer extends Vue {
    public previousCat: any = null;
    public selectedCat: any = null;
    public slideRight: boolean | null = false;
    public slideLeft = true;
    public historyArray: IDealerCategoriesModel[] = [];
    public idForStores: number | null = null;

    // @Prop({ default: () => [], type: Array })
    // public categories!: IDealerCategoriesModel[];
    //
    // @Prop({ default: () => [], type: Array })
    // public stores!: IStoreDto[];

    @Prop({ default: {}, type: Object })
    public vm!: DealerViewModel;

    // @Prop({ default: () => [], type: Array })
    @Prop({ default: {}, type: Object })
    public initialCat!: IDealerCategoriesModel;

    public transition(event: any, right: boolean) {
        var el = document.getElementById("rightPanel");
        if (el) {
            el.style.animation = "none";
            el.offsetHeight; /* trigger reflow */
            el.style.animation = "";
        }

        this.slideRight = null;
        this.slideRight = right;
    }

    public catList() {
        let subs: IDealerCategoriesModel[] = [];
        if (this.initialCat == null && this.selectedCat == null) {
            this.vm.catObject.categories[0].subCategories.forEach((sub) => {
                if (!sub.categoryBranding.hideCategory) {
                    subs.push(sub);
                }
            });
        } else {
            if (this.selectedCat == null) {
                this.idForStores = this.vm.homeCategory.categoryId;
                this.initialCat.subCategories.forEach((sub) => {
                    if (!sub.categoryBranding.hideCategory) {
                        subs.push(sub);
                    }
                });
                this.selectedCat = this.initialCat;
            } else {
                this.idForStores = this.selectedCat.categoryId;
                this.selectedCat.subCategories.forEach((sub: IDealerCategoriesModel) => {
                    if (!sub.categoryBranding.hideCategory) {
                        subs.push(sub);
                    }
                });
            }
        }
        return subs;
    }

    public async progress(selectedCat: IDealerCategoriesModel) {
        // if (this.selectedCat) {
        //     this.historyArray.push(this.selectedCat);
        // }
        let nextLevel = await this.vm.getCategoryAndSubs(this.vm.dealerId, selectedCat.categoryId);
        this.selectedCat = nextLevel.categories[0];
        this.idForStores = this.selectedCat.categoryId;
    }

    public async goBack() {
        var parentId = 0;
        if (this.selectedCat.parentCategoryId != null) {
            parentId = this.selectedCat.parentCategoryId;
        }
        let nextLevel = await this.vm.getCategoryAndSubs(this.vm.dealerId, parentId);
        if (nextLevel.categories[0].categoryId == this.vm.homeCategory.categoryId) {
            this.selectedCat = this.vm.homeCategory;
            this.idForStores = null;
        } else {
            this.selectedCat = nextLevel.categories[0];
            this.idForStores = this.selectedCat.categoryId;
        }
    }

    @Watch("initialCat")
    public intitalChanged() {
        this.progress(this.initialCat);
    }
}
