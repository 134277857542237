
































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import IDealerCategoriesModel from "@/chipply/IDealerCategoriesModel";
import IDealerDto from "@/chipply/IDealerDto";
import { DealerLogoDisplay } from "../../../../common";
@Component({
    computed: {
        DealerLogoDisplay() {
            return DealerLogoDisplay;
        },
    },
})
export default class LayoutLogo extends Vue {
    @Prop({
        type: Object,
    })
    public category!: IDealerCategoriesModel | null;

    @Prop({
        type: Object,
    })
    public dealerBranding!: IDealerDto | null;
}
