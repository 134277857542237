


























































































































































































































































import IEventBrandingDto from "@/chipply/IEventBrandingDto";
import { DealerLogoDisplay, WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import "@mdi/font/css/materialdesignicons.css";
import { Watch } from "vue-property-decorator";
import { Utils } from "chipply-common";
@Component({
    components: {},
    props: {
        branding: Object,
        eventId: Number,
        checkout: Boolean,
        cart: Boolean,
        totalDue: Number,
        hideTag: Boolean,
    },
    computed: {
        mobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
})
export default class StoreLayout extends Vue {
    public eventId!: number | null;
    public branding!: IEventBrandingDto | null;
    public checkout!: boolean | null;
    public hideTag!: boolean | null;
    public cart!: boolean | null;
    public cartQuantity: number | null = null;
    public drawer = false;
    public isNavigationButtonVisible = false;
    public Utils = Utils;
    public DealerLogoDisplay = DealerLogoDisplay;

    public $refs!: {
        appbar: HTMLElement;
    };

    public get cartTarget() {
        if (this.$vuetify.breakpoint.smAndDown && this.checkout) {
            return "";
        } else {
            return "./cart.aspx?eventId=" + this.eventId;
        }
    }

    @Watch("eventId")
    public async onEventIdChanged() {
        if (this.eventId! > 0) {
            await this.get(this.eventId!);
        }
    }

    public async created() {
        //the watch wasn't firing during testing so I'm doing this
        if (this.eventId! > 0) {
            await this.get(this.eventId!);
        }
    }

    public async openDrawer() {
        this.$emit("open");
    }

    public async get(eventId: number): Promise<void> {
        let baseUrl = "/api/Event/Cart?eventId=";
        baseUrl += encodeURIComponent(eventId);
        const results = (await WebHelper.getJsonData(baseUrl)) as any;
        if (results && results.itemQuantity) {
            this.cartQuantity = results.itemQuantity;
        } else {
            this.cartQuantity = 0;
        }
    }
}
