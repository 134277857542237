






















































import CheckoutReviewOrderItemViewModel from "@/chipply/view-model/CheckoutReviewOrderItemViewModel";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { Utils } from "chipply-common";
@Component
export default class ItemDisplay extends Vue {
    public Utils = Utils;

    @Prop({ default: false })
    public isRequestor!: boolean;

    @Prop({ default: {} })
    public item!: CheckoutReviewOrderItemViewModel;
}
