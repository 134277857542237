import { FieldViewModel } from "./FieldViewModel";
import { TextFieldModel } from "./TextFieldModel";

export class TextFieldViewModel extends FieldViewModel {
    public modelTypeHint = "TextFieldModel";
    public typeName = "Text";

    public constructor(model?: TextFieldModel) {
        super(model);
    }
}
