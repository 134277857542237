export enum ShippingType {
    Shipping,
    GroupPickup,
    InStorePickup,
}

export class ShippingTypeHelper {
    public static toString(shippingType: ShippingType) {
        switch (shippingType) {
            case ShippingType.Shipping:
                return "Shipping";
            case ShippingType.GroupPickup:
                return "Organization Delivery";
            case ShippingType.InStorePickup:
                return "In Store Pickup";
            default:
                throw Error("Shipping Type not defined");
        }
    }

    public static toShippingType(shippingType: string) {
        switch (shippingType) {
            case "Shipping":
                return ShippingType.Shipping;
            case "Organization Delivery":
                return ShippingType.GroupPickup;
            case "In Store Pickup":
                return ShippingType.InStorePickup;
            default:
                throw Error("Shipping Type not defined");
        }
    }
}
