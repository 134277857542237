





















































































import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";

@Component({
    props: {
        endDate: {
            default: "",
            type: String,
        },
        startDate: {
            default: "",
            type: String,
        },
        color: {
            default: "white",
            type: String,
        },
    },
})
export default class CountdownTimer extends Vue {
    public readonly millisecondsPerSecond = 1000;
    public readonly millisecondsPerMinute = 60 * this.millisecondsPerSecond;
    public readonly millisecondsPerHour = 60 * this.millisecondsPerMinute;
    public readonly millisecondsPerDay = 24 * this.millisecondsPerHour;

    public days = 0;
    public hours = 0;
    public minutes = 0;
    public seconds = 0;
    public endDate: string | undefined;
    public startDate: string | undefined;
    public secondsProgress = 0;
    public minutesProgress = 0;
    public hoursProgress = 0;
    public daysProgress = 0;
    public totalMilliseconds = 0;
    public remainingMilliseconds = 0;
    protected interval: any;

    public mounted() {
        this.updateRemainingTime();
        this.startUpdating();
    }

    public beforeDestroy() {
        this.stopUpdating();
    }

    protected startUpdating() {
        this.interval = setInterval(() => {
            this.updateRemainingTime();
        }, 1000);
    }

    protected stopUpdating() {
        clearInterval(this.interval);
    }

    protected updateRemainingTime() {
        const today = new Date();
        const endDate = new Date(this.endDate as string);
        const startDate = new Date(this.startDate as string);
        if (isNaN(endDate.getTime())) {
            this.setEmtptyDateValues();
            return;
        }
        this.remainingMilliseconds = endDate.getTime() - today.getTime();
        this.totalMilliseconds = endDate.getTime() - startDate.getTime();
        if (this.remainingMilliseconds <= 0) {
            this.setEmtptyDateValues();
        } else {
            this.days = Math.floor(this.remainingMilliseconds / this.millisecondsPerDay);
            this.hours = Math.floor((this.remainingMilliseconds % this.millisecondsPerDay) / this.millisecondsPerHour);
            this.minutes = Math.floor(
                (this.remainingMilliseconds % this.millisecondsPerHour) / this.millisecondsPerMinute
            );
            this.seconds = Math.floor(
                (this.remainingMilliseconds % this.millisecondsPerMinute) / this.millisecondsPerSecond
            );
            this.updateProgressRings();
        }
    }
    protected setEmtptyDateValues() {
        this.days = 0;
        this.hours = 0;
        this.seconds = 0;
        this.minutes = 0;
        this.updateProgressRings();
    }

    protected updateProgressRings() {
        if (this.seconds === 0) {
            this.secondsProgress = 0;
        } else {
            this.secondsProgress = (this.seconds / 60) * 100;
        }

        if (this.minutes === 0) {
            this.minutesProgress = 0;
        } else {
            this.minutesProgress = (this.minutes / 60) * 100;
        }

        if (this.hours === 0) {
            this.hoursProgress = 0;
        } else {
            this.hoursProgress = (this.hours / 24) * 100;
        }

        if (this.days === 0) {
            this.daysProgress = 0;
        } else {
            this.daysProgress = (this.remainingMilliseconds / this.totalMilliseconds) * 100;
        }
    }

    @Watch("endDate")
    protected endDateChanged() {
        this.updateRemainingTime();
    }
}
