















































































































import ProductColor from "../components/ProductColor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import ProductSize from "./ProductSize.vue";
import { Watch } from "vue-property-decorator";
import ProductColorSizeWrapperModel from "@/chipply/ProductColorSizeWrapperModel";
import AddToCartArgs from "@/chipply/AddToCartArgs";
import ProductOption from "@/chipply/ProductOption";
import { WebHelper } from "chipply-common";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import ProductDialog from "./ProductDialog.vue";

@Component({
    components: {
        ProductColor,
        ProductDialog,
    },
    props: {
        value: Object,
        cartVm: Object,
        packageView: Boolean,
        requestorLayouts: Array,
    },
})
export default class RequestorInfoSelector extends Vue {
    name = "RequestorInfoSelector";

    public value!: ProductViewModel;
    private myColors: Array<any> = [];
    private layouts: Array<object> = [{ text: "Create New Layout", id: 0 }];
    public selectedLayout = "";
    private cartVm!: CartViewModel;
    private showSnackbar = false;
    private layoutName = "";
    private colorError = false;
    private packageView!: boolean;
    public requestorLayouts!: Array<any>;

    // @ts-ignore
    public $refs!: {
        snack: ProductDialog;
    };

    public async created() {
        await this.initializeColors();

        this.cartVm.loModel.layouts.forEach((lo) => {
            this.layouts.push(lo);
        });

        if (this.requestorLayouts && this.requestorLayouts.length > 0) {
            this.requestorLayouts.forEach((lo) => {
                this.layouts.push(lo);
            });
        }
    }

    private async clearedText() {
        this.selectedLayout = "";
    }

    public async initializeColors() {
        this.value.colorSize.colors?.forEach((element) => {
            var targetDictionary = this.value.colorSize.colorSizeDictionary![element.eventProductColorId];

            var model: {
                colorName: string;
                hexValue: string;
                eventProductColorId: number;
                enabled: boolean;
                selected: boolean;
            } = {
                colorName: element.colorName,
                hexValue: element.hexCode1,
                eventProductColorId: element.eventProductColorId,
                enabled: true,
                selected: false,
            };

            this.value.selectedReqColors.push(model);
        });
    }

    private calculateCols(where: string) {
        switch (where) {
            case "colors": {
                let labelLengthFlag = false;
                this.value.selectedReqColors.forEach((color) => {
                    if (color.colorName.length > 18) {
                        labelLengthFlag = true;
                    }
                });
                if (labelLengthFlag || !this.$vuetify.breakpoint.smAndUp || this.packageView) {
                    return "6";
                } else {
                    return "4";
                }
            }
            default:
                break;
        }
    }

    public async updatePackageItem() {
        if (this.value.displayReqColors() == "") {
            this.colorError = true;
        } else {
            this.colorError = false;
        }

        if (this.layoutName != "" && this.selectedLayout == "Create New Layout") {
            this.value.selectedLayout = this.layoutName;
        } else if (this.selectedLayout != "") {
            this.value.selectedLayout = this.selectedLayout;
        }

        if (!this.colorError) {
            this.value.pkgItemFinalized = true;
            this.$emit("finalize");
        }
    }

    public async addToCartRequestor() {
        var args = new AddToCartArgs();
        args.productId = this.value.product!.eventProductID;
        args.sizeId = this.value.selectedSize!;

        //for requestor always assume 1 qty
        let qty = 1;
        args.quantity = qty;
        args.eventId = this.value.product!.eventID;

        var firstColor = false;

        //add the colors
        this.value.selectedReqColors.forEach((color, index) => {
            if (color.selected) {
                const po = new ProductOption();
                po.text = color.colorName + " - " + color.hexValue;
                po.processItemId = 0;
                po.processOptionId = 0;
                po.processName = "Color";
                args.productOptions.push(po);

                if (!firstColor) {
                    args.colorId = color.eventProductColorId;
                    firstColor = true;
                }
            }
        });

        //add layout if present
        if (this.layoutName != "" && this.selectedLayout == "Create New Layout") {
            const poLayout = new ProductOption();
            poLayout.text = this.layoutName;
            this.value.selectedLayout = this.layoutName;
            poLayout.processItemId = 0;
            poLayout.processOptionId = 0;
            poLayout.processName = "Layout";
            args.productOptions.push(poLayout);
        } else if (this.selectedLayout != "") {
            const poLayout = new ProductOption();
            poLayout.text = this.selectedLayout;
            this.value.selectedLayout = this.selectedLayout;
            poLayout.processItemId = 0;
            poLayout.processOptionId = 0;
            poLayout.processName = "Layout";
            args.productOptions.push(poLayout);
        }

        if (!firstColor) {
            this.colorError = true;
            return;
        } else {
            this.colorError = false;
        }

        if (args.productOptions.length <= 0) {
            //don't add to cart
        } else {
            this.value.loading = true;
            await WebHelper.postJsonData("/api/EventProductEcom/AddToCart", args);
            await this.cartVm.getCartItems(args.eventId);
            this.value.loading = false;
            await this.$refs.snack.showSnackbar();
        }
    }

    private async toggleColor(color: any) {
        if (color.selected) {
            color.selected = false;
            this.$emit("color", this.value.getASelectedColor());
        } else {
            color.selected = true;
            this.value.reqPrevColor = color.eventProductColorId;
            this.$emit("color", color.eventProductColorId);
        }
    }
}
