import { WebHelper } from "chipply-common";
import CartModel from "../ICartModel";
import ICartItem from "../ICartItem";
import ICartItemAttributes from "../ICartItemAttributes";
import IEventBrandingDto from "../IEventBrandingDto";
import IEventBrandingResults from "../IEventBrandingResults";
import IProceedToCheckoutResults from "../IProceedToCheckoutResults";
import LayoutModel from "../LayoutModel";
import RecaptchaClient from "../verify/RecaptchaClient";
import IEventCategoriesDto from "../i-event-categories-dto";
import ICartModel from "../ICartModel";
import CartItemViewModel from "./CartItemViewModel";
import { colors } from "vuetify/lib";

export default class CartViewModel {
    public branding: IEventBrandingDto = {} as any;
    public eventId: number | null = null;
    public loModel: LayoutModel = {} as LayoutModel;
    public initialized = false;
    //public proceedResults: IProceedToCheckoutResults = [] as any;
    public categories: IEventCategoriesDto = {} as any;

    public showCartError = false;
    public cartErrorMessage = "";
    public cartErrorHeader = "";

    //Previously attributes of the cart model
    public contents: Array<CartItemViewModel> = [];
    public packages: Map<string, any> = new Map();
    public itemCount = "";
    public itemTotal = "";
    public isRequestor = false;
    public cartId = 0;

    public async get(eventId: number): Promise<void> {
        this.eventId = eventId;
        let baseUrl = "/api/Event/Branding?eventId=";
        baseUrl += encodeURIComponent(eventId);
        const results = (await WebHelper.getJsonData(baseUrl)) as IEventBrandingResults;
        if (results && results.dto) {
            this.branding = results.dto;
        }
    }

    public async getCategories(eventId: number, categoriesOnly = false): Promise<void> {
        const eventCategories = (await WebHelper.getJsonData(
            `/api/EventProductEcom/GetEventCategories/` + `${eventId}/${this.branding.dealerId}/0/${categoriesOnly}`,
            true
        )) as IEventCategoriesDto;
        this.categories = eventCategories;

        if (eventCategories.products != null) {
            eventCategories.products.products.forEach((product) => {
                product.designs = [];
                try {
                    product.colors[0].url = product.productUrl;
                } catch (e) {
                    //no colors present
                }
                for (let i = 0; i < product.designCount; i++) {
                    if (i == 0) {
                        product.designs.push({
                            key: product.eventProductID,
                            value: product.colors,
                        });
                    } else {
                        product.designs.push({
                            key: i,
                            value: product.colors,
                        });
                    }
                }
            });
        }
    }

    private storeName() {
        const ele = document.createElement("div");
        if (this.branding) {
            if (this.branding.slideHeader) {
                ele.innerHTML = this.branding.slideHeader;
                return ele.innerText;
            }
        } else {
            return "";
        }
    }

    public calculateCurrentMax(productId: number, defaultMax: number): number {
        let currentMax = defaultMax;
        currentMax = defaultMax - this.calculateRunningTotal(productId);
        return currentMax;
    }

    public calculateCartMax(ids: any[], defaultMax: number, allowMultiple: boolean) {
        let currentMax = defaultMax;
        let runningTotal = 0;

        if (!allowMultiple) {
            //fixed limit is not present, look for all values
            this.contents.forEach((item) => {
                if (item.packageId == null) {
                    if (ids.includes(item.eventProductId)) {
                        runningTotal += item.displayQuantity;
                    }
                }
            });
            currentMax = currentMax - runningTotal;
        }

        return currentMax;
    }

    public calculateCurrentMaxMultiDesign(
        ids: any[],
        defaultMax: number,
        colorSizeMax?: number,
        colorString?: string,
        sizeString?: string
    ) {
        let currentMax = defaultMax;
        let runningTotal = 0;
        let itemTotal = 0;

        if (colorSizeMax) {
            currentMax = colorSizeMax;
            if (colorSizeMax <= defaultMax) {
                this.contents.forEach((item) => {
                    if (ids.includes(item.eventProductId)) {
                        if (item.itemColors[0].itemColorText == colorString && item.itemSize == sizeString) {
                            runningTotal += item.displayQuantity;
                        }
                    }
                });

                this.contents.forEach((item) => {
                    if (ids.includes(item.eventProductId)) {
                        itemTotal += item.displayQuantity;
                    }
                });
            } else {
                this.contents.forEach((item) => {
                    if (ids.includes(item.eventProductId)) {
                        runningTotal += item.displayQuantity;
                    }
                });
            }

            if (itemTotal == defaultMax) {
                return 0;
            }

            currentMax = currentMax - runningTotal;
            return currentMax;
        } else {
            //fixed limit is not present, look for all values
            this.contents.forEach((item) => {
                if (ids.includes(item.eventProductId)) {
                    runningTotal += item.displayQuantity;
                }
            });
            currentMax = currentMax - runningTotal;
        }

        return currentMax;
    }

    public calculateRunningTotal(productId: number) {
        let runningTotal = 0;
        this.contents.forEach((item) => {
            if (item.eventProductId == productId) {
                runningTotal += item.displayQuantity;
                //currentMax = item.maxQuantity - item.itemQuantity;
            }
        });
        return runningTotal;
    }

    public async clearErrors() {
        this.contents.forEach((item) => {
            item.errorMessage = "";
        });
    }

    public cartError() {
        let errorFlag = false;
        this.contents.forEach((item) => {
            if (item.errorMessage != "" && item.errorMessage !== undefined) {
                errorFlag = true;
            }
        });
        return errorFlag;
    }

    public async getCartItems(eventId: number, getCartContents = true): Promise<void> {
        // let cart: ICartModel | null = null;
        const cart = (await WebHelper.getJsonData(`/api/Cart/GetItems/${eventId}/${getCartContents}`)) as ICartModel;

        this.contents = [];
        this.packages = new Map();
        if (!cart) {
            return;
        }
        cart.contents.forEach((item) => {
            const civm = new CartItemViewModel(item);
            civm.attributes.sort((a, b) => a.cartItemAttributeID - b.cartItemAttributeID);
            this.contents.push(civm);
        });
        this.itemCount = cart.itemCount;
        this.itemTotal = cart.itemTotal;
        this.isRequestor = cart.isRequestor;
        this.cartId = cart.cartId;
        await this.getRequestorLayouts();

        this.contents.forEach((item, index, array) => {
            const newArray: Array<string> = new Array<string>();
            for (let i = 0; i < item.maxQuantity; i++) {
                newArray.push("Qty " + (i + 1));
            }

            item.quantityArray = newArray;
            item.displayQuantity = item.itemQuantity;

            const compositeKey = item.packageName + "_" + item.packageNum;

            //organize into packages
            if (item.packageName != null) {
                if (this.packages && this.packages.has(compositeKey)) {
                    const packageArray = this.packages.get(compositeKey);

                    packageArray?.nPackageArray.push(item);
                } else {
                    //const nPackageArray = new Array<CartItemViewModel>();
                    const model: { packageName: string; packageId: number; nPackageArray: Array<CartItemViewModel> } = {
                        packageName: item.packageName,
                        packageId: item.packageId,
                        nPackageArray: new Array<CartItemViewModel>(),
                    };
                    model.nPackageArray.push(item);
                    this.packages.set(compositeKey, model);
                }
            }
        });
    }

    public async getRequestorLayouts(): Promise<void> {
        this.loModel = (await WebHelper.getJsonData(`/api/Cart/GetLayouts/${this.cartId}`)) as LayoutModel;
    }

    public async changeItemQuantity(selected: number, item: number): Promise<void> {
        const args = {
            quantity: selected,
            cartItemId: item,
        };
        const quantity = await WebHelper.postJsonData(`/api/Cart/ChangeQuantity/${this.eventId}`, args);
    }

    public async deleteCartItem(target: number) {
        const args = {
            cartItemId: target,
            eventId: this.eventId,
        };
        const quantity = await WebHelper.postJsonData(`/api/Cart/DeleteItem`, args);
    }

    public async deletePackage(name: string, packageId: number, packageNum: number, eventId: number) {
        const args = {
            packageName: name,
            packageId: packageId,
            packageNumber: packageNum,
            eventId: eventId,
        };
        const quantity = await WebHelper.postJsonData(`/api/Cart/DeletePackage`, args);
    }

    public async updateOption(attrName: string, option: ICartItemAttributes) {
        const args = {
            attributeName: option.attributeName,
            userValue: option.userValue,
            eventProcessOptionId: option.eventProcessOptionID,
        };
        const itemPrice = await WebHelper.postJsonData(`/api/Cart/UpdateOptions/${this.eventId}`, option);
        return JSON.parse(itemPrice);
    }

    public async continueShopping() {
        location.assign(`./store.aspx?eid=${this.eventId}`);
    }

    protected async recaptchaHandler() {
        const recaptchaClient = new RecaptchaClient();
        const token = await recaptchaClient.execute({
            action: "submit",
        });
        return token;
    }

    public async proceedToCheckout() {
        const recaptchaInfo = await this.recaptchaHandler();
        const args = {
            eventId: this.eventId,
            token: recaptchaInfo,
        };

        debugger;

        const proceedResults = await WebHelper.postJsonData("/api/Cart/ProceedToCheckout", args);
        const parsedResults = JSON.parse(proceedResults);
        if (parsedResults.success) {
            location.assign("./checkout-review.aspx?eventId=" + this.eventId + "&stepName=billingInfo");
        } else {
            //display error
            this.cartErrorHeader = "Issue with Checkout";
            this.cartErrorMessage = parsedResults.errorMessage;
            this.showCartError = true;
        }
    }
}
