

















































































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import DealerViewModel from "@/chipply/view-model/DealerViewModel";
import LandingLayout from "@/components/LandingLayout.vue";
import CountdownTimer from "@/components/CountdownTimer.vue";
import IStoreDto from "@/chipply/IStoreDto";
import IDealerDto from "@/chipply/IDealerDto";
import chipplyIcons from "@/chipply/ImportIcons";
import IStoresDto from "@/chipply/IStoresDto";
import { StoresDto } from "@/chipply/StoresDto";
import _ from "lodash";
import CategoryDrawer from "@/components/CategoryDrawer.vue";
import IDealerCategoriesModel from "@/chipply/IDealerCategoriesModel";
import { Serializer } from "chipply-common";

@Component({
    components: { CategoryDrawer, LandingLayout, CountdownTimer },
})
export default class DealerLanding extends Vue {
    public name = "DealerLanding";
    public vm: DealerViewModel = new DealerViewModel();
    public eventId = 0;
    public dealerId = 0;
    public categoryId = 0;
    public idForStores: number | null = null;
    public categories = "";
    public isPreview = false;
    public loaded!: boolean;
    public newBranding: IDealerDto | null = null;
    public listView = false;
    public chipplyIcons = chipplyIcons;
    public loadingDone = false;
    public selectedCategory: IDealerCategoriesModel | null = null;
    public historyArray: IDealerCategoriesModel[] = [];
    public crumbArray: IDealerCategoriesModel[] = [];

    public initialUrlSegment = "";
    public segmentSet = false;

    // @ts-ignore
    public $refs!: {
        landingLayout: LandingLayout;
        video: HTMLIFrameElement;
    };

    protected done = false;
    protected player: any;

    public navigateToStore(store: IStoreDto) {
        window.open("/" + store.accessUrl + "/" + "store.aspx?eid=" + store.storeId, "_blank");
    }

    public getStoreImageAltText(store: IStoreDto) {
        const id = store.storeId;
        return `Store Image: ${id}`;
    }

    public created() {
        window.onpopstate = (event: Event) => {
            this.goBack();
        };
    }

    public async crumbClicked(cat: IDealerCategoriesModel | null) {
        this.crumbArray.length = this.crumbArray.findIndex((x) => x.categoryName == cat?.categoryName) + 1;
        let urlTraveledPath = "";
        let currentUrl = window.location.href;
        let historyUrl = new URL(currentUrl);

        this.crumbArray.forEach((crumb) => {
            urlTraveledPath = urlTraveledPath + "/" + crumb.urlSegment;
        });

        if (cat) {
            let crumbLevel = await this.vm.getCategoryAndSubs(this.dealerId, cat.categoryId);
            this.selectedCategory = crumbLevel.categories[0];
            this.idForStores = this.selectedCategory.categoryId;

            if (this.initialUrlSegment == "/") {
                history.pushState(null, "", historyUrl.origin + "/cat" + urlTraveledPath);
            } else {
                history.pushState(null, "", historyUrl.origin + this.initialUrlSegment + urlTraveledPath);
            }
        } else {
            if (this.vm.homeCategory) {
                let crumbLevel = await this.vm.getCategoryAndSubs(this.dealerId, this.vm.homeCategory.categoryId);
                this.selectedCategory = crumbLevel.categories[0];
                if (this.selectedCategory.categoryId == 0) {
                    this.idForStores = 0;
                } else {
                    this.idForStores = this.selectedCategory.categoryId;
                }
                if (this.initialUrlSegment == "/") {
                    history.pushState(null, "", historyUrl.origin);
                } else {
                    history.pushState(null, "", historyUrl.origin + this.initialUrlSegment);
                }
            } else {
                let crumbLevel = await this.vm.getCategoryAndSubs(this.dealerId, 0);
                this.selectedCategory = null;
                this.idForStores = 0;
            }
        }
    }

    public async progress(selectedCat: IDealerCategoriesModel) {
        this.historyArray.push(this.selectedCategory!);
        this.crumbArray.push(selectedCat);
        let nextLevel = await this.vm.getCategoryAndSubs(this.dealerId, selectedCat.categoryId);
        this.selectedCategory = nextLevel.categories[0];

        this.idForStores = this.selectedCategory.categoryId;
        let currentUrl = window.location.href;
        let historyUrl = new URL(currentUrl);
        window.scrollTo(0, 0);

        if (!this.segmentSet) {
            this.initialUrlSegment = historyUrl.pathname;
            this.segmentSet = true;
        }

        if (historyUrl.pathname == "/") {
            history.pushState(null, "", historyUrl.href + "cat/" + selectedCat.urlSegment);
        } else {
            history.pushState(null, "", historyUrl.href + "/" + selectedCat.urlSegment);
        }
    }

    public goBack() {
        var previous = this.historyArray.pop();
        var crumbPrevious = this.crumbArray.pop();
        if (previous == undefined) {
            this.selectedCategory = this.vm.homeCategory;
            if (this.selectedCategory.categoryId == 0) {
                this.idForStores = null;
            } else {
                this.idForStores = this.selectedCategory.categoryId;
            }
        } else {
            this.selectedCategory = previous;
            if (this.selectedCategory.categoryId == 0) {
                this.idForStores = null;
            } else {
                this.idForStores = this.selectedCategory.categoryId;
            }
        }
    }

    public categoriesPresentToShow() {
        if (this.vm.homeCategory.categoryId != 0) {
            return this.vm.catObject.showDealerCategories;
        } else {
            return this.vm.catObject.showDealerCategories && this.vm.homeCategory.subCategories.length > 0;
        }
    }

    private hiddenRemoved() {
        const unhidden = this.selectedCategory?.subCategories.filter((i) => !i.categoryBranding.hideCategory);
        return unhidden;
    }

    private allCategoriesHidden() {
        const unhidden = this.selectedCategory?.subCategories.filter((i) => !i.categoryBranding.hideCategory);
        return unhidden && unhidden.length == 0;
    }

    private brandingPresent() {
        return (
            (this.vm.branding.dealerBranding.dealerSlideImage &&
                this.vm.branding.dealerBranding.dealerSlideImage != "") ||
            (this.vm.branding.dealerBranding.dealerSlideLogo &&
                this.vm.branding.dealerBranding.dealerSlideLogo != "") ||
            (this.vm.branding.dealerBranding.dealerSlideVideo && this.vm.branding.dealerBranding.dealerSlideVideo != "")
        );
    }

    @Watch("dealerId")
    public async onDealerIdChange() {
        //assign in VM
        await this.vm.get(this.dealerId);
        await this.vm.getDealerInfo(this.dealerId);
        this.newBranding = this.vm.branding.dealerBranding;
        this.newBranding.shouldIncludeDealerLogo = true;
        this.listView = this.vm.branding.isListView;
        this.loadingDone = true;
    }

    @Watch("categoryId")
    public async onDealerCategoryIdChanged() {
        let test = await this.vm.getCategoryAndSubs(this.dealerId, this.categoryId);
        this.vm.setHomeCategory(test.categories[0]);
        this.selectedCategory = test.categories[0];
        this.idForStores = this.selectedCategory.categoryId;
    }

    @Watch("categories")
    public async onCategoriesChanged() {
        let urlCats = this.categories;
        if (urlCats.length > 0) {
            this.vm.catObject = await this.vm.getCategoryAndSubs(this.dealerId, parseFloat(urlCats.slice(-1)));
            this.vm.setHomeCategory(this.vm.catObject.categories[0]);
            this.selectedCategory = this.vm.catObject.categories[0];
            this.idForStores = this.selectedCategory.categoryId;
        } else {
            this.vm.catObject = await this.vm.getCategoryAndSubs(this.dealerId, 0);
            this.vm.setHomeCategory(this.vm.catObject.categories[0]);
            this.selectedCategory = this.vm.catObject.categories[0];
            this.idForStores = null;
        }
    }
}
