(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("reflect-metadata"), require("luxon"));
	else if(typeof define === 'function' && define.amd)
		define(["reflect-metadata", "luxon"], factory);
	else if(typeof exports === 'object')
		exports["chipply-common"] = factory(require("reflect-metadata"), require("luxon"));
	else
		root["chipply-common"] = factory(root["reflect-metadata"], root["luxon"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE_reflect_metadata__, __WEBPACK_EXTERNAL_MODULE_luxon__) {
return 