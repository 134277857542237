import CIcon1 from "./CIcon1.vue";
import CIcon2 from "./CIcon2.vue";
import CIcon3 from "./CIcon3.vue";
import CIcon4 from "./CIcon4.vue";
import CIcon5 from "./CIcon5.vue";
import CIcon6 from "./CIcon6.vue";
import CIcon7 from "./CIcon7.vue";
import CIcon8 from "./CIcon8.vue";
import CIcon9 from "./CIcon9.vue";
import CIconAnnotate from "./CIconAnnotate.vue";
import CIconApplyChange from "./CIconApplyChange.vue";
import CIconArrowBack from "./CIconArrowBack.vue";
import CIconArrowDown from "./CIconArrowDown.vue";
import CIconArrowForward from "./CIconArrowForward.vue";
import CIconArrowUp from "./CIconArrowUp.vue";
import CIconAttention from "./CIconAttention.vue";
import CIconAvatar from "./CIconAvatar.vue";
import CIconAvatarFill from "./CIconAvatarFill.vue";
import CIconAvatarOutline from "./CIconAvatarOutline.vue";
import CIconBackSpeedDialSmall from "./CIconBackSpeedDialSmall.vue";
import CIconCalendar from "./CIconCalendar.vue";
import CIconCheckBox from "./CIconCheckBox.vue";
import CIconCheckmarkSharp from "./CIconCheckmarkSharp.vue";
import CIconCheckSolid from "./CIconCheckSolid.vue";
import CIconChevronDown from "./CIconChevronDown.vue";
import CIconChevronLeft from "./CIconChevronLeft.vue";
import CIconChevronRight from "./CIconChevronRight.vue";
import CIconChevronUp from "./CIconChevronUp.vue";
import CIconClose from "./CIconClose.vue";
import CIconCloseCircle from "./CIconCloseCircle.vue";
import CIconCopy from "./CIconCopy.vue";
import CIconCustomItem from "./CIconCustomItem.vue";
import CIconDashboardSolid from "./CIconDashboardSolid.vue";
import CIconDashOutline from "./CIconDashOutline.vue";
import CIconDatePickerChevronDown from "./CIconDatePickerChevronDown.vue";
import CIconDatePickerChevronLeft from "./CIconDatePickerChevronLeft.vue";
import CIconDatePickerChevronRight from "./CIconDatePickerChevronRight.vue";
import CIconDatePickerChevronUp from "./CIconDatePickerChevronUp.vue";
import CIconDateRange from "./CIconDateRange.vue";
import CIconDot from "./CIconDot.vue";
import CIconDotOuter from "./CIconDotOuter.vue";
import CIconEdit from "./CIconEdit.vue";
import CIconExport from "./CIconExport.vue";
import CIconExternalLink from "./CIconExternalLink.vue";
import CIconEye from "./CIconEye.vue";
import CIconEyeOff from "./CIconEyeOff.vue";
import CIconFacebook from "./CIconFacebook.vue";
import CIconGear from "./CIconGear.vue";
import CIconGlassdoor from "./CIconGlassdoor.vue";
import CIconHamburger from "./CIconHamburger.vue";
import CIconHelpFill from "./CIconHelpFill.vue";
import CIconHelpOutline from "./CIconHelpOutline.vue";
import CIconHelpSquare from "./CIconHelpSquare.vue";
import CIconHistory from "./CIconHistory.vue";
import CIconHistoryDisabled from "./CIconHistoryDisabled.vue";
import CIconHouse from "./CIconHouse.vue";
import CIconInfo from "./CIconInfo.vue";
import CIconInstagram from "./CIconInstagram.vue";
import CIconLinkedin from "./CIconLinkedin.vue";
import CIconList from "./CIconList.vue";
import CIconListWithCheckmark from "./CIconListWithCheckmark.vue";
import CIconLock from "./CIconLock.vue";
import CIconMail from "./CIconMail.vue";
import CIconMenu from "./CIconMenu.vue";
import CIconMenuDown from "./CIconMenuDown.vue";
import CIconMenuLeft from "./CIconMenuLeft.vue";
import CIconMenuRight from "./CIconMenuRight.vue";
import CIconMenuUp from "./CIconMenuUp.vue";
import CIconMinus from "./CIconMinus.vue";
import CIconNavigation from "./CIconNavigation.vue";
import CIconNote from "./CIconNote.vue";
import CIconNoteFill from "./CIconNoteFill.vue";
import CIconNoteLandscape from "./CIconNoteLandscape.vue";
import CIconOrderDeactivate from "./CIconOrderDeactivate.vue";
import CIconOrderFilter from "./CIconOrderFilter.vue";
import CIconOrderManager from "./CIconOrderManager.vue";
import CIconOrderManagerLarge from "./CIconOrderManagerLarge.vue";
import CIconOrganizations from "./CIconOrganizations.vue";
import CIconPackage from "./CIconPackage.vue";
import CIconPercent from "./CIconPercent.vue";
import CIconPlay from "./CIconPlay.vue";
import CIconPlus from "./CIconPlus.vue";
import CIconPrint from "./CIconPrint.vue";
import CIconProductManager from "./CIconProductManager.vue";
import CIconPurchasing from "./CIconPurchasing.vue";
import CIconPurchasingCircle from "./CIconPurchasingCircle.vue";
import CIconViewGrid from "./CIconViewGrid.vue";
import CIconRefresh from "./CIconRefresh.vue";
import CIconReportsBullet from "./CIconReportsBullet.vue";
import CIconReportsFold from "./CIconReportsFold.vue";
import CIconSale from "./CIconSale.vue";
import CIconSave from "./CIconSave.vue";
import CIconSearch from "./CIconSearch.vue";
import CIconShopping from "./CIconShopping.vue";
import CIconSort from "./CIconSort.vue";
import CIconSortUpDown from "./CIconSortUpDown.vue";
import CIconStatus from "./CIconStatus.vue";
import CIconStore from "./CIconStore.vue";
import CIconSubstitute from "./CIconSubstitute.vue";
import CIconTracking from "./CIconTracking.vue";
import CIconTrash from "./CIconTrash.vue";
import CIconTwitter from "./CIconTwitter.vue";
import CIconYoutube from "./CIconYoutube.vue";
import CIconCarouselRight from "./CIconCarouselRight.vue";
import CIconCarouselLeft from "./CIconCarouselLeft.vue";

export const ChipplyIconLibrary = {
    icon1: {
        component: CIcon1,
    },
    icon2: {
        component: CIcon2,
    },
    icon3: {
        component: CIcon3,
    },
    icon4: {
        component: CIcon4,
    },
    icon5: {
        component: CIcon5,
    },
    icon6: {
        component: CIcon6,
    },
    icon7: {
        component: CIcon7,
    },
    icon8: {
        component: CIcon8,
    },
    icon9: {
        component: CIcon9,
    },
    iconAnnotate: {
        component: CIconAnnotate,
    },
    iconApplyChange: {
        component: CIconApplyChange,
    },
    iconArrowBack: {
        component: CIconArrowBack,
    },
    iconArrowDown: {
        component: CIconArrowDown,
    },
    iconArrowForward: {
        component: CIconArrowForward,
    },
    iconArrowUp: {
        component: CIconArrowUp,
    },
    iconAttention: {
        component: CIconAttention,
    },
    iconAvatar: {
        component: CIconAvatar,
    },
    iconAvatarFill: {
        component: CIconAvatarFill,
    },
    iconAvatarOutline: {
        component: CIconAvatarOutline,
    },
    iconBackSpeedDialSmall: {
        component: CIconBackSpeedDialSmall,
    },
    iconCalendar: {
        component: CIconCalendar,
    },
    iconCheckBox: {
        component: CIconCheckBox,
    },
    iconCheckmarkSharp: {
        component: CIconCheckmarkSharp,
    },
    iconCheckSolid: {
        component: CIconCheckSolid,
    },
    iconChevronDown: {
        component: CIconChevronDown,
    },
    iconChevronLeft: {
        component: CIconChevronLeft,
    },
    iconChevronRight: {
        component: CIconChevronRight,
    },
    iconChevronUp: {
        component: CIconChevronUp,
    },
    iconClose: {
        component: CIconClose,
    },
    iconCloseCircle: {
        component: CIconCloseCircle,
    },
    iconCopy: {
        component: CIconCopy,
    },
    iconCustomItem: {
        component: CIconCustomItem,
    },
    iconDashboardSolid: {
        component: CIconDashboardSolid,
    },
    iconDashOutline: {
        component: CIconDashOutline,
    },
    iconDatePickerChevronDown: {
        component: CIconDatePickerChevronDown,
    },
    iconDatePickerChevronLeft: {
        component: CIconDatePickerChevronLeft,
    },
    iconDatePickerChevronRight: {
        component: CIconDatePickerChevronRight,
    },
    iconDatePickerChevronUp: {
        component: CIconDatePickerChevronUp,
    },
    iconDateRange: {
        component: CIconDateRange,
    },
    iconDot: {
        component: CIconDot,
    },
    iconDotOuter: {
        component: CIconDotOuter,
    },
    iconEdit: {
        component: CIconEdit,
    },
    iconExport: {
        component: CIconExport,
    },
    iconExternalLink: {
        component: CIconExternalLink,
    },
    iconEye: {
        component: CIconEye,
    },
    iconEyeOff: {
        component: CIconEyeOff,
    },
    iconFacebook: {
        component: CIconFacebook,
    },
    iconGear: {
        component: CIconGear,
    },
    iconGlassdoor: {
        component: CIconGlassdoor,
    },
    iconHamburger: {
        component: CIconHamburger,
    },
    iconHelpFill: {
        component: CIconHelpFill,
    },
    iconHelpOutline: {
        component: CIconHelpOutline,
    },
    iconHelpSquare: {
        component: CIconHelpSquare,
    },
    iconHistory: {
        component: CIconHistory,
    },
    iconHistoryDisabled: {
        component: CIconHistoryDisabled,
    },
    iconHouse: {
        component: CIconHouse,
    },
    iconInfo: {
        component: CIconInfo,
    },
    iconInstagram: {
        component: CIconInstagram,
    },
    iconLinkedin: {
        component: CIconLinkedin,
    },
    iconList: {
        component: CIconList,
    },
    iconListWithCheckmark: {
        component: CIconListWithCheckmark,
    },
    iconLock: {
        component: CIconLock,
    },
    iconMail: {
        component: CIconMail,
    },
    iconMenu: {
        component: CIconMenu,
    },
    iconMenuDown: {
        component: CIconMenuDown,
    },
    iconMenuLeft: {
        component: CIconMenuLeft,
    },
    iconMenuRight: {
        component: CIconMenuRight,
    },
    iconMenuUp: {
        component: CIconMenuUp,
    },
    iconMinus: {
        component: CIconMinus,
    },
    iconNavigation: {
        component: CIconNavigation,
    },
    iconNote: {
        component: CIconNote,
    },
    iconNoteFill: {
        component: CIconNoteFill,
    },
    iconNoteLandscape: {
        component: CIconNoteLandscape,
    },
    iconOrderDeactivate: {
        component: CIconOrderDeactivate,
    },
    iconOrderFilter: {
        component: CIconOrderFilter,
    },
    iconOrderManager: {
        component: CIconOrderManager,
    },
    iconOrderManagerLarge: {
        component: CIconOrderManagerLarge,
    },
    iconOrganizations: {
        component: CIconOrganizations,
    },
    iconPackage: {
        component: CIconPackage,
    },
    iconPercent: {
        component: CIconPercent,
    },
    iconPlay: {
        component: CIconPlay,
    },
    iconPlus: {
        component: CIconPlus,
    },
    iconPrint: {
        component: CIconPrint,
    },
    iconProductManager: {
        component: CIconProductManager,
    },
    iconPurchasing: {
        component: CIconPurchasing,
    },
    iconPurchasingCircle: {
        component: CIconPurchasingCircle,
    },
    iconViewGrid: {
        component: CIconViewGrid,
    },
    iconRefresh: {
        component: CIconRefresh,
    },
    iconReportsBullet: {
        component: CIconReportsBullet,
    },
    iconReportsFold: {
        component: CIconReportsFold,
    },
    iconSale: {
        component: CIconSale,
    },
    iconSave: {
        component: CIconSave,
    },
    iconSearch: {
        component: CIconSearch,
    },
    iconShopping: {
        component: CIconShopping,
    },
    iconSort: {
        component: CIconSort,
    },
    iconSortUpDown: {
        component: CIconSortUpDown,
    },
    iconStatus: {
        component: CIconStatus,
    },
    iconStore: {
        component: CIconStore,
    },
    iconSubstitute: {
        component: CIconSubstitute,
    },
    iconTracking: {
        component: CIconTracking,
    },
    iconTrash: {
        component: CIconTrash,
    },
    iconTwitter: {
        component: CIconTwitter,
    },
    iconYoutube: {
        component: CIconYoutube,
    },
    iconCarouselRight: {
        component: CIconCarouselRight,
    },
    iconCarouselLeft: {
        component: CIconCarouselLeft,
    },
};
