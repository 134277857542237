












































































































































































































import ProductColor from "../components/ProductColor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import ProductSize from "./ProductSize.vue";
import { Watch, Prop } from "vue-property-decorator";
import ProductColorSizeWrapperModel from "@/chipply/ProductColorSizeWrapperModel";
import AddToCartArgs from "@/chipply/AddToCartArgs";
import ProductOption from "@/chipply/ProductOption";
import { WebHelper } from "chipply-common";
import ProductViewModel from "@/chipply/view-model/ProductViewModel";
import ParentProductViewModel from "@/chipply/view-model/ParentProductViewModel";
import IProcessOptionItem from "@/chipply/IProcessOptionItem";
import IProcessOptionModel from "@/chipply/IProcessOptionModel";
import RecaptchaClient from "@/chipply/verify/RecaptchaClient";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import ProductDialog from "./ProductDialog.vue";
import { mask } from "vue-the-mask";
import Validation from "@/chipply/Validation";
import ProductOptionDisplay from "@/components/design-elements/ProductOptionDisplay.vue";
import ChooseYourDesign from "@/components/design-elements/ChooseYourDesign.vue";
import QuantityComponent from "@/components/design-elements/QuantityComponent.vue";
import RadioButtonModel from "@/chipply/RadioButtonModel";
import { max, size } from "lodash";
import PackageViewModel from "@/chipply/view-model/PackageViewModel";

@Component({
    components: {
        QuantityComponent,
        ChooseYourDesign,
        ProductOptionDisplay,
        ProductColor,
        ProductSize,
        ProductDialog,
    },
    props: {
        // productColors: Array,
        colorSize: Object,
        value: Object,
        cartVm: Object,
        packageView: Boolean,
        hidePrice: Boolean,
        maxReached: Boolean,
        pvm: Object,
    },
    directives: {
        mask,
    },
})
export default class ProductInfoSelector extends Vue {
    name = "ProductInfoSelector";

    public Validation = Validation;
    // TODO: Remove I think this is unused
    public selectedColor: any | undefined = "";
    // TODO: Remove I think this is unused
    public selectedSize: number | undefined = 0;
    public selectedQuantity: string | undefined = "";
    public mySizes: Array<any> = [];
    private value!: ProductViewModel;
    private qtyArray: Array<string> = [];
    private displayQty = 1;
    private addedOptions: Array<ProductOption> = [];
    private optionFormValid = false;
    private qtyFormValid = false;
    private showSnackbar = false;
    private sizeError = false;
    private cartVm!: CartViewModel;
    private maxReached!: boolean;
    private minReached = false;
    private itemMax = false;
    private outOfBounds = false;
    private noDesignSelected = false;
    public pvm!: ParentProductViewModel;
    private disableButton = false;
    private qtyValid = false;

    @Prop({
        default: false,
        type: Boolean,
    })
    public packageView!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public allowMultiple!: boolean;

    // @ts-ignore
    public $refs!: {
        optionForm: any;
        qtyForm: any;
        qtyComponent: QuantityComponent;
        snack: ProductDialog;
    };

    protected async recaptchaHandler() {
        const recaptchaClient = new RecaptchaClient();
        const token = await recaptchaClient.execute({
            action: "submit",
        });
        return token;
    }

    public async created() {
        await this.value.setInitialColorSize();
        if (this.value.product) {
            if (!this.value.productQuantity || this.value.productQuantity == 0) {
                this.value.productQuantity = this.value.product.minQuantity;
            }

            if (this.value.product.designCount > 1 && !this.value.checkForEnabledDesign()) {
                //this.value.colorSize.designsObject.designs[0].enabled = true;
            }

            this.value.options.forEach((option) => {
                if (option.processType == 5) {
                    option.originalValue = option.userValue;
                }
            });
        }
    }

    private specifiedQuantity() {
        if (this.value.product) {
            if (this.value.product.minQuantity == this.value.product.maxQuantity) {
                return true;
            } else {
                return false;
            }
        }
    }

    private calculateOptionCols(option: IProcessOptionModel) {
        if (this.packageView || !this.$vuetify.breakpoint.smAndUp || option.extraFee != 0) {
            return "12";
        } else {
            return "6";
        }
    }

    private calculateCols(where: string) {
        switch (where) {
            case "options":
                if (this.packageView || !this.$vuetify.breakpoint.smAndUp) {
                    return "12";
                } else {
                    return "6";
                }
            case "sizing":
                return "12";

            case "quantity":
                if (this.packageView || !this.$vuetify.breakpoint.smAndUp) {
                    return "12";
                } else {
                    return "4";
                }
            case "button":
                if (this.packageView) {
                    return "12";
                } else {
                    return "8";
                }
            default:
                break;
        }
    }

    protected screenCheck() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return true;
        } else {
            return false;
        }
    }

    protected minLabel(reached: boolean) {
        this.minReached = reached;
        if (reached) {
            this.qtyValid = false;
        } else {
            this.qtyValid = true;
        }
    }

    protected outOfBoundsLabel(reached: boolean) {
        this.outOfBounds = reached;
        if (reached) {
            this.qtyValid = false;
        } else {
            this.qtyValid = true;
        }
    }

    public async setSize(selected: number) {
        this.sizeError = false;
        await this.value.disableColors(selected);
        this.value.selectedSize = selected;

        const allSelectedSizesAreFalse = this.value.productSizeCollection.every((item) => !item.selected);

        if (allSelectedSizesAreFalse) {
            this.selectedSize = 0;
            this.value.productColorCollection.forEach((myColor) => {
                myColor.enabled = true;
            });
        }

        this.validateQuantity(this.value.productQuantity); //should run after each size selection because the dictionary will be different
    }

    protected changeQty(amount: number) {
        const targetNum = parseInt(this.value.productQuantity.toString()) + amount;
        if (amount > 0) {
            if (targetNum <= this.calculateCurrentMax()) {
                this.value.productQuantity++;
                this.minReached = false;
            } else {
                this.itemMax = true;
            }
        } else {
            if (this.value.productQuantity != 1) {
                if (targetNum >= this.value.product!.minQuantity) {
                    this.value.productQuantity--;
                    this.itemMax = false;
                } else {
                    this.minReached = true;
                }
            }
        }
        this.validateQuantity(this.value.productQuantity);
    }

    public isSticky() {
        if (this.value.selectedSize == 0) {
            return false;
        }

        if (!this.$refs.optionForm.validate() || !this.$refs.qtyComponent.validateForm()) {
            //don't add to cart
            this.$refs.optionForm.resetValidation();
            this.$refs.qtyComponent.resetValidation();
            return false;
        } else {
            return true;
        }
    }

    public async setInitialColor(preset?: object) {
        this.value.setInitialColorSize();
    }

    private async disableSizes(id: number) {
        if (id != undefined && this.value.colorSize != undefined) {
            var targetDictionary = this.value.colorSize.colorSizeDictionary![id];
            var keys = Object.keys(targetDictionary);
            this.value.productSizeCollection = [];
            this.value.colorSize.sizes?.forEach((element) => {
                var model: { display: string; value: number; selected: boolean; enabled: boolean } = {
                    display: element.display,
                    value: element.value,
                    selected: false,
                    enabled: keys.includes("" + element.value),
                };
                this.value.productSizeCollection.push(new RadioButtonModel(model));
            });

            if (this.value.productSizeCollection.length == 1) {
                this.value.productSizeCollection[0].selected = true;
                this.value.selectedSize = this.value.productSizeCollection[0].value;
            }
        }
    }

    private calculateCurrentMax() {
        let designIds: any[] = [];
        let limit = this.value.product?.maxQuantity;
        let sizeMax = 0;

        if (this.value.selectedColor && this.value.selectedSize) {
            if (this.value.selectedColor.eventProductColorID != "" && this.value.selectedSize != 0) {
                var colorId = this.value.selectedColor.eventProductColorId;
                var sizeId = this.value.selectedSize;
                var targetEntry = this.value.colorSize.colorSizeDictionary![colorId][sizeId];
                if (targetEntry.parentProductColorId != 0 || targetEntry.parentProductSizeId != 0) {
                    var parentSize =
                        this.pvm.parent.colorSize.colorSizeDictionary![targetEntry.parentProductColorId][
                            targetEntry.parentProductSizeId
                        ];
                    if (parentSize != null) {
                        if (parentSize.availableQuantity != null) {
                            if (parentSize.availableQuantity < limit!) {
                                return parentSize.availableQuantity;
                            }
                        }
                    }
                }

                if (
                    this.value.colorSize.colorSizeDictionary![colorId][sizeId].availableQuantity != null &&
                    this.value.colorSize.colorSizeDictionary![colorId][sizeId].availableQuantity < limit!
                ) {
                    return this.value.colorSize.colorSizeDictionary![colorId][sizeId].availableQuantity;
                }
            }
        }

        if (this.value.product!.designCount > 1) {
            this.value.colorSize.designsObject.designs.forEach((design: any) => {
                designIds.push(design.value);
            });
        } else {
            designIds.push(this.value.product?.eventProductID);
        }
        return this.cartVm.calculateCartMax(designIds, this.value.product!.maxQuantity, this.allowMultiple);
    }

    // This might work better as a computed property
    protected validateQuantity(enteredNum: number) {
        this.value.productQuantity = enteredNum;
        //might eventually move this minReached setting if we want to warn the user without stopping them that they've reached min
        //this.minReached = false;
        this.maxReached = false;
        if (this.value.product) {
            if (enteredNum < this.value.product.minQuantity || enteredNum > this.calculateCurrentMax()) {
                this.outOfBounds = true;
            } else {
                this.outOfBounds = false;
            }
        }
    }

    public async updatePackageItem() {
        if (this.value.selectedSize == 0) {
            this.sizeError = true;
            return;
        } else {
            this.sizeError = false;
        }

        if (!this.value.checkForEnabledDesign() && this.value.product!.designCount > 1) {
            this.noDesignSelected = true;
            return;
        }

        if (this.calculateCurrentMax() == 0) {
            this.outOfBounds = true;
            return;
        }

        if (!this.$refs.optionForm.validate() || !this.$refs.qtyComponent.validateForm() || this.outOfBounds) {
            //don't update
        } else {
            this.value.pkgItemFinalized = true;
            this.$emit("finalize");
        }
    }

    public async addToCart() {
        if (this.disableButton) {
            return;
        }
        this.disableButton = true;
        try {
            var args = new AddToCartArgs();
            args.productId = this.value.product!.eventProductID;
            args.sizeId = this.value.selectedSize!;
            args.categoryId = this.value.categoryId;
            args.colorId = this.value.selectedColor.eventProductColorId;

            //let qty = parseInt(this.displayQty!.slice(4));
            let qty = this.value.productQuantity;
            args.quantity = qty;
            args.eventId = this.value.product!.eventID;

            //Product options stuff goes here
            this.value.options.forEach((option) => {
                if (option.userValue != undefined) {
                    const po = new ProductOption();
                    if (option.processType == 4 || option.processType == 5) {
                        option.options.forEach((choice) => {
                            if (choice.display == option.userValue) {
                                po.processOptionId = choice.optionItemId;
                            }
                        });
                    }
                    po.text = option.userValue;
                    po.processItemId = option.optionId;
                    args.productOptions.push(po);
                }
            });

            if (!this.value.checkForEnabledDesign() && this.value.product!.designCount > 1) {
                this.noDesignSelected = true;
                this.disableButton = false;
                return;
            }

            if (this.value.selectedSize == 0) {
                this.sizeError = true;
                this.disableButton = false;
                return;
            } else {
                this.sizeError = false;
            }

            if (this.outOfBounds) {
                this.disableButton = false;
                return;
            }

            if (!this.$refs.optionForm.validate() || !this.$refs.qtyComponent.validateForm()) {
                //don't add to cart
            } else {
                this.value.loading = true;
                await WebHelper.postJsonData("/api/EventProductEcom/AddToCart", args);
                this.value.loading = false;
                await this.$refs.snack.showSnackbar();
            }
        } finally {
            this.disableButton = false;
        }
    }

    private async designSelected(design: any) {
        this.noDesignSelected = false;
        this.$emit("designChanged", design);
    }

    private async disableColors(id: number) {
        var colors = Object.keys(this.value.colorSize.colorSizeDictionary!);
        colors.forEach((color) => {
            var sizes = Object.keys(this.value.colorSize.colorSizeDictionary![color as unknown as number]);
            var combo = this.value.colorSize.colorSizeDictionary![color as unknown as number][id];

            if (combo == undefined) {
                this.value.productColorCollection.forEach((myColor) => {
                    if (myColor.eventProductColorId == color) {
                        myColor.enabled = false;
                    }
                });
            } else {
                this.value.productColorCollection.forEach((myColor) => {
                    if (myColor.eventProductColorId == color) {
                        myColor.enabled = true;
                    }
                });
            }
        });
    }

    private async rebuildColorsAndSizes() {
        this.value.productColorCollection = [];
        this.value.colorSize.colors?.forEach((element) => {
            var targetDictionary = this.value.colorSize.colorSizeDictionary![element.eventProductColorId];

            var model: { colorName: string; hexValue: string; eventProductColorId: number; enabled: boolean } = {
                colorName: element.colorName,
                hexValue: element.hexCode1,
                eventProductColorId: element.eventProductColorId,
                enabled: Object.keys(targetDictionary).length > 0,
            };

            this.value.productColorCollection.push(model);
        });

        //need to setup the size array and disable any that aren't available in the initial color
        this.value.productSizeCollection = [];
        var keys = Object.keys(this.value.colorSize.colorSizeDictionary![this.value.selectedColor.eventProductColorId]);
        this.value.colorSize.sizes?.forEach((element) => {
            var model: { display: string; value: number; selected: boolean; enabled: boolean } = {
                display: element.display,
                value: element.value,
                selected: false,
                enabled: keys.includes("" + element.value),
            };
            this.value.productSizeCollection.push(new RadioButtonModel(model));
        });

        if (this.value.productSizeCollection.length == 1) {
            this.value.productSizeCollection[0].selected = true;
            this.value.selectedSize = this.value.productSizeCollection[0].value;
        }
    }

    @Watch("value.selectedColor")
    protected async onColorChanged() {
        if (this.value.selectedColor != undefined && this.value.selectedColor != "") {
            await this.value.rebuildColorsAndSizes(this.value.selectedColor.eventProductColorId);
            await this.disableSizes(this.value.selectedColor.eventProductColorId);

            if (this.value.selectedSize != 0) {
                await this.disableColors(this.value.selectedSize);
            }

            this.$emit("color", this.value.selectedColor.eventProductColorId);
        }

        this.validateQuantity(this.value.productQuantity);
    }

    @Watch("value.selectedSize")
    protected async onSizeChanged() {
        this.validateQuantity(this.value.productQuantity);
    }
}
