import IDealerDto from "./IDealerDto";

export class DealerDto implements IDealerDto {
    public privacyUrl = "";
    public termsUrl = "";
    public shouldIncludeDealerLogo = false;
    public dealerWebsite = "";
    public foregroundColor = "";
    public dealerLogo = "";
    public dealerName = "";
    public dealerEmail = "";
    public dealerPhone = "";
    public dealerPrimaryColor = "";
    public dealerSecondaryColor = "";
    public hideChipplyLogo = false;
    public dealerSlideImage = "";
    public dealerSlideContentImagePath = "";
    public dealerSlideLogo = "";
    public dealerSlideContentLogoPath = "";
    public dealerCloseInfo = "";
    public dealerTerms = "";
    public dealerSlideVideo = "";
}
