import { IAddressValidationAddressProblems } from "@/chipply/address-validation/IAddressValidationAddressProblems";
import { IAddressValidationResults } from "@/chipply/address-validation/IAddressValidationResults";
import { AsyncInteractionViewModel, IAddress } from "chipply-common";

export class AddressValidationViewModel extends AsyncInteractionViewModel {
    public enteredAddress: IAddress;
    public addressResults: IAddressValidationResults;

    public selection = "corrected";

    public get showAddressCorrection() {
        return (
            (this.addressResults.correctionResults && this.addressResults.correctionResults.hasCorrection) ||
            (this.addressResults.hasEnhancedMatch && this.addressResults.addressProblems.errors.length == 0)
        );
    }

    public get title() {
        if (!this.addressResults.isValid) {
            return "Address Not Found";
        }

        if (this.addressResults.hasEnhancedMatch) {
            return "Better Address Found";
        }
    }

    constructor(enteredAddress: IAddress, addressResults: IAddressValidationResults) {
        super();
        const addressCopy = JSON.parse(JSON.stringify(enteredAddress)) as IAddress;
        addressCopy.firstName = "";
        addressCopy.lastName = "";
        addressCopy.country = "";
        this.enteredAddress = addressCopy;
        this.addressResults = addressResults;
    }
}
