import { AsyncInteractionResult } from "../AsyncInteractionViewModel";

export class EditResult {
    public canceled = false;
    public completionSource: string | null = null;

    constructor(result?: string) {
        this.canceled = result === "cancel";
        this.completionSource = result || null;
    }
}
