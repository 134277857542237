import IProductsDto from "@/chipply/i-products-dto";
import IEventCategoryDto from "@/chipply/i-event-category-dto";
import IPackageDto from "@/chipply/i-package-dto";
import IEventCategoriesDto from "./i-event-categories-dto";
import { ProductsDto } from "./products-dto";

export default class EventCategoriesDto implements IEventCategoriesDto {
    public isSingleCategoryExpansion = false;
    public products: IProductsDto = new ProductsDto();
    public containers: Array<IEventCategoryDto | IPackageDto> = [];
    public packages: IPackageDto[] = [];
    public hexCode1 = "";
    public foregroundColor = "";
    public storePasswordNeedsValidation = false;

    public constructor(eventCategoriesDto?: IEventCategoriesDto) {
        if (eventCategoriesDto) {
            this.isSingleCategoryExpansion = eventCategoriesDto.isSingleCategoryExpansion;
            this.products = eventCategoriesDto.products;
            this.containers = eventCategoriesDto.containers;
            this.hexCode1 = eventCategoriesDto.hexCode1;
            this.foregroundColor = eventCategoriesDto.foregroundColor;
            this.storePasswordNeedsValidation = eventCategoriesDto.storePasswordNeedsValidation;
        }
    }
}
